import React from 'react';
import createReactClass from 'create-react-class';
import { translate } from '@/i18n';
import classNameMixin from '../../mixins/classNameMixin';
import dropdownManager from './dropdownManager';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'footer',

  // @ts-expect-error
  mixins: [classNameMixin],

  getDefaultProps() {
    return {
      type: 'footer',
    };
  },

  render() {
    let content = this.props.children || '';

    if (typeof content === 'string') {
      content = content.trim();

      if (this.props.shouldTranslate) {
        content = translate(content);
      }
    }

    return React.createElement(
      'div',
      {
        onClick: dropdownManager.hide.bind(dropdownManager),
        className: this.generateClassName(this.props.className),
        style: this.props.style,
      },
      content,
    );
  },
});
