import React from 'react';
import _ from 'lodash';

import { hoc, cx, type WithReportUIChange } from '@/util';
import { utils as themeUtils } from '@/theme';

const COLORS_IN_ROW = 5;
const PALETTE_COLORS_START_INDEX = 11;

function invertMatrix<T extends unknown>(
  matrix: T[],
  rows: number,
  cols: number,
): T[] {
  const result = [];
  const total = rows * cols;

  for (let mod = 0; mod < rows; mod++) {
    for (let index = mod; index < total; index += rows) {
      result.push(matrix[index]);
    }
  }

  return result;
}

interface Color {
  id: string;
  value: string;
}

interface PaletteDisplayerProps extends WithReportUIChange {
  palette: Record<string, string>;
  className?: string;
  isSelectable: boolean;
  onPreview: (colorId: string, isMouseOut: boolean) => void;
  value: string;
  onChange: (colorId: string, isDoubleClick?: boolean) => void;
}

class PaletteDisplayerComponent extends React.Component<PaletteDisplayerProps> {
  static displayName = 'paletteDisplayer';

  getFormattedPalette() {
    const { palette } = this.props;

    return Object.entries(palette)
      .filter(([colorId]) => {
        const colorIndex = parseInt(/color_(\d+)/.exec(colorId)[1], 10);

        return colorIndex >= PALETTE_COLORS_START_INDEX;
      })
      .map(([colorId, colorValue]) => ({
        id: colorId,
        value: colorValue,
      }));
  }
  getColors() {
    const colors = this.getFormattedPalette();

    return invertMatrix(colors, 5, colors.length / 5);
  }
  getColorGroups() {
    const initialColors = this.getFormattedPalette();

    return _.chunk(initialColors, COLORS_IN_ROW);
  }
  getOptionClassNames(color: Color) {
    const { value, isSelectable } = this.props;

    return {
      'palette-color-option': true,
      'white-option': themeUtils.getDistanceToWhite(color.value) < 3,
      'option-selected': value === color.id,
      selectable: isSelectable,
    };
  }
  previewColor(colorId: string, isMouseOut: boolean) {
    const { isSelectable, onPreview } = this.props;

    if (isSelectable && onPreview) {
      onPreview(colorId, isMouseOut);
    }
  }
  handleOptionClick(colorId: string, colorIndex: number) {
    const { reportUIChange, isSelectable, onChange } = this.props;

    reportUIChange({
      value: colorIndex,
    });

    if (isSelectable) {
      onChange(colorId);
    }
  }
  handleOptionDoubleClick(colorId: string) {
    const { isSelectable, onChange } = this.props;

    if (isSelectable) {
      onChange(colorId, true);
    }
  }
  renderRow(colors: Color[], rowIndex: number) {
    const { value } = this.props;

    return (
      <div
        className="palette-displayer-row"
        onMouseLeave={() => {
          this.previewColor(value, true);
        }}
        onMouseEnter={() => {
          this.previewColor(value, false);
        }}
        key={rowIndex}
      >
        {colors.map((color, colorIndex) => (
          <div
            key={color.id}
            onClick={() => {
              this.handleOptionClick(
                color.id,
                rowIndex * COLORS_IN_ROW + colorIndex,
              );
            }}
            onDoubleClick={() => this.handleOptionDoubleClick(color.id)}
            onMouseEnter={() => {
              this.previewColor(color.id, false);
            }}
            style={{
              backgroundColor: color.value,
            }}
            className={cx(this.getOptionClassNames(color))}
          />
        ))}
      </div>
    );
  }

  render() {
    const { className } = this.props;

    const groups = this.getColorGroups();

    return (
      <div
        className={cx('palette-displayer palette-displayer-content', className)}
      >
        {groups.map((group, index) => this.renderRow(group, index))}
      </div>
    );
  }
}

export const paletteDisplayer = hoc.reportUIChange(PaletteDisplayerComponent);
