import { createContext } from 'react';

import Message from './message';
import Messages from './messages';
import ChatInput from './chatInput';

import { type ChatContextTypes } from './types';

const ChatContext = createContext<ChatContextTypes | undefined>(undefined);

export { ChatContext, Message, Messages, ChatInput };
