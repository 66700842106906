// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import { Composites, OpacityPicker, TextLabel } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  propTypes: {
    label: PropTypes.string,
    helpText: PropTypes.string,
    value: PropTypes.any,
    automationId: PropTypes.string,
    panelMode: PropTypes.string,
    ignoreOpacityInPreview: PropTypes.bool,
    colorResolver: PropTypes.func,
    openColorPicker: PropTypes.func,
  },
  getDefaultProps() {
    return {
      isSmallStepper: false,
    };
  },
  getInitialState() {
    const alpha = util.valueLink.getValueFromProps(this.props).alpha;
    return {
      alpha,
      origAlpha: alpha,
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currAlpha = util.valueLink.getValueFromProps(this.props).alpha;
    const nextAlpha = util.valueLink.getValueFromProps(nextProps).alpha;
    if (!Number.isNaN(nextAlpha) && currAlpha !== nextAlpha) {
      this.setState({
        alpha: nextAlpha,
      });
    }
  },
  mixins: [util.propTypesFilterMixin, util.translationMixin],
  displayName: 'colorPickerInputWithOpacity',
  getOpacity() {
    return this.state.alpha * 100;
  },
  onOpacityChange(alpha) {
    util.valueLink.callOnChangeIfExists(this.props, {
      color: util.valueLink.getValueFromProps(this.props).color,
      alpha: alpha / 100,
    });
    if (this.state.alpha != alpha / 100) {
      this.setState({ alpha: alpha / 100, origAlpha: alpha / 100 });
    }
  },
  async onColorChange(color, { isHover, isMouseOut } = {}) {
    let alpha = this.state.alpha;
    let origAlpha = this.state.origAlpha;
    if (Number(this.state.alpha) === 0.0 && !isMouseOut) {
      alpha = 1;
      origAlpha = 0;
    } else if (isMouseOut && origAlpha === 0) {
      alpha = this.state.origAlpha;
    } else if (!isHover) {
      origAlpha = this.state.alpha;
    }
    this.setState({ alpha, origAlpha }, () =>
      util.valueLink.callOnChangeIfExists(
        this.props,
        { color, alpha },
        { isHover, isMouseOut },
      ),
    );
  },

  render() {
    const color = util.valueLink.getValueFromProps(this.props)?.color;
    const opacitySliderColor = util.backgroundUtils.getFirstColorStopColor(
      color,
      this.props.colorResolver,
    );
    const previewOpacity = this.props.ignoreOpacityInPreview
      ? 100
      : this.getOpacity();

    return (
      <Composites.ColorPickerInputLabeled>
        {this.props.label ? (
          <TextLabel
            key="label"
            shouldTranslate={false}
            value={this.translateIfNeeded(this.props.label)}
            disabled={this.props.disabled}
          />
        ) : null}
        <Composites.ColorPickerInput>
          <OpacityPicker
            automationId={`${this.props.automationId}-slider`}
            value={this.getOpacity()}
            onChange={this.onOpacityChange}
            opacityGradientColor={opacitySliderColor}
            disabled={this.props.disabled}
          />

          <baseUI.colorPickerInput
            automationId={`${this.props.automationId}-colorPicker`}
            value={color}
            opacity={previewOpacity}
            openColorPicker={this.props.openColorPicker}
            disabled={this.props.disabled}
            useMouseEvent={true}
            onChange={this.onColorChange}
            colorResolver={this.props.colorResolver}
            skipLabelWrapper={true}
            isStrictColorPicker={this.props.isStrictColorPicker}
            enableHistory={true}
            panelMode={this.props.panelMode}
          />
        </Composites.ColorPickerInput>
      </Composites.ColorPickerInputLabeled>
    );
  },
});
