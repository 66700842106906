// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import mediaManagerMixin from './mediaManagerMixin';
import wixStaticMediaMixin from './wixStaticMediaMixin';
import React from 'react';
import ImagePreview from './imagePreview';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'imageChange',
  mixins: [util.propTypesFilterMixin, mediaManagerMixin, wixStaticMediaMixin],
  propTypes: {
    addButtonLabel: PropTypes.string,
    changeButtonLabel: PropTypes.string,
    additionalButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClick: PropTypes.func,
    imageFittingType: PropTypes.string,
    previewOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      additionalButtons: [],
    };
  },
  getButtons() {
    const loadImageFromMediaGalleryButton = {
      nonEmptyButtonLabel: this.props.changeButtonLabel,
      nonEmptyButtonIcon: 'image-change',
      emptyButtonLabel: this.props.addButtonLabel,
      emptyButtonIcon: 'plus',
      onClick: this.props.onClick || this.openMediaGallery,
      disabled: this.props.disabled,
    };
    return [].concat(
      loadImageFromMediaGalleryButton,
      this.props.additionalButtons,
    );
  },
  render() {
    return (
      <div>
        {this.props.previewOnly ? (
          <ImagePreview
            key="previewOnly"
            value={this.props.value}
            valueLink={this.props.valueLink}
            getImageTransformData={this.getImageTransformData}
            imageFittingType={this.props.imageFittingType}
            emptySymbolName="camera"
            {...this.filteredProps()}
          />
        ) : null}

        {!this.props.previewOnly ? (
          <ImagePreview
            key="previewInteractive"
            value={this.props.value}
            onChange={this.props.onChange}
            valueLink={this.props.valueLink}
            buttons={this.getButtons()}
            getImageTransformData={this.getImageTransformData}
            imageFittingType={this.props.imageFittingType}
            emptySymbolName="camera"
            {...this.filteredProps()}
          />
        ) : null}
      </div>
    );
  },
});
