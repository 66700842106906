// @ts-nocheck
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import $ from 'zepto';
import * as util from '@/util';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import { AngleInput, Composites, TextLabel } from '@wix/wix-base-ui';
import { translate } from '@/i18n';

const degreesPerRad = 180 / Math.PI;

function setCircleCenter() {
  const root = $(ReactDOM.findDOMNode(this));
  const circle = root.find('.circle-center')[0];
  const boundingRectangle = circle.getBoundingClientRect();
  //TODO: find a better method to get a circleCenter independent of scroll, i.e. using screen coordinates instead of page
  this.circleCenter = {
    x: boundingRectangle.left,
    y: boundingRectangle.top + window.pageYOffset,
  };
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'angle',
  mixins: [reportUIChangeMixin, util.translationMixin],
  translate,
  propTypes: {
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },

  componentDidMount() {
    this.previousValue = util.valueLink.getValueFromProps(this.props);
  },
  onKnobMouseDown() {
    setCircleCenter.call(this);
    $(window.document).on('mousemove', this.changeAngle);
    $(window.document).on('mouseup', this.removeListenersAndReportChange);
  },
  removeListenersAndReportChange() {
    $(window.document).off('mouseup', this.removeListenersAndReportChange);
    $(window.document).off('mousemove', this.changeAngle);
    this.reportChangeFromUser();
  },
  changeAngle(e) {
    const angle = Math.atan2(
      e.pageY - this.circleCenter.y,
      e.pageX - this.circleCenter.x,
    );
    const degrees = Math.floor((450 + angle * degreesPerRad) % 360);

    util.valueLink.callOnChangeIfExists(this.props, degrees);
  },
  reportChangeFromUser() {
    const currentValue = util.valueLink.getValueFromProps(this.props);
    if (this.previousValue !== currentValue) {
      this.previousValue = currentValue;
      this.reportUIChange({ value: currentValue });
    }
  },
  componentWillUnmount() {
    this.removeListenersAndReportChange();
  },

  getCircleStyle() {
    const value = util.valueLink.getValueFromProps(this.props);
    return {
      transform: `rotate(${value}deg)`,
      WebkitTransform: `rotate(${value}deg)`,
    };
  },

  getKnobStyle() {
    const value = util.valueLink.getValueFromProps(this.props);
    return {
      transform: `rotate(-${value}deg)`,
      WebkitTransform: `rotate(-${value}deg)`,
    };
  },

  onAngleClick(evt) {
    setCircleCenter.call(this);
    this.changeAngle(evt);
  },

  onAngleChange(degrees) {
    util.valueLink.callOnChangeIfExists(this.props, degrees);
  },

  render() {
    return (
      <Composites.AngleInputLabeled>
        <TextLabel
          value={this.props.label}
          disabled={this.props.disabled}
          shouldTranslate={true}
        />
        <AngleInput
          disabled={this.props.disabled}
          value={util.valueLink.getValueFromProps(this.props)}
          onChange={this.onAngleChange}
        />
      </Composites.AngleInputLabeled>
    );
  },
});
