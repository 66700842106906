// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Composites, TextLabel, CornerRadiusInput } from '@wix/wix-base-ui';
import * as util from '@/util';

function template() {
  return (
    <Composites.CornerRadiusInputLabeled>
      <TextLabel
        value={this.props.label}
        shouldTranslate={this.props.shouldTranslate}
      />
      <CornerRadiusInput
        value={this.getValue()}
        onChange={(value) => {
          this.onChange(value);
        }}
        isInitiallyLocked={this.props.isInitiallyLocked}
        max={this.props.max}
        min={this.props.min}
        unit={this.getUnit()}
      />
    </Composites.CornerRadiusInputLabeled>
  );
}

class CornerRadiusInputComponent extends React.Component {
  constructor(props) {
    super(props);

    this.getIsSpx = () => {
      const value = this.props.valueLink?.value || '';
      return value.split(' ').some((part) => /^(-?[\d.]+)spx$/.test(part));
    };

    this.getUnit = () => (this.getIsSpx() ? 'spx' : 'px');

    this.getValue = () => {
      const linkValue = this.props.valueLink?.value ?? this.props.value ?? '';
      return linkValue;
    };

    this.onChange = (value) => {
      const onChange = this.props.valueLink
        ? this.props.valueLink.requestChange
        : this.props.onChange;
      if (_.isFunction(onChange)) {
        onChange(value);
      }

      _.invoke(this, 'props.reportUIChange', { value });
    };
  }

  render() {
    return template.call(this);
  }
}

CornerRadiusInputComponent.displayName = 'cornerRadiusInput';
CornerRadiusInputComponent.propTypes = {
  isInitiallyLocked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  reportUIChange: PropTypes.func,
  value: PropTypes.string,
  valueLink: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
};

const Wrapped = util.hoc.reportUIChange(CornerRadiusInputComponent);

export default Wrapped;
