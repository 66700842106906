// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputMixin from '../panelInputs/inputMixin';
import classNameMixin from '../mixins/classNameMixin';
import tooltipManager from '../popovers/tooltipManager';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import * as util from '@/util';
import { translate } from '@/i18n';
import Tooltip from '../popovers/tooltip';
import Image from './image';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'radio',
  mixins: [inputMixin, classNameMixin],
  propTypes: {
    options: PropTypes.array,
    name: PropTypes.string,
    imageData: PropTypes.object,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    group: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    translate: PropTypes.bool,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    notifySymbol: PropTypes.string,
    onNotifySymbolClick: PropTypes.func,
    notifySymbolClassName: PropTypes.string,
    notifSymbolTooltip: PropTypes.string,
    radioType: PropTypes.oneOf(['image', 'symbol', 'class']),
  },
  getDefaultProps() {
    return {
      notifySymbolClassName: 'notify-symbol-small-right',
      disabled: false,
      translate: true,
    };
  },
  isLabelEllipsisActive() {
    return (
      this.props.label &&
      this.props.ellipsis &&
      ReactDOM.findDOMNode(this).offsetWidth <
        ReactDOM.findDOMNode(this.refs.label).scrollWidth
    );
  },
  componentDidMount() {
    this.labelEllipsActive = this.isLabelEllipsisActive();
  },
  componentDidUpdate() {
    this.labelEllipsActive = this.isLabelEllipsisActive();
  },
  shouldShowTooltip() {
    return this.props.tooltip || this.labelEllipsActive;
  },
  toggleNotifySymbolTooltip(show) {
    if (show && this.props.notifySymbolTooltip) {
      tooltipManager.createTooltip(true, {
        id: 'notifySymbol',
        targetNode: this.refs.notifySymbol,
        value: this.props.notifySymbolTooltip,
      });
    } else {
      tooltipManager.hide('notifySymbol');
    }
  },
  getRadioClasses() {
    const cssClasses = {
      'svg-radio': true,
      disabled: this.props.disabled,
    };
    cssClasses[`svg-radio-${this.props.name}`] = true;
    return cssClasses;
  },

  render() {
    return (
      <Tooltip
        ref="tooltip"
        value={this.props.tooltip || this.props.label}
        disabled={!this.shouldShowTooltip()}
        shouldTranslate={true}
      >
        <label
          key={this.props.name}
          onMouseOver={this.props.onMouseOver}
          onMouseOut={this.props.onMouseOut}
          className={cx(
            `radio-control ${this.getClassName(`control-${this.props.name}`)}`,
            { disabled: this.props.disabled },
          )}
          data-automation-id={this.props.automationId}
        >
          <input
            type="radio"
            name={this.props.group}
            checked={util.valueLink.getValueFromProps(this.props)}
            disabled={!!this.props.disabled}
            onChange={this.props.onChange}
            className={`input-${this.props.name}`}
          />

          {this.props.radioType === 'symbol' ? (
            <symbols.symbol
              key="radio-symbol"
              name={this.props.name}
              className={cx(this.getRadioClasses())}
            />
          ) : null}
          {this.props.radioType === 'class' ? (
            <div key="radio-class" className={`class-${this.props.name}`} />
          ) : null}
          {this.props.radioType === 'image' && this.props.imageData ? (
            <div
              key="radio-image"
              className={`image-radio image-radio-${this.props.valueName}`}
            >
              <Image
                imageData={this.props.imageData}
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
                className="image-radio-src"
              />
            </div>
          ) : null}
          {this.props.notifySymbol ? (
            <div
              ref="notifySymbol"
              key="notifySymbol"
              onMouseOver={() => {
                {
                  this.toggleNotifySymbolTooltip(true);
                }
              }}
              onMouseLeave={() => {
                {
                  this.toggleNotifySymbolTooltip(false);
                }
              }}
              className={`notify-symbol ${this.props.notifySymbolClassName}`}
            >
              <span>
                <symbols.symbol
                  name={this.props.notifySymbol}
                  onClick={this.props.onNotifySymbolClick}
                />
              </span>
            </div>
          ) : null}
          {this.props.label ? (
            <span
              ref="label"
              key="radio-label"
              className={cx(`label label-${this.props.name}`, {
                disabled: this.props.disabled,
              })}
            >
              {this.props.translate
                ? translate(this.props.label)
                : this.props.label}
            </span>
          ) : null}
        </label>
      </Tooltip>
    );
  },
});
