// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import { Button } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';

const FITTING_SCALE_TO_FIT = util.imageTransform.fittingTypes.SCALE_TO_FIT;
const STATIC_VIDEO_URL = util.serviceTopology.staticVideoUrl;

const TOP_PANEL_HEIGHT = 162;
const TOP_PANEL_WIDTH = 288;

const SEARCH_TYPES = constants.UI.VIDEO.TYPE;
const VIDEO_TYPES = constants.VIDEO_PLAYER_TYPES;
const VIDEO_SEARCH_PANEL_NAME = 'videoSearch.panels.videoSearchPanel';

const videoToSearchTypeMap = {
  [VIDEO_TYPES.DAILYMOTION]: SEARCH_TYPES.DAILYMOTION,
  [VIDEO_TYPES.VIMEO]: SEARCH_TYPES.VIMEO,
  [VIDEO_TYPES.YOUTUBE]: SEARCH_TYPES.YOUTUBE,
};

const searchToVideoTypeMap = {
  [SEARCH_TYPES.DAILYMOTION]: VIDEO_TYPES.DAILYMOTION,
  [SEARCH_TYPES.VIMEO]: VIDEO_TYPES.VIMEO,
  [SEARCH_TYPES.YOUTUBE]: VIDEO_TYPES.YOUTUBE,
};

//TYPE WAS GENERATED, remove this line when reviewed
interface VideoPreviewProps {
  videoType: AnyFixMe;
  videoUrl?: string;
  videoRef?: AnyFixMe;
  openMediaManager: FunctionFixMe;
  openPanel: FunctionFixMe;
  closePanel: FunctionFixMe;
  isSearchVisible?: boolean;
  onSearchStart?: FunctionFixMe;
  onSearchDone?: FunctionFixMe;
  isUploadVisible?: boolean;
  onUploadStart?: FunctionFixMe;
  onUploadDone?: FunctionFixMe;
  origin: PropTypes.string;
}

class VideoPreview extends React.Component<VideoPreviewProps> {
  static displayName = 'videoPreview';

  static propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    videoType: PropTypes.oneOf(_.values(VIDEO_TYPES)).isRequired,
    videoUrl: PropTypes.string,
    videoRef: PropTypes.object,
    openMediaManager: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired,
    isSearchVisible: PropTypes.bool,
    onSearchStart: PropTypes.func,
    onSearchDone: PropTypes.func,
    isUploadVisible: PropTypes.bool,
    onUploadStart: PropTypes.func,
    onUploadDone: PropTypes.func,
    origin: PropTypes.string,
  };

  state = {
    hasBeenPlayed: false,
    isPlaying: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.videoType !== this.props.videoType) {
      this.setState({
        hasBeenPlayed: false,
        isPlaying: false,
      });
    }
  }

  toggleVideoPlayback = (event) => {
    const value = event.target.checked;

    if (value) {
      this.playVideo();
    } else {
      this.pauseVideo();
    }
  };

  playVideo = () => {
    this.setState({
      isPlaying: true,
      hasBeenPlayed: true,
    });

    if (this.videoElement) {
      this.videoElement.play();
    }
  };

  pauseVideo = () => {
    this.setState({
      isPlaying: false,
    });

    if (this.videoElement) {
      this.videoElement.pause();
    }
  };

  hasVideoPlayer = () => {
    const { videoType, videoRef } = this.props;

    return videoRef || videoType === VIDEO_TYPES.FILE;
  };

  hasEmbeddedPlayer = () => {
    const { videoType } = this.props;

    return videoType && videoType !== VIDEO_TYPES.FILE;
  };

  getPosterData = () => {
    const { videoRef } = this.props;

    if (!videoRef) {
      return;
    }

    const currentPoster = videoRef.posterImageRef;
    if (
      videoRef.generatedPosters &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(videoRef.generatedPosters, videoRef.uri)
    ) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      const { width, height } = _.last(videoRef.qualities);
      return {
        uri: _.head(videoRef.generatedPosters),
        width,
        height,
      };
    }
    return currentPoster;
  };

  getPosterStyle = () => {
    const posterData = this.getPosterData();

    if (!posterData) {
      return {};
    }

    const { width, height, uri } = posterData;
    const previewDisplayData = util.imageTransform.getFromImageData(
      { width, height, uri },
      TOP_PANEL_WIDTH,
      TOP_PANEL_HEIGHT,
      FITTING_SCALE_TO_FIT,
      'center',
      'bg',
    );
    const style = {
      backgroundImage: `url(${previewDisplayData.uri})`,
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(style, previewDisplayData.css.container);
    return style;
  };

  getVideoUrl = () => {
    const { videoUrl } = this.props;

    if (videoUrl) {
      return videoUrl;
    }

    const qualities = this.props?.videoRef?.qualities;
    if (!qualities) {
      return;
    }

    const { url } = _.maxBy(qualities, 'width');

    return `${STATIC_VIDEO_URL}${url}`;
  };

  getEmbeddedUrl = () => {
    const { videoType, videoUrl } = this.props;

    return util.videoPlayerUtils.getEmbeddedUrl(videoType, videoUrl);
  };

  onSearchVideoSelected = (videoId, searchType) => {
    const videoUrl = util.videoPlayerUtils.getUrlFromId(
      searchToVideoTypeMap[searchType],
      videoId,
    );

    this.props.closePanel(VIDEO_SEARCH_PANEL_NAME);
    this.props.onSearchDone(videoUrl);
  };

  search = () => {
    this.pauseVideo();
    this.props.onSearchStart();

    this.props.openPanel(
      VIDEO_SEARCH_PANEL_NAME,
      {
        videoType: videoToSearchTypeMap[this.props.videoType],
        onVideoSelected: this.onSearchVideoSelected.bind(this),
      },
      true,
    );
  };

  upload = () => {
    this.pauseVideo();
    this.props.onUploadStart();

    this.props.openMediaManager((payload, info) => {
      this.props.onUploadDone(payload, info);
    }, this.props.origin);
  };

  render() {
    return (
      <div className="video-preview">
        {this.hasVideoPlayer() ? (
          <div key="player">
            {!this.state.hasBeenPlayed ? (
              <div
                style={this.getPosterStyle()}
                className="video-preview__poster"
              />
            ) : null}
            {this.state.hasBeenPlayed ? (
              <video
                ref={(el) => {
                  this.videoElement = el;
                }}
                src={this.getVideoUrl()}
                loop
                playsInline
                autoPlay
                className="video-preview__player"
              />
            ) : null}
            <div className="video-preview__cover">
              <div
                data-aid="mediaPlayerSettingsPlayButton"
                className="media-panel-play-button"
              >
                <input
                  type="checkbox"
                  value={this.state.isPlaying ? 'Pause Video' : 'Play Video'}
                  checked={this.state.isPlaying}
                  onChange={this.toggleVideoPlayback}
                />
                <Symbols.symbol
                  name="play-video"
                  className="play-pause-symbol"
                />
              </div>
            </div>
          </div>
        ) : null}

        {this.hasEmbeddedPlayer() ? (
          <div key="wrapper" className="video-preview__embedded-player">
            <iframe src={this.getEmbeddedUrl()} />
          </div>
        ) : null}

        {this.props.isSearchVisible && !this.state.isPlaying ? (
          <div key="search-button" className="video-preview__button-wrapper">
            <Button
              onClick={() => {
                this.search();
              }}
              className="btn-confirm-secondary"
            >
              <Symbols.symbol name="magnifyingGlass" />
              <span className="change-media-button-text">
                {translate('Video_Settings_Content_Search_Button')}
              </span>
            </Button>
          </div>
        ) : null}

        {this.props.isUploadVisible && !this.state.isPlaying ? (
          <div key="upload-button" className="video-preview__button-wrapper">
            <Button
              onClick={() => {
                this.upload();
              }}
              className="btn-confirm-secondary"
            >
              <Symbols.symbol name="image-change" />
              <span className="change-media-button-text">
                {translate('MEDIA_PLAYER_SETTINGS_MEDIA_BUTTON_LABEL')}
              </span>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const openMediaManagerAction =
  (onChange, source = 'editor_media_player') =>
  (dispatch, getState, { editorAPI }) => {
    const { mediaManager } = editorAPI.mediaServices;

    mediaManager.open(mediaManager.categories.VIDEO, source, {
      callback: onChange,
    });
  };

const mapDispatchToProps = (dispatch) => ({
  openMediaManager(onChange, source) {
    dispatch(openMediaManagerAction(onChange, source));
  },
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  closePanel(panelName) {
    dispatch(stateManagement.panels.actions.closePanelByName(panelName));
  },
});

const WrappedPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(VideoPreview);

export default WrappedPanel;
