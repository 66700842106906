// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import inputMixin from '../panelInputs/inputMixin';
import React from 'react';
import { ColorPickerInput, Composites, TextLabel } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import { generate as generateGradient } from '@wix/css-gradient-generator';
export interface ColorPickerInputProps {
  value?: any;
  colorResolver?: (value: string) => string;
  opacity?: number;
  onClick?: any;
  openColorPicker?: Function;
  onChange?: Function;
  skipLabelWrapper?: boolean;
  isStrictColorPicker?: boolean;
  label?: string;
  valueLink?: {
    value: string;
    requestChange: (value: string) => void;
  };
}
// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ColorPickerInputProps>({
  displayName: 'colorPickerInput',
  propTypes: {
    useMouseEvent: PropTypes.bool,
    position: PropTypes.object,
    openColorPicker: PropTypes.func,
    colorResolver: PropTypes.func, //so that we can support theme color pointers, e.g. 'color_9'
    previewOnHover: PropTypes.bool, // Defaults to true
    helpText: PropTypes.string,
    automationId: PropTypes.string,
    shouldTranslate: PropTypes.bool, // Defaults to true
    onClick: PropTypes.func,
    value: PropTypes.object,
    skipLabelWrapper: PropTypes.bool,
    isStrictColorPicker: PropTypes.bool,
    panelMode: PropTypes.string,
  },
  mixins: [inputMixin, util.translationMixin],
  getDefaultProps() {
    return {
      colorResolver: _.identity, //so that we don't need to pass resolveColor when using the colorpicker for basic colors
    };
  },
  getInitialState() {
    return {
      forceHighlight: false,
    };
  },
  componentDidMount() {
    this.mounted = true;
  },
  componentWillUnmount() {
    this.mounted = false;
  },
  openColorPicker(event) {
    this.setState({
      forceHighlight: true,
    });
    let { position } = this.props;
    if (!position && this.props.useMouseEvent) {
      position = { top: event.clientY, left: event.clientX };
    }
    this.props.openColorPicker(
      this.handleChange,
      util.valueLink.getValueFromProps(this.props),
      true,
      position,
      this.props.previewOnHover !== false,
      this.props.enableHistory,
      this.onColorPickerClose,
      this.props.origin,
      this.props.panelMode,
    );
  },
  handleChange(
    newValue,
    options: boolean | { isHover: boolean; isMouseOut: boolean } = {},
  ) {
    const isHover = _.isBoolean(options) ? options : options.isHover;
    util.valueLink.callOnChangeIfExists(this.props, newValue, {
      isHover,
      isMouseOut: !_.isBoolean(options) && options.isMouseOut,
    });
  },
  resolveColor() {
    const value = util.valueLink.getValueFromProps(this.props);
    if (typeof value === 'string') {
      return this.props.colorResolver(value);
    }

    if (value?.type) {
      return generateGradient(value, {
        resolveColor: this.props.colorResolver,
      });
    }

    return value;
  },
  getOpacity() {
    let opacity = 100;
    if (_.isNumber(this.props.opacity)) {
      opacity = this.props.opacity;
    }
    return opacity;
  },
  unHighlight() {
    if (this.mounted) {
      this.setState({
        forceHighlight: false,
      });
    }
  },
  onColorPickerClose() {
    if (this.mounted) {
      // in case both color picker panel and input became unmounted at the same time
      this.unHighlight();
    }
  },

  isThemeColor() {
    const value = util.valueLink.getValueFromProps(this.props);
    return value && value.startsWith('color_'); // @TODO use theme fn
  },

  render() {
    const colorPickerInput = (
      <ColorPickerInput
        value={this.resolveColor()}
        onClick={this.props.onClick ?? this.openColorPicker}
        opacity={this.getOpacity()}
        automationId={this.props.automationId}
        symbol={() =>
          this.props.isStrictColorPicker && this.isThemeColor() ? (
            <Symbols.symbol name="linkBtn" />
          ) : null
        }
      />
    );

    return this.props.skipLabelWrapper ? (
      colorPickerInput
    ) : (
      <Composites.ColorSelectLabeled>
        {this.props.label ? (
          <TextLabel
            key="colorPickerLabel"
            enableEllipsis={true}
            type="T02"
            shouldTranslate={this.props.shouldTranslate}
            value={this.props.label}
          />
        ) : null}

        {colorPickerInput}
      </Composites.ColorSelectLabeled>
    );
  },
});
