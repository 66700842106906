// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import * as util from '@/util';
import * as platformUtils from '@wix/santa-platform-utils';
import {
  Composites,
  InfoIcon,
  TextLabel,
  SecondaryMediaImage,
  Tooltip,
  Button,
  RadioButtons,
  NumericInput,
  ToggleSwitch,
  TextInput,
} from '@wix/wix-base-ui';
import Image from '../controls/image';
import * as symbols from '@wix/santa-editor-symbols';

function SecondaryImageComponent(props) {
  return (
    <Composites.SecondaryMediaLabeled>
      {!_.isEmpty(props.infoText) ? (
        <InfoIcon
          key="SecondaryMediaLabeledInfoIcon"
          shouldTranslate={props.shouldTranslate}
          text={props.infoText}
        />
      ) : null}
      <TextLabel
        value={props.displayName}
        shouldTranslate={props.shouldTranslate}
      />
      <SecondaryMediaImage
        onClick={props.onImageClick}
        automationId={props.automationId}
      >
        {props.imageData.uri ? (
          <Image
            key={props.imageData.uri}
            imageData={props.imageData}
            imageWidth={props.imageWidth}
            imageHeight={props.imageHeight}
          />
        ) : null}
      </SecondaryMediaImage>
      <Tooltip content={props.uploadButtonLabel}>
        <Button automationId="change-image-btn" onClick={props.onButtonClick}>
          <symbols.symbol name={props.symbolName} />
        </Button>
      </Tooltip>
      <Tooltip content="AppStudio_WidgetAPI_Image_DefaultImage_Delete_Tooltip">
        {props.imageData.uri ? (
          <Button onClick={props.onRemoveClick} key={props.imageData.uri}>
            <symbols.symbol name="deleteImage" />
          </Button>
        ) : null}
      </Tooltip>
    </Composites.SecondaryMediaLabeled>
  );
}

const compTypeToUIComp = {
  string: {
    composite: Composites.TextInputLabeled,
    component: TextInput,
    hasLabel: true,
    hasToolTip: true,
    supportedProps: [
      'placeholder',
      'allowInvalidChange',
      'validator',
      'invalidMessage',
      'validateInitialValue',
      'maxLength',
    ],
  },
  number: {
    composite: Composites.TextInputLabeled,
    component: NumericInput,
    hasLabel: true,
    hasToolTip: true,
    supportedProps: ['min', 'max', 'step'],
  },
  bool: {
    composite: Composites.ToggleSwitch,
    component: ToggleSwitch,
    hasLabel: false,
    hasToolTip: false,
    supportedProps: ['label'],
  },
  url: {
    composite: Composites.TextInputLabeled,
    component: TextInput,
    hasLabel: true,
    hasToolTip: true,
    validator: (url) => _.isEmpty(url) || util.validate.url(url),
    invalidMessage: 'AppStudio_WidgetAPI_URL_DefaultURL_Error',
    supportedProps: [
      'placeholder',
      'allowInvalidChange',
      'validateInitialValue',
    ],
  },
  radio: {
    composite: Composites.RadioButtonsLabeled,
    component: RadioButtons,
    hasLabel: true,
    hasToolTip: true,
    supportedProps: ['label', 'options'],
  },
  image: {
    composite: Composites.SecondaryMediaLabeled,
    component: SecondaryMediaImage,
    hasLabel: false,
    hasToolTip: true,
    supportedProps: ['infoText', 'uploadImage', 'changeImage'],
  },
  dateTime: {
    composite: Composites.TextInputLabeled,
    component: TextInput,
    hasLabel: true,
    hasToolTip: true,
    validator: (date) => _.isEmpty(date) || !isNaN(new Date(date).getDate()),
    invalidMessage: 'AppStudio_WidgetAPI_URL_DefaultDate_Error',
    supportedProps: [
      'placeholder',
      'allowInvalidChange',
      'validateInitialValue',
    ],
  },
};

// TODO fix auto panel and widget properties panel to use the same types
compTypeToUIComp.text = compTypeToUIComp.string;

const createLabel = (name, shouldTranslate = false) =>
  React.createElement(TextLabel, {
    value: name,
    shouldTranslate,
    key: `${name}_label`,
  });

const generateSecondaryImageComponent = (
  schema,
  value,
  onChange,
  uiWidgetProps,
  { openMediaManager },
) => {
  const { name, displayName, shouldTranslate = false, description } = schema;
  const {
    infoText,
    uploadImage = 'upload-btn',
    changeImage = 'image-change',
    automationId,
  } = uiWidgetProps;

  const onImageSelected = (imagesData) => {
    const selectedImage = _.head(imagesData);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const mediaItem = _.assign(
      {
        type: platformUtils.mediaItemUtils.types.IMAGE,
        mediaId: selectedImage.uri,
      },
      _.pick(selectedImage, ['title', 'width', 'height']),
    );

    const mediaSrc = platformUtils.mediaSrcHandler.createMediaSrc(mediaItem);

    onChange(name, mediaSrc.item);
  };

  const symbolName = value ? changeImage : uploadImage;
  const uploadButtonLabel = value
    ? 'AppStudio_WidgetAPI_Image_DefaultImage_Change_Tooltip'
    : 'AppStudio_WidgetAPI_Image_DefaultImage_Upload_Tooltip';

  const mediaItem =
    value && platformUtils.mediaItemUtils.parseMediaItemUri(value);

  return React.createElement(SecondaryImageComponent, {
    infoText: description || infoText,
    displayName: displayName || name,
    shouldTranslate,
    key: name,
    onImageClick: mediaItem ? _.noop : () => openMediaManager(onImageSelected),
    onRemoveClick: () => onChange(name, ''),
    imageData: {
      uri: mediaItem.mediaId,
      width: mediaItem.width,
      height: mediaItem.height,
    },
    fittingType: util.imageTransform.fittingTypes.SCALE_TO_FILL,
    imageHeight: 84,
    imageWidth: 156,
    automationId,
    onButtonClick: () => openMediaManager(onImageSelected),
    symbolName,
    uploadButtonLabel,
  });
};

const castTypeIfNeeded = (value, type) => {
  if (type === 'bool') {
    return !(value === 'false' || !value);
  }
  return value;
};

const generateUIComponent = (schema, value, onChange, extraProps) => {
  const {
    name,
    type,
    propType,
    displayName,
    shouldTranslate,
    defaultValue,
    automationId,
    validations,
    info,
    reactKey,
    validateInitialValue,
  } = schema;

  const { validator, invalidMessage } = compTypeToUIComp[propType].validator
    ? compTypeToUIComp[propType]
    : schema;

  const uiWidgetProps = _(schema)
    .defaults(validations)
    .pick(...compTypeToUIComp[propType].supportedProps)
    .assign(
      {
        value: castTypeIfNeeded(
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
          _.isUndefined(value) ? defaultValue : value,
          type,
        ),
        onChange: (newValue) => onChange(name, newValue),
        shouldTranslate: shouldTranslate ? shouldTranslate : false,
        automationId,
        key: reactKey || name,
        allowInvalidChange: true,
      },
      validator ? { validator, invalidMessage, validateInitialValue } : {},
    )
    .value();

  if (propType === 'image') {
    if (!extraProps?.openMediaManager) {
      throw new Error(
        '`openMediaManager` prop must be provided to image input',
      );
    }
    return generateSecondaryImageComponent(
      schema,
      value,
      onChange,
      uiWidgetProps,
      extraProps,
    );
  }

  const components = _.compact([
    !_.isEmpty(info) && compTypeToUIComp[propType].hasToolTip
      ? React.createElement(InfoIcon, {
          text: info,
          shouldTranslate: false,
          key: `${name}_info`,
        })
      : null,
    compTypeToUIComp[propType].hasLabel
      ? createLabel(displayName || name, shouldTranslate)
      : null,
    React.createElement(compTypeToUIComp[propType].component, uiWidgetProps),
  ]);

  return React.createElement(
    compTypeToUIComp[propType].composite,
    { key: reactKey || name },
    components,
  );
};

export default generateUIComponent;
