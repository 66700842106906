import React, { useCallback, useState } from 'react';
import { cx } from '@/util';
import { Button, TextInput } from '@wix/wix-base-ui';

interface IProps {
  onRename(newName: string): void;
  onRenameCancel(): void;

  validate?: (value: string) => string;

  labels: {
    initialValue: string;
    renameDone: string;
    placeholder: string;
  };
  maxLength?: number;
}

export const MenuItemComponentEditState = (props: IProps) => {
  const {
    labels,
    onRenameCancel,
    onRename,
    validate = () => '',
    maxLength,
  } = props;

  const [value, setValue] = useState(labels.initialValue);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRenameCancel();
        e.nativeEvent.preventDefault();
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isValid = useCallback(
    (): boolean => validate(value) === '',
    [value, validate],
  );

  const onEditCompleted = useCallback(
    () => {
      if (isValid()) {
        onRename(value);
        return;
      }

      onRenameCancel();
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, isValid],
  );

  return (
    <>
      <TextInput
        automationId="manage-menu-item-edit-input"
        className="sortable-list-item_edit-state__input"
        value={value}
        isValid={isValid()}
        onChange={setValue}
        selectOnFocus={true}
        autoSelect={true}
        onBlur={onEditCompleted}
        blurOnEscKey={false}
        invalidMessage={validate(value)}
        onKeyDown={onKeyDown}
        shouldTranslate={false}
        placeholder={labels.placeholder}
        maxLength={maxLength}
      />
      <Button
        className={cx('btn-sm', 'sortable-list-item_edit-state__button')}
        disabled={!isValid()}
      >
        {labels.renameDone}
      </Button>
    </>
  );
};
