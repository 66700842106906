// @ts-nocheck
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import inputMixin from '../inputMixin';
import dropdownManager from './dropdownManager';
import cacheMixin from './cacheMixin';
import reportUIChangeMixin from '../../mixins/reportUIChangeMixin';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../../controls/infoIcon';
import experiment from 'experiment';
import { cx } from '@/util';

function template() {
  return (
    <div
      onKeyDown={this.onKeyDown}
      tabIndex="0"
      {...this.getFilteredProps()}
      className={cx(
        `dropdown ${this.getDDClassName()}`,
        this.getFilteredProps()?.className,
      )}
      style={{
        ...this.props.style,
        ...this.getFilteredProps()?.style,
      }}
    >
      {this.hasLabel() ? (
        <label key="dropdownLabel" className="dropdown-label">
          {this.props.shouldTranslate
            ? translate(this.getLabel())
            : this.getLabel()}
        </label>
      ) : null}

      {this.hasLabel() && (this.props.infoText || this.props.infoTitle) ? (
        <InfoIcon
          key="infoIcon"
          text={this.props.infoText}
          title={this.props.infoTitle}
          linkAction={this.props.linkAction}
          learnMoreText={this.props.learnMoreText}
        />
      ) : null}

      <div
        ref="dropdown"
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        onContextMenu={() => {
          if (experiment.isOpen('rightClickBehaviors')) {
            this.show();
          }
        }}
        className="dd"
      >
        <div ref="selectedContainer" className="selected-container">
          {this.props.template.call(this)}
        </div>

        {this.props.toggleIcon ? (
          <i key="toggleIcon" className="expand arrow">
            <symbols.symbol name={this.props.toggleIconName || 'arrowDown'} />
          </i>
        ) : null}
      </div>
    </div>
  );
}

function getSelectedIndex(options, value) {
  let selectedIndex = 0;

  options.some(function (child, i) {
    const isSelected = child.props.value === value;

    if (isSelected) {
      selectedIndex = i;
    }

    return isSelected;
  });

  return selectedIndex;
}

function isOptionSelected(selectedIndex, value, child) {
  const childValue = child.props.value;

  return (
    selectedIndex === undefined &&
    childValue !== undefined &&
    value === child.props.value
  );
}

function getShouldTranslate(item, parentShouldTranslate) {
  return item.props.shouldTranslate === undefined
    ? parentShouldTranslate
    : item.props.shouldTranslate;
}

const TOGGLE_KEY_CODES = [32, 38, 40];

export default {
  mixins: [inputMixin, cacheMixin, reportUIChangeMixin],

  propTypes: {
    label: PropTypes.string,
    searchText: PropTypes.string,
    tabIndex: PropTypes.number,
    scrollSpeed: PropTypes.number,
    optionsWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toggleIcon: PropTypes.bool,
    toggleIconName: PropTypes.string,
    showScrollBar: PropTypes.bool,
    showScroll: PropTypes.bool,
    template: PropTypes.func,
    infoText: PropTypes.string,
    setSelectedAnyway: PropTypes.bool,
    onHover: PropTypes.func,
    onClick: PropTypes.func,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
    infoTitle: PropTypes.string,
    onOptionsVisible: PropTypes.func,
    linkAction: PropTypes.func,
    learnMoreText: PropTypes.string,
  },

  render: template,

  getDefaultProps() {
    return {
      shouldTranslate: true,
    };
  },

  getInitialState() {
    const value = util.valueLink.getValueFromProps(this.props);
    const optionsData = this.getOptionsData(value, this.props.children);
    const { options } = optionsData;
    const { selectedIndex } = optionsData;
    const selectedData = this.getSelectedData(selectedIndex, options);

    this.updateCache({
      selectedContent: selectedData.content,
      selectedSearchText: selectedData.searchText,
      options,
      items: optionsData.items,
      footer: optionsData.footer,
      searchTexts: optionsData.searchTexts,
    });

    return {
      value,
      selectedIndex,
      expanded: false,
    };
  },

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const isClosed = !nextState.expanded;
    const isSelectedChanged =
      nextState.selectedIndex !== this.state.selectedIndex;

    if (isClosed || isSelectedChanged) {
      const selectedData = this.getSelectedData(
        nextState.selectedIndex,
        this.getCached('options'),
      );

      this.updateCache({
        selectedContent: selectedData.content,
        selectedSearchText: selectedData.searchText,
      });
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    let selectedData;
    const newVal = util.valueLink.getValueFromProps(nextProps);

    if (nextProps.children !== this.props.children) {
      const optionsData = this.getOptionsData(newVal, nextProps.children);

      selectedData = this.getSelectedData(
        optionsData.selectedIndex,
        optionsData.options,
      );

      this.updateCache({
        selectedContent: selectedData.content,
        selectedSearchText: selectedData.searchText,
        options: optionsData.options,
        items: optionsData.items,
        footer: optionsData.footer,
        searchTexts: optionsData.searchTexts,
      });

      this.setState({ selectedIndex: optionsData.selectedIndex });
    }

    if (util.valueLink.getValueFromProps(this.props) !== newVal) {
      const options = this.getCached('options');
      const selectedIndex = getSelectedIndex(options, newVal);

      selectedData = this.getSelectedData(selectedIndex, options);

      this.updateCache({
        selectedContent: selectedData.content,
        selectedSearchText: selectedData.searchText,
      });

      this.setState({
        value: newVal,
        selectedIndex,
      });
    }
  },

  componentDidMount() {
    this.mounted = true;
  },

  componentWillUnmount() {
    this.mounted = false;
    if (this.isExpanded()) {
      dropdownManager.hideOptions();
    }
  },

  getSelectedData(selectedIndex, options) {
    const selectedData = {
      searchText: '',
      content: '',
    };

    if (options.length) {
      const selectedOption = options[selectedIndex];
      let content = selectedOption.props.children;
      let { searchText } = selectedOption.props;

      if (content) {
        if (typeof content === 'string') {
          content = content.trim();
          selectedData.content = getShouldTranslate(
            selectedOption,
            this.props.shouldTranslate,
          )
            ? translate(content)
            : content;
        } else {
          selectedData.content = content;
        }
      }

      if (searchText) {
        searchText = searchText.trim();
        selectedData.searchText = getShouldTranslate(
          selectedOption,
          this.props.shouldTranslate,
        )
          ? translate(searchText)
          : searchText;
      }
    }

    return selectedData;
  },

  cloneOption(option, index) {
    return React.cloneElement(option, {
      index,
      key: `option-${index}`,
      shouldTranslate: getShouldTranslate(option, this.props.shouldTranslate),
    });
  },

  cloneOptgroup(optgroup, newChildren) {
    return React.cloneElement(optgroup, {
      children: newChildren,
      shouldTranslate: getShouldTranslate(optgroup, this.props.shouldTranslate),
    });
  },

  cloneFooter(footer) {
    return React.cloneElement(footer, {
      shouldTranslate: getShouldTranslate(footer, this.props.shouldTranslate),
    });
  },

  getSearchText(option) {
    let { searchText } = option.props;

    if (searchText) {
      searchText = searchText.trim();

      return getShouldTranslate(option, this.props.shouldTranslate)
        ? translate(searchText)
        : searchText;
    }

    return '';
  },

  parseChildren(value, children, recursionData) {
    //TODO: decouple
    recursionData = recursionData || {};
    let optionIndex = recursionData.optionIndex || 0;
    let { selectedIndex } = recursionData;
    const self = this;

    const optionsData = {
      items: [],
      options: [],
      selectedIndex: undefined,
      footer: null,
      searchTexts: [],
    };

    React.Children.forEach(children, function (child) {
      if (React.isValidElement(child)) {
        if (child.props.type === 'footer') {
          optionsData.footer = self.cloneFooter(child);
        } else {
          let item = child;

          if (child.props.type === 'option') {
            if (isOptionSelected(selectedIndex, value, child)) {
              selectedIndex = optionIndex;
            }

            item = self.cloneOption(child, optionIndex);
            optionsData.options.push(item);
            optionsData.searchTexts.push(self.getSearchText(child));
            optionIndex++;
          } else if (child.props.type === 'optgroup') {
            const optgroupOptionsData = self.parseChildren(
              value,
              child.props.children,
              {
                optionIndex,
                selectedIndex,
              },
            );

            const optgroupOptions = optgroupOptionsData.options;

            item = self.cloneOptgroup(child, optgroupOptionsData.items);
            optionsData.options = optionsData.options.concat(optgroupOptions);
            optionsData.searchTexts = optionsData.searchTexts.concat(
              optgroupOptionsData.searchTexts,
            );
            selectedIndex = optgroupOptionsData.selectedIndex;
            optionIndex += optgroupOptions.length;
          }

          optionsData.items.push(item);
        }
      }
    });

    if (selectedIndex) {
      optionsData.selectedIndex = selectedIndex;
    }

    return optionsData;
  },

  getOptionsData(value, children) {
    // TODO: refactor
    const optionsData = this.parseChildren(value, children);

    if (optionsData.selectedIndex === undefined) {
      optionsData.selectedIndex = 0;
    }

    return optionsData;
  },

  getData() {
    return {
      items: this.getCached('items'),
      footer: this.getCached('footer'),
      selectedIndex: this.state.selectedIndex,
      className: this.className,
      showScrollBar: this.props.showScrollBar,
    };
  },

  getDDClassName() {
    let addClassName = '';

    if (this.className) {
      addClassName += ` ${this.className}`;
    }

    if (this.props.className) {
      addClassName += ` ${this.props.className}`;
    }

    if (this.state.expanded) {
      addClassName += ' expanded';
    }

    if (this.props.showScroll) {
      addClassName += ' show-scroll';
    }

    if (this.isDropDownDisabled()) {
      addClassName += ' disabled';
    }

    return addClassName.trim();
  },

  getDDEl() {
    return ReactDOM.findDOMNode(this.refs.dropdown);
  },

  getOnChange(newValue) {
    return () => util.valueLink.callOnChangeIfExists(this.props, newValue);
  },

  setSelected(optionData) {
    const newValue = optionData.value;
    const newIndex = optionData.index;

    if (
      this.props.setSelectedAnyway ||
      this.state.value !== newValue ||
      this.state.selectedIndex !== newIndex
    ) {
      this.reportUIChange({ value: newValue });

      this.setState(
        {
          value: newValue,
          selectedIndex: newIndex,
        },
        this.getOnChange(newValue),
      );
    }
  },

  toggle() {
    if (!this.isDropDownDisabled()) {
      if (!this.isExpanded()) {
        dropdownManager.show(this);
        if (_.isFunction(this.props.onExpand)) {
          this.props.onExpand();
        }
      } else {
        dropdownManager.hide();
      }
    }
  },

  isDropDownDisabled() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    const isChildrenEmpty = _.isArray(this.props.children)
      ? !this.props.children.length
      : !this.props.children;

    return this.props.disabled || isChildrenEmpty;
  },

  onKeyDown(e) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(TOGGLE_KEY_CODES, e.keyCode)) {
      this.toggle();
      e.preventDefault();
      e.stopPropagation();
    }
  },

  focus() {
    if (this.mounted) {
      ReactDOM.findDOMNode(this).focus();
    }
  },

  isExpanded() {
    return dropdownManager.isExpanded(this);
  },

  setExpanded(isExpanded) {
    this.setState({ expanded: isExpanded });
    if (!isExpanded && _.isFunction(this.props.onCollapse)) {
      this.props.onCollapse();
    }
  },

  getFocusEl() {
    const { focusElement } = this.refs;

    if (focusElement) {
      return ReactDOM.findDOMNode(focusElement);
    }
  },

  getSearchTexts() {
    return this.getCached('searchTexts');
  },

  onHover(optionData) {
    if (this.props.onHover) {
      this.props.onHover(optionData);
    }
  },

  onOptionsVisible(optionsEl) {
    if (this.props.onOptionsVisible) {
      this.props.onOptionsVisible(optionsEl);
    }
  },

  onMouseDown(e) {
    if (dropdownManager.isExpanded(this)) {
      e.nativeEvent.stopImmediatePropagation();
    }
  },

  onClick(e) {
    this.toggle();

    if (_.isFunction(this.props.onClick)) {
      this.props.onClick(e);
    }
  },

  getFilteredProps() {
    return _.omit(this.filteredProps(), 'shouldTranslate');
  },
};
