// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import mediaServicesMixin from '../mixins/mediaServicesMixin';
import inputMixin from './inputMixin';

import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import UiButton from '../controls/button';

const mediaTypes = {
  Image: 'IMAGE',
  SingleAudioPlayer: 'MUSIC',
  AudioPlayer: 'MUSIC',
  Default: 'DOCUMENT',
  Flash: 'FLASH',
};

const selectionTypes = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'changeMediaButton',
  mixins: [inputMixin, mediaServicesMixin],
  propTypes: {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    mediaType: PropTypes.oneOf(_.keys(mediaTypes)),
    buttonLabelWithItems: PropTypes.string,
    buttonLabelWithoutItems: PropTypes.string,
    value: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    selectionType: PropTypes.oneOf(_.values(selectionTypes)),
    mediaManagerHeaderLabel: PropTypes.string,
    mediaManagerButtonLabel: PropTypes.string,
    mediaManagerBiLabel: PropTypes.string,
    onClick: PropTypes.func,
    mediaServices: PropTypes.object,
  },
  getButtonLabel() {
    return this.hasFile()
      ? this.props.buttonLabelWithItems
      : this.props.buttonLabelWithoutItems;
  },
  getDefaultProps() {
    return {
      mediaType: 'Default',
      buttonLabelWithItems: 'CHANGE_MEDIA',
      buttonLabelWithoutItems: 'ADD_MEDIA',
      selectionType: 'multiple',
    };
  },
  openMediaFrame() {
    if (this.props.onClick) {
      this.props.onClick();
    }

    const mediaServices = this.getMediaServices() || this.props.mediaServices;
    const { mediaManager } = mediaServices;
    const mediaManagerArgs = [mediaTypes[this.props.mediaType]];

    if (this.props.mediaManagerBiLabel) {
      mediaManagerArgs.push(this.props.mediaManagerBiLabel);
    }

    let mediaManagerKeys;

    if (
      this.props.mediaManagerHeaderLabel ||
      this.props.mediaManagerButtonLabel
    ) {
      mediaManagerKeys = {};
      mediaManagerKeys.title = translate(this.props.mediaManagerHeaderLabel);
      mediaManagerKeys.submitButton = translate(
        this.props.mediaManagerButtonLabel,
      );
    }

    mediaManagerArgs.push({
      translation: mediaManagerKeys,
      callback: (items, info) => {
        this.onClose(items, info);
        mediaServices.setFocusToEditor();
      },
      multiSelect: this.props.selectionType === selectionTypes.MULTIPLE,
    });

    mediaManager.open(...mediaManagerArgs);
  },
  getButtonProps() {
    const props = {
      className: 'btn-confirm-secondary upload-button no-margin',
      onClick: this.openMediaFrame,
      label: this.getButtonLabel(),
    };
    if (this.hasFile()) {
      props.icon = 'organizeImagesIcon';
      props.className += ' has-file';
    }
    return props;
  },
  onClose(payload) {
    if (!payload) {
      return;
    }
    util.valueLink.callOnChangeIfExists(this.props, ...arguments);
  },
  hasFile() {
    return this.props.value;
  },
  render() {
    return (
      <div className="media-button">
        <UiButton {...this.getButtonProps()} />
        {this.hasFile() ? (
          <div key="hasfile" className="media-button-filename-text">
            <span className="wrapper">
              {this.props.value}
              <symbols.symbol name="inputValidationSuccess" />
            </span>
          </div>
        ) : null}
      </div>
    );
  },
});
