import React, {
  type CSSProperties,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { cx } from '@/util';
import * as baseUI from '@/baseUI';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { type ConnectDragSource, useDrag, useDrop } from 'react-dnd';
import { TextLabel } from '@wix/wix-base-ui';

import { MenuItemContextMenu } from './MenuItemContextMenu';
import { MenuItemComponentEditState } from './MenuItemComponentEditState';
import { useDropZone } from './hooks';
import {
  DropMode,
  type IMenuItem,
  type IMenuItemProps,
  type IMenuItemId,
  type IAction,
} from '../types';
import { MenuItemSuffix } from './MenuItemSuffix';

const dropZoneSize = '12';

type IViewProps = Pick<
  IMenuItemProps,
  'isCollapsed' | 'toggleCollapse' | 'suffixes'
> & {
  symbol: string;
  drag: ConnectDragSource;
  label: string;
  isContextMenuOpen: boolean;
  onContextMenuToggle(isOpened: boolean): void;
  id: IMenuItemId;
  hasChildren: boolean;
  isChild: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  actions: IAction[];
  nestingLevel: number;
  customButton?: React.ReactNode;
};

export const View: React.FC<IViewProps> = ({
  isCollapsed,
  toggleCollapse,
  symbol,
  drag,
  label,
  id,
  actions,
  suffixes,
  isContextMenuOpen,
  onContextMenuToggle,
  hasChildren,
  isChild,
  onClick,
  onDoubleClick,
  nestingLevel,
  customButton,
}) => {
  const prevent = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );

  const wrapperProps = {
    className: 'sortable-list-item__view',
    onClick,
    onDoubleClick,
    ref: drag,
  };

  return (
    <div {...wrapperProps} data-aid="manage-menu-item-view">
      <div className="sortable-list-item__drag-indicator">
        <Symbol name="dragPage" />
      </div>
      <div
        className={cx({
          'sortable-list-item__arrow': hasChildren,
          'sortable-list-item__arrow_closed': hasChildren && !isCollapsed,
          'sortable-list-item__nesting-line': isChild,
          'sortable-list-item__nesting-line__sub': nestingLevel > 1,
        })}
        onClick={(e) => {
          prevent(e);
          toggleCollapse(id);
        }}
      />
      <div className="sortable-list-item__prefix">
        <baseUI.SymbolWrapper name={symbol} />
      </div>
      <div
        data-aid="sortable-list-item-label"
        className="sortable-list-item__text"
      >
        <TextLabel type="T07" value={label} shouldTranslate={false} />
      </div>
      <div className="sortable-list-item__suffix">
        {suffixes?.map((suffix, index) => (
          <MenuItemSuffix {...suffix} key={index} />
        ))}
      </div>
      <span onClick={prevent}>
        {customButton ? (
          <div className="sortable-list-item__custom-button">
            {customButton}
          </div>
        ) : (
          <MenuItemContextMenu
            actions={actions}
            isContextMenuOpen={isContextMenuOpen}
            onContextMenuToggle={onContextMenuToggle}
          />
        )}
      </span>
    </div>
  );
};

export const MenuItem: React.FC<IMenuItemProps> = (props) => {
  const {
    item,
    getActions,
    suffixes,
    editingId,
    toggleCollapse,
    isCollapsed,
    onDrop,
    isDraggingAnyItem,
    setDragging,
    checkCanDrop,
    onRename,
    onRenameCancel,
    onContextMenuOpen,
    nestingLevel = 0,
    isLastOnCurrentLevel = false,
    isLastOnUpperLevel = false,
    onClick,
    onDoubleClick,
    isSelected,
    getItemLocalizations,
    maxItemNameLength,
    isOverAfterList,
    isOverBeforeList,
    blockDragAndDrop = false,
    automationId,
  } = props;

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

  const [actions, setActions] = useState([]);

  const handleContextMenuToggle = useCallback(
    (isOpened: boolean) => {
      setIsContextMenuOpen(isOpened);

      if (isOpened) {
        setActions(getActions(item));
        onContextMenuOpen(item.item);
      }
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, getActions],
  );

  const { label, items, customButton } = item.item;

  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: 'BasicMenuItem',
    drop(droppedItem, monitor) {
      const didDrop = monitor.didDrop();
      const canDrop = checkCanDrop(props, droppedItem as IMenuItem);

      if (didDrop || !canDrop) {
        return;
      }

      onDrop(droppedItem as IMenuItem, item.id);
    },

    collect: (monitor) => {
      const dragItem = monitor.getItem();

      if (!dragItem) {
        return {
          isOverCurrent: false,
          canDrop: false,
        };
      }

      const canDrop = checkCanDrop(props, dragItem);
      const isOverCurrent = monitor.isOver({ shallow: true });

      return {
        isOverCurrent,
        canDrop,
      };
    },
  });

  const [
    { dropOnZone: dropBefore, canDropOnZone: canDropBefore },
    dropBeforeZone,
  ] = useDropZone({
    checkCanDrop,
    props,
    onDrop,
    dropMode: DropMode.before,
  });

  const [
    { dropOnZone: dropAfter, canDropOnZone: canDropAfter },
    dropAfterZone,
  ] = useDropZone({
    checkCanDrop,
    props,
    onDrop,
    dropMode: DropMode.after,
  });

  const [{ isCurrentDragging }, drag, preview] = useDrag({
    item: item.item,
    collect: (monitor) => ({
      isCurrentDragging: monitor.isDragging(),
    }),
    begin: () => {
      setDragging(true);
    },
    end: () => {
      setDragging(false);
    },
    canDrag: () => !blockDragAndDrop,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const innerProps: IViewProps = {
    isContextMenuOpen,
    onContextMenuToggle: handleContextMenuToggle,
    isCollapsed,
    toggleCollapse,
    actions,
    suffixes,
    label,
    drag,
    id: item.id,
    symbol: item.item.symbol,
    hasChildren: items.length > 0,
    isChild: nestingLevel > 0,
    onClick: () => onClick?.(item.id),
    onDoubleClick: () => onDoubleClick?.(item.id),
    nestingLevel,
    customButton,
  };

  const isEditing = editingId === item.id;

  const { inputPlaceholder, submitRenameButton, validateName } = useMemo(
    () => getItemLocalizations(item.item),
    [item.item, getItemLocalizations],
  );

  const showDropZone = isDraggingAnyItem && !isCurrentDragging;

  return (
    <>
      <div
        ref={drop}
        className={cx('sortable-list-item', {
          'sortable-list-item_selected': isSelected,
          'sortable-list-item_nested': nestingLevel > 0,
          'sortable-list-item_last': nestingLevel > 0 && isLastOnCurrentLevel,
          'sortable-list-item_last-upperlevel':
            nestingLevel > 0 && isLastOnUpperLevel,
          'sortable-list-item_with-arrow': items.length > 0,
          'sortable-list-item_dragging': isCurrentDragging,
          'sortable-list-item_drop-on': isOverCurrent && canDrop,
          'sortable-list-item_edit-state': isEditing,
          'sortable-list-item_block-dnd': blockDragAndDrop,
        })}
        style={
          {
            '--nestingLevel': nestingLevel,
            '--dropZoneSize': dropZoneSize,
          } as CSSProperties
        }
        data-aid={`sortable-list-item ${automationId || ''}`}
      >
        {showDropZone && (
          <div
            ref={dropBeforeZone}
            className={cx(
              'sortable-list-item__dropzone',
              'sortable-list-item__dropzone_before',
              {
                'sortable-list-item__dropzone_drop':
                  (dropBefore || isOverBeforeList) && canDropBefore,
              },
            )}
          />
        )}

        {isEditing ? (
          <MenuItemComponentEditState
            labels={{
              initialValue: label,
              renameDone: submitRenameButton,
              placeholder: inputPlaceholder,
            }}
            validate={validateName}
            onRename={(name: string) => onRename(item.id, name)}
            onRenameCancel={onRenameCancel}
            maxLength={maxItemNameLength}
          />
        ) : (
          <View {...innerProps} />
        )}

        {showDropZone && (
          <div
            ref={dropAfterZone}
            className={cx(
              'sortable-list-item__dropzone',
              'sortable-list-item__dropzone_after',
              {
                'sortable-list-item__dropzone_drop':
                  (dropAfter || isOverAfterList) && canDropAfter,
              },
            )}
          />
        )}
      </div>
    </>
  );
};
