export enum EntryType {
  MANUAL = 'maual',
  CALCULATION = 'calculation',
}

export function calculateInput(enteredValue: string | number) {
  if (typeof enteredValue === 'number') {
    if (isNaN(enteredValue)) {
      return;
    }

    return {
      value: Math.round(enteredValue),
      entryType: EntryType.MANUAL,
    };
  }

  const trimmedValue = enteredValue.trim();

  if (!isNaN(Number(trimmedValue))) {
    return {
      value: Math.round(Number(trimmedValue)),
      entryType: EntryType.MANUAL,
    };
  }

  if (!/^\d+\s?[+\-*\/]?\s?\d+?$/.test(trimmedValue)) {
    return;
  }

  const operands = trimmedValue.split(/[-+*/\s]/).filter((item) => item !== '');
  const operator = trimmedValue
    .split(/[0-9]/)
    .filter((item) => item !== '')
    ?.map((item) => item?.trim());

  if ((!operator && trimmedValue.length > 1) || operator[0] === '') {
    return;
  }

  if (!operator && trimmedValue) {
    return {
      value: Math.round(Number(trimmedValue)),
      entryType: EntryType.MANUAL,
    };
  }

  let calculatedValue = Number(operands[0]);

  if (operator.length > 1) {
    return;
  }

  if (operands.length > 1) {
    switch (operator[0]) {
      case '+':
        calculatedValue += Number(operands[1]);
        break;

      case '-':
        calculatedValue -= Number(operands[1]);
        break;

      case '*':
        calculatedValue *= Number(operands[1]);
        break;

      case '/':
        if (Number(operands[1]) === 0) {
          return;
        }

        calculatedValue /= Number(operands[1]);
        break;

      default:
        return;
    }
  }

  return {
    value: Math.round(calculatedValue),
    entryType: !operator ? EntryType.MANUAL : EntryType.CALCULATION,
  };
}
