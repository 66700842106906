import * as React from 'react';
import styles from './gfppBackgroundIcon.scss';
import { cx } from '@/util';

interface GfppBackgroundIconProps {
  iconInfo: React.CSSProperties & {
    underlayOpacity?: number;
    underlayBackgroundImage?: string;
  };
  isStandaloneIcon: boolean;
  buttonClassName?: string;
}

const selectedClassName = 'selected';

export const GfppBackgroundIcon: React.FC<GfppBackgroundIconProps> = ({
  iconInfo,
  isStandaloneIcon,
  buttonClassName,
}) => {
  const {
    underlayOpacity,
    underlayBackgroundImage,
    backgroundColor,
    ...backgroundMediaProperties
  } = iconInfo;
  const parentClassesNames = buttonClassName.split(' ');
  return (
    <div
      className={cx(styles.container, {
        [styles['with-increased-size']]: isStandaloneIcon,
        [styles.selected]:
          isStandaloneIcon && parentClassesNames.includes(selectedClassName),
      })}
    >
      <div className={styles['empty-state']} />
      <div
        className={styles['fill-layer']}
        style={{
          backgroundColor,
          backgroundImage: underlayBackgroundImage,
          opacity: underlayOpacity ?? iconInfo.opacity,
        }}
      />
      {iconInfo.backgroundImage && (
        <div
          className={styles['media-container']}
          style={backgroundMediaProperties}
        />
      )}
    </div>
  );
};
