// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import ImageChange from './imageChange';

const ADJUST_BUTTON_TEXT = 'Image_Settings_Adjust_Button';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'imageChangeAdjust',
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    removeButtonLabel: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
    onChangeClick: PropTypes.func,
    onAdjustClick: PropTypes.func,
  },
  getAdjustButton() {
    if ((util.valueLink.getValueFromProps(this.props) || {}).uri) {
      return {
        nonEmptyButtonLabel: ADJUST_BUTTON_TEXT,
        nonEmptyButtonIcon: 'adjust',
        onClick: this.props.onAdjustClick,
        disabled: this.props.disabled,
      };
    }
  },
  render() {
    return (
      <ImageChange
        value={this.props.value}
        onChange={this.props.onChange}
        valueLink={this.props.valueLink}
        changeButtonLabel="Image_Settings_Change_Button"
        additionalButtons={this.getAdjustButton()}
        emptySymbolName="camera"
        disabled={this.props.disabled}
        onClick={this.props.onChangeClick}
        {...this.filteredProps()}
      />
    );
  },
});
