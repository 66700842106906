// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import $ from 'zepto';
import _ from 'lodash';
import * as util from '@/util';
import statefulInputMixin from './statefulInputMixin';
import inputValidationMixin from './inputValidationMixin';
import tooltipManager from '../popovers/tooltipManager';
import React from 'react';
import UIButton from '../controls/button';
import Tooltip from '../popovers/tooltip';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'textInputWithFixedButton',
  propTypes: {
    buttonLabel: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    shouldChangeOnOuterClick: PropTypes.bool,
    onOuterClick: PropTypes.func,
  },
  mixins: [statefulInputMixin, inputValidationMixin, util.translationMixin],
  getDefaultProps() {
    return {
      dir: 'ltr',
      shouldChangeOnOuterClick: false,
    };
  },
  getInitialState() {
    this.uniqueTooltipId = _.uniqueId();
    const value =
      util.valueLink.getValueFromProps(this.props) || this.props.defaultValue;
    return {
      value,
      origValue: value,
      invalidMessage: '',
      showValidationIcon: true,
      isFocused: false,
    };
  },
  uniqueTooltipId: '',
  setFocusState(isFocused) {
    const selectText =
      isFocused &&
      this.props.selectOnFocus &&
      function () {
        $('input', ReactDOM.findDOMNode(this)).select();
      };

    this.setState({ isFocused }, selectText);
  },

  handleChange(evt) {
    const { value } = evt.target;
    this.setState(
      { value: util.stringUtils.removeNonSupportedCharacters(value) },
      function () {
        if (!this.isValid()) {
          tooltipManager.show(this.getTooltipId());
        } else {
          tooltipManager.hide(this.getTooltipId());
        }
      }.bind(this),
    );
  },

  handleClick() {
    this.onChange();
  },

  onOuterClick() {
    if (this.props.shouldChangeOnOuterClick) {
      this.onChange();
    }

    if (this.props.onOuterClick) {
      this.props.onOuterClick();
    }
  },

  onChange() {
    if (this.isValid(this.state.value)) {
      util.valueLink.callOnChangeIfExists(this.props, this.state.value);
      this.setFocusState(false);
    }
  },

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleClick();
    }
  },

  getTooltipId() {
    return `text-input-with-button-validation-error${this.uniqueTooltipId}`;
  },

  getTextInputNode() {
    // also not possible to set ref to children of tooltip
    return ReactDOM.findDOMNode(this.refs.tooltip);
  },

  focusControl() {
    this.setFocusState(true);
    this.getTextInputNode().select();
  },

  render() {
    const isValidResult = this.isValid();

    return (
      <util.outerClick onOuterClick={this.onOuterClick}>
        <div
          className={cx({
            'control-text-input-with-fixed-button': true,
            invalid: !isValidResult,
            success: isValidResult,
            focused: this.state.isFocused,
          })}
          {...this.filteredProps()}
        >
          <Tooltip
            id={this.getTooltipId()}
            value={this.getValidationStatusMessage()}
            disabled={isValidResult}
            ref="tooltip"
          >
            <input
              value={this.state.value}
              onChange={this.handleChange}
              maxLength={this.props.maxLength}
              onFocus={() => {
                this.setFocusState(true);
              }}
              onKeyPress={this.onKeyPress}
              type="text"
              autoFocus="true"
              dir={this.props.dir}
              className="control-text"
            />
          </Tooltip>

          <UIButton
            ref="actionButton"
            onClick={this.handleClick}
            label={this.props.buttonLabel}
            className={cx({ disabled: !isValidResult })}
          />
        </div>
      </util.outerClick>
    );
  },
});
