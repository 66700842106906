// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import ImageChange from './imageChange';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'imageChangeRemove',
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    removeButtonLabel: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },
  getRemoveButton() {
    if ((util.valueLink.getValueFromProps(this.props) || {}).uri) {
      return {
        nonEmptyButtonLabel: this.props.removeButtonLabel,
        nonEmptyButtonIcon: 'delete',
        onClick: this._removeImage,
      };
    }
  },
  _removeImage(doneCB) {
    doneCB(null);
  },
  render() {
    return (
      <ImageChange
        value={this.props.value}
        onChange={this.props.onChange}
        valueLink={this.props.valueLink}
        additionalButtons={this.getRemoveButton()}
        emptySymbolName="camera"
        {...this.filteredProps()}
      />
    );
  },
});
