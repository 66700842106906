import React from 'react';
import { Composites, CollapseLabel, InfoIcon } from '@wix/wix-base-ui';
import type { CollapseLabelSkin } from '@wix/wix-base-ui';
import { translate } from '@/i18n';

interface OptionalCollapseLabelInnerProps {
  name: string;
  label: string;
  linkText?: string;
  onLinkClick?: () => void;
  children: React.ReactNode | React.ReactNode[];
}

export interface OptionalCollapseLabelGetterProps {
  categoryList: string[];
  selectedCategories: string[];
  tabsUiMode: 'tabs' | 'lables';
  tooltipText?: string;
  shouldTranslate?: boolean;
  tabsUiSkin?: CollapseLabelSkin;
}

export interface OptionalCollapseLabelSetterProps {
  updateSelectedCategories: (
    selectedCategories: string[],
    name: string,
    isOpen: boolean,
  ) => void;
}

export function OptionalCollapseLabel({
  name,
  children,
  categoryList,
  selectedCategories,
  tabsUiMode,
  tabsUiSkin,
  label,
  shouldTranslate = false,
  updateSelectedCategories,
  tooltipText,
  linkText,
  onLinkClick,
}: OptionalCollapseLabelGetterProps &
  OptionalCollapseLabelSetterProps &
  OptionalCollapseLabelInnerProps): JSX.Element {
  const isEnabled = categoryList.includes(name);
  const isOpen = selectedCategories.includes(name);
  const content = isOpen ? children : null;

  if (!isEnabled) {
    return null;
  }

  if (tabsUiMode === 'tabs') {
    return content as JSX.Element;
  }

  return (
    <Composites.CollapseLabelWithInfoIcon>
      <CollapseLabel
        label={shouldTranslate ? translate(label) : label}
        shouldTranslate={false}
        isOpen={isOpen}
        skin={tabsUiSkin}
        customToggle={() =>
          updateSelectedCategories(selectedCategories, name, !isOpen)
        }
        dataHook={name}
      >
        {content}
      </CollapseLabel>
      {tooltipText ? (
        <InfoIcon
          linkText={linkText || ''}
          onLinkClick={onLinkClick}
          text={tooltipText}
        />
      ) : null}
    </Composites.CollapseLabelWithInfoIcon>
  ) as JSX.Element;
}
