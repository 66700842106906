// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import inputMixin from '../panelInputs/inputMixin';
import * as util from '@/util';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'Button2',
  mixins: [inputMixin, util.translationMixin],
  propTypes: {
    label: PropTypes.string,
    className: PropTypes.string,
    wrapperClass: PropTypes.string,
    wrapperStyle: PropTypes.object,
    disabled: PropTypes.bool,
    symbolName: PropTypes.string,
    hintId: PropTypes.string,
    dataHook: PropTypes.string,
  },
  getWrapperProps(defaultProps) {
    const props = {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(props, defaultProps);

    if (!_.isEmpty(this.props.wrapperClass)) {
      props.class = _.compact([props.class, this.props.wrapperClass].join(' '));
    }
    if (!_.isEmpty(this.props.wrapperStyle)) {
      props.style = this.props.wrapperStyle;
    }

    return props;
  },
  render() {
    return (
      <div {...this.getWrapperProps({ className: 'control-button2-wrapper' })}>
        <div
          disabled={this.props.disabled ? 'disabled' : ''}
          {...this.filteredProps()}
          className={cx(
            this.getClassName('control-button2'),
            this.filteredProps()?.className,
          )}
          data-hook={this.props.dataHook}
        >
          <input
            type="button"
            disabled={this.props.disabled ? 'disabled' : ''}
          />
          {this.props.symbolName ? (
            <symbols.symbol
              name={this.props.symbolName}
              key="symbol"
              className="symbol"
            />
          ) : null}
          {this.props.label ? (
            <span
              key="label"
              className={`label ${this.props.customLabelClass}`}
            >
              {this.translateIfNeeded(this.props.label)}
            </span>
          ) : null}
        </div>
      </div>
    );
  },
});
