import React, { type FC } from 'react';
import styles from './message.scss';
import { cx } from '@/util';
import { Text } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import MessageControls from '../messageControls';

// import {
//   ChatCompletionRequestMessageRoleEnum,
//   type ChatCompletionRequestMessage,
// } from '../types';
import type { MessageTypes } from './messageTypes';
// import type { OnSuggestionSelectParams } from '../chat/chatTypes';

// interface OnSuggestionSelectParams {
//   value: string;
//   label: string;
// }

const Message: FC<MessageTypes> = ({
  content,
  userMessage = false,
  withControls = false,
  // withSuggestions = false,
  // onSeeOrLessMoreClick,
}) => {
  // const [t] = useTranslation();

  // const handleSuggestionSelect = (_params: OnSuggestionSelectParams) => {
  // const userMessage =
  //   typeof params.value === 'string' ? params.value : params.value.value;
  // const chatHistory = [
  //   ...chatContext.messages,
  //   {
  //     role: ChatCompletionRequestMessageRoleEnum.ASSISTANT,
  //     content: chatContext.systemMessage.content,
  //   },
  //   {
  //     role: ChatCompletionRequestMessageRoleEnum.USER,
  //     content: userMessage,
  //   },
  // ] as ChatCompletionRequestMessage[];
  // chatContext.setMessages(chatHistory);
  // chatContext.clearMessage();
  // chatContext.streamMessage(chatHistory);
  // biContext.sendSuggestionClick({
  //   suggestionType: 'suggested_command',
  //   suggestionName:
  //     typeof params.value === 'string'
  //       ? t(params.label)
  //       : `${t(params.label)}_${params.value.label}`,
  // });
  // biContext.sendUserMessage({
  //   isSuggestedMessage: true,
  //   suggestionType: 'suggested_commands',
  //   userText: userMessage,
  //   suggestedText: userMessage,
  //   origin: 'cta inside panel',
  //   success: true,
  // });
  // };

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const hendleSeeMoreClick = () => {
  //   onSeeOrLessMoreClick();

  // biContext.sendAdditionalActions(
  //   'message_list_area -> suggested_commands -> see_more_click',
  // );
  // };

  // const hendleSeeLessClick = () => {
  //   onSeeOrLessMoreClick();

  // biContext.sendAdditionalActions(
  //   'message_list_area -> suggested_commands -> see_less_click',
  // );
  // };

  // const hendleDropdownOpen = (_label: string) => {
  //  biContext.sendAdditionalActions(`change ${label} command - dropdown open`);
  // };

  return (
    <div className={styles.massage__wrapper}>
      <div
        className={cx(styles.massage, { [styles.massage_user]: userMessage })}
      >
        <div className={styles.massage__content}>
          {!userMessage && (
            <div className={styles.massage__iconWrapper}>
              <Symbol name="aiTextCreatorV2AiIcon" />
            </div>
          )}
          <Text
            size="small"
            skin="standard"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {typeof content === 'string'
              ? content.replace(/. Use this tone of voice: .*/, '')
              : content}
          </Text>
        </div>

        {withControls && <MessageControls messageText={content} />}
      </div>
      {/* {withSuggestions && (
        <div className={styles.massage__suggestion}>
          <Suggestion
            onSelect={handleSuggestionSelect}
            length={4}
            controls={SUGGESTIONS}
            onSeeMoreClick={hendleSeeMoreClick}
            onSeeLessClick={hendleSeeLessClick}
            onDropdownOpen={hendleDropdownOpen}
          />
        </div>
      )} */}
    </div>
  );
};

export default Message;
