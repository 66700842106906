// @ts-nocheck
import _ from 'lodash';
import tooltipManager from './tooltipManager';
import React from 'react';
import TooltipPresenter from './tooltipPresenter';
import * as UA from '@/uiAnimations';

export default class extends React.Component {
  static displayName = 'tooltipsRenderer';

  state = {
    presentedTooltips: [],
  };

  UNSAFE_componentWillMount() {
    tooltipManager.setTooltipRenderer(this);
  }

  updateDisplayedTooltips = (presentersArr, callback) => {
    this.setState(
      {
        presentedTooltips: presentersArr,
      },
      callback,
    );
  };

  render() {
    return (
      <div key="tooltip-renderer-wrapper" className="tooltips-layer">
        <UA.tooltipAnimation>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.state.presentedTooltips, (tooltipData) => (
            <TooltipPresenter key={tooltipData.id} {...tooltipData} />
          ))}
        </UA.tooltipAnimation>
      </div>
    );
  }
}
