// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as util from '@/util';
import radioButtonsMixin from '../controls/radioButtonsMixin';
import React from 'react';

const INTERVAL_TIME = 200;
const DEFAULT_SLIDER_WIDTH = 400;
const DEFAULT_SELECTED_ITEM_OFFSET = 50;
const DEFAULT_STEP_WIDTH = 96;

function setPosition(newPosition) {
  if (this.state.position === newPosition) {
    return;
  }

  const positionDiff = newPosition - this.state.position;
  const newDistanceToScroll = this.state.distanceToScroll - positionDiff;
  this.setState({
    position: newPosition,
    distanceToScroll: newDistanceToScroll,
    isLeftEdge: newPosition === 0,
    isRightEdge: newDistanceToScroll === 0,
  });
}

function singleStepRight() {
  const distanceToMove = Math.min(
    this.state.distanceToScroll,
    this.props.stepWidth,
  );
  const newPosition = this.state.position + distanceToMove;
  setPosition.call(this, newPosition);
}

function singleStepLeft() {
  const distanceToMove = Math.min(this.state.position, this.props.stepWidth);
  const newPosition = this.state.position - distanceToMove;
  setPosition.call(this, newPosition);
}

function moveToRight() {
  if (this.state.distanceToScroll === 0) {
    this.endRight();
    return;
  }

  singleStepRight.call(this);
}

function moveToLeft() {
  if (this.state.position === 0) {
    this.endLeft();
    return;
  }

  singleStepLeft.call(this);
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'thumbnailSlider',
  propTypes: {
    items: PropTypes.array.isRequired,
    width: PropTypes.number,
    stepWidth: PropTypes.number,
    selectedItemOffset: PropTypes.number,
  },
  mixins: [radioButtonsMixin],
  getDefaultProps() {
    return {
      width: DEFAULT_SLIDER_WIDTH,
      stepWidth: DEFAULT_STEP_WIDTH,
      selectedItemOffset: DEFAULT_SELECTED_ITEM_OFFSET,
    };
  },
  getInitialState() {
    return {}; //{position: 0, distanceToScroll: 0 , isRightEdge: true, isLeftEdge: true};
  },
  componentDidMount() {
    this.focusOnSelectedSkin();
  },
  componentWillUnmount() {
    window.clearInterval(this.moveInterval);
  },
  moveInterval: undefined,
  focusOnSelectedSkin() {
    const visibleContainer = ReactDOM.findDOMNode(this);

    util.uiUtils.waitForImagesToLoad(visibleContainer).then(() => {
      const maxDistanceToScroll =
        visibleContainer.scrollWidth - visibleContainer.clientWidth;

      const selectedSkinElement = $(visibleContainer)
        .find('input:checked')
        .parent();
      const selectedSkinPosition = selectedSkinElement.position();
      const selectedSkinLeftOffset = selectedSkinPosition
        ? selectedSkinPosition.left
        : 0;

      const distanceToScrollToSelected = Math.max(
        selectedSkinLeftOffset - this.props.selectedItemOffset,
        0,
      );

      const position = Math.min(
        maxDistanceToScroll,
        distanceToScrollToSelected,
      );
      const distanceToScroll = maxDistanceToScroll - position;

      const itemsContainer = $(visibleContainer).find('.itemsContainer');
      //eslint-disable-next-line @wix/santa/no-css-manipulation
      itemsContainer.css({
        'margin-top': -itemsContainer.height() / 2,
        visibility: 'visible',
      });

      this.setState({
        position,
        distanceToScroll,
        isRightEdge: distanceToScroll === 0,
        isLeftEdge: position === 0,
      });
    });
  },
  getPosition() {
    return this.state.position;
  },
  startRight() {
    moveToRight.apply(this);
    this.moveInterval = window.setInterval(
      moveToRight.bind(this),
      INTERVAL_TIME,
    );
  },
  endRight() {
    window.clearInterval(this.moveInterval);
    this.props.afterMoveRight();
  },
  startLeft() {
    moveToLeft.apply(this);
    this.moveInterval = window.setInterval(
      moveToLeft.bind(this),
      INTERVAL_TIME,
    );
  },
  endLeft() {
    window.clearInterval(this.moveInterval);
    this.props.afterMoveLeft();
  },
  render() {
    return (
      <div
        style={{
          width: `${this.props.width}px`,
        }}
        className="control-thumbnailsSlider"
      >
        <div
          style={{
            left: `-${this.state.position}px`,
          }}
          className="itemsContainer"
        >
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.items, (item) => (
            <label data-aid={item.value} key={item.value}>
              <input
                type="radio"
                name={this.getRadioGroupId()}
                checked={
                  item.value === util.valueLink.getValueFromProps(this.props)
                }
                onChange={() => {
                  this.handleChange(item.value);
                }}
              />
              <img src={util.media.getMediaUrl(item.iconSrc)} />
            </label>
          ))}
        </div>
        {!this.state.isLeftEdge ? (
          <div
            key="left"
            onMouseDown={this.startLeft}
            onMouseUp={this.endLeft}
            className="left arrow"
          >
            <span />
          </div>
        ) : null}
        {!this.state.isRightEdge ? (
          <div
            key="right"
            onMouseDown={this.startRight}
            onMouseUp={this.endRight}
            className="right arrow"
          >
            <span />
          </div>
        ) : null}
      </div>
    );
  },
});
