import _ from 'lodash';
import React from 'react';
import { RichText, EditableUrl, Preloader } from '@wix/wix-base-ui';

import { translate } from '@/i18n';
import { cx } from '@/util';

import { connectDomainInput } from './DomainInput.mapper';

import type { DomainInputProps, DomainInputState } from './DomainInput.types';

export const DEBOUNCE_DELAY = 500;

export class DomainInputComponent extends React.Component<
  DomainInputProps,
  DomainInputState
> {
  static defaultProps = {
    onDomainClick: (): undefined => undefined,
    onInputClick: (): undefined => undefined,
    onEditClick: _.noop,
    onCancelClick: _.noop,
    onConfirmClick: _.noop,
    onChange: _.noop,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      valueCandidate: props.value,
      isValid: true,
      isValidating: false,
      invalidMessage: '',
      helpArticleId: '',
    };
  }

  componentDidUpdate(prevProps: Readonly<DomainInputProps>) {
    if (prevProps.isEditable && !this.props.isEditable) {
      this.cancelScheduledValidation();
      this.resetValidationStatus();
    }
  }

  componentWillUnmount() {
    this.cancelScheduledValidation();
  }

  validate = _.debounce(async (value: string) => {
    const { validateSiteName, onValidityChange, onValidationResponseReceived } =
      this.props;

    const validationStartTime = Date.now();

    this.setState({ isValidating: true });

    validateSiteName(value, (result) => {
      this.setState({ isValidating: false });

      if (this.state.valueCandidate !== value) {
        return;
      }

      const nextState = {
        isValid: result.success,
        invalidMessage: result.errorMessage,
        helpArticleId: result.helpArticleId,
      };

      this.setState(nextState);

      onValidationResponseReceived({
        loadingTime: Date.now() - validationStartTime,
        isSuccess: nextState.isValid,
        errorName: nextState.invalidMessage,
        siteDisplayName: this.state.valueCandidate,
      });

      onValidityChange(result.success);
    });
  }, DEBOUNCE_DELAY);

  handleValueCandidateChange = async (value: string) => {
    this.setState({
      valueCandidate: value,
      invalidMessage: '',
      helpArticleId: '',
      isValid: false,
    });

    if (value) {
      this.validate(value);
    }

    this.props.onValidityChange(false);
  };

  cancelScheduledValidation = () => {
    this.validate.cancel();
  };

  handleChange = (nextValue: AnyFixMe) => {
    const { withEditButtons, onChange, value, sanitizeSiteName } = this.props;
    const { isValid } = this.state;

    const valueToSet = sanitizeSiteName(
      withEditButtons || isValid || nextValue === value ? nextValue : value,
    );

    onChange(valueToSet);

    this.setState({
      valueCandidate: valueToSet,
    });

    if (valueToSet === value) {
      this.resetValidationStatus();
    }
  };

  handleBlur = () => {
    const { valueCandidate } = this.state;

    this.cancelScheduledValidation();

    if (!valueCandidate) {
      this.validate(valueCandidate);
    }
  };

  resetValidationStatus() {
    this.setState({
      isValid: true,
      invalidMessage: '',
      helpArticleId: '',
    });
  }

  handleConfirm = () => {
    this.props.onConfirmClick(this.state.valueCandidate);
  };

  getInvalidMessage = () => {
    const { invalidMessage, helpArticleId } = this.state;

    if (helpArticleId) {
      return (
        <RichText type="T02">
          <p>{invalidMessage}</p>
          <a
            href="#"
            data-aid="site-name-validation-error-learn-more"
            onClick={this.createHandleLearnMoreClickHandler(helpArticleId)}
          >
            {translate('SAVE_SITE_NAME_ERROR_LINK')}
          </a>
        </RichText>
      );
    }

    return invalidMessage;
  };

  createHandleLearnMoreClickHandler =
    (helpArticleId: string) => (event: React.MouseEvent) => {
      event.preventDefault();

      const { openHelpCenter, closeAllPanels } = this.props;

      closeAllPanels(); // https://jira.wixpress.com/browse/SE-5425
      openHelpCenter(helpArticleId);
    };

  render() {
    const {
      prefix,
      isEditable,
      withEditButtons,
      value,
      onDomainClick,
      onInputClick,
      onEditClick,
      onCancelClick,
    } = this.props;

    const { isValid, isValidating, helpArticleId } = this.state;

    return (
      <div
        className={cx('domain-input-container', {
          'with-edit-buttons': withEditButtons,
        })}
      >
        <EditableUrl
          key={String(isEditable)}
          shouldTranslate={false}
          closeTooltipOnClickOutside={false}
          domain={prefix}
          value={value}
          isValid={isValid}
          onBlur={this.handleBlur}
          isReadOnly={!isEditable}
          onChange={this.handleChange}
          onValueCandidateChange={this.handleValueCandidateChange}
          invalidMessage={this.getInvalidMessage()}
          tooltipInteractive={Boolean(helpArticleId)}
          autoSelect={!withEditButtons}
          {...(withEditButtons && {
            editButtons: true,
            editButtonText: translate('SAVE_SITE_SAVED_BUTTON_EDIT'),
          })}
          onDomainClick={onDomainClick}
          onInputClick={onInputClick}
          onEditClick={onEditClick}
          onCancel={onCancelClick}
          onConfirm={this.handleConfirm}
        />
        {isValidating && (
          <div className="domain-input-loader">
            <Preloader className="tiny" />
          </div>
        )}
      </div>
    );
  }
}

export const DomainInput = connectDomainInput(DomainInputComponent);
