import React from 'react';
import type { IMenuItemSuffix } from '@/baseUI';
import { translate } from '@/i18n';
import { Tooltip, TextLabel, RichText } from '@wix/wix-base-ui';

import { symbol as Symbol } from '@wix/santa-editor-symbols';

interface IMenuItemSuffixProps extends IMenuItemSuffix {}

export const MenuItemSuffix: React.FC<IMenuItemSuffixProps> = ({
  tooltipText,
  helpText,
  openHelpCenter,
  symbolName,
}) => {
  const tooltipContent = tooltipText ? (
    <div>
      <TextLabel value={tooltipText} enableEllipsis={false} />
      {helpText && (
        <RichText>
          <a onClick={openHelpCenter}>{translate(helpText)}</a>
        </RichText>
      )}
    </div>
  ) : null;

  return (
    <Tooltip
      disabled={!tooltipContent}
      className="sortable-list-item__suffix"
      content={tooltipContent}
      interactive
    >
      {symbolName && <Symbol name={symbolName} />}
    </Tooltip>
  );
};
