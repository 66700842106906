// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import radioButtonsMixin from './radioButtonsMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'thumbnails-vertical',
  mixins: [radioButtonsMixin],
  propTypes: {
    options: PropTypes.array.isRequired,
    valueLink: PropTypes.object,
    imageMargin: PropTypes.string,
    align: PropTypes.string,
  },
  getDefaultProps() {
    return {
      align: 'center',
      imageMargin: '5px',
    };
  },
  isCurrentValue(value) {
    const currentValue = util.valueLink.getValueFromProps(this.props);
    return _.isEqual(value, currentValue);
  },
  render() {
    return (
      <div className="thumbnails-vertical">
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option, optionIndex) => (
          <label key={`option_${optionIndex}`}>
            <input
              type="radio"
              name={this.getRadioGroupId()}
              value={option.value}
              checked={this.isCurrentValue(option.value)}
              onChange={() => {
                this.handleChange(option.value);
              }}
            />
            <div className={`thumbnail-container ${this.props.align}`}>
              {option.imgSrc ? (
                <img
                  key="imgsrc"
                  src={option.imgSrc}
                  width={option.width || ''}
                  height={option.height || ''}
                  className={cx({ withoutLabel: !option.title })}
                />
              ) : null}
              {option.symbolName ? (
                <symbols.symbol
                  key="thumbnailContainerSymbol"
                  name={option.symbolName}
                />
              ) : null}
              {option.title ? (
                <span key="title" className="thumbnail-title">
                  {this.translateIfNeeded(option.title)}
                </span>
              ) : null}
            </div>
          </label>
        ))}
      </div>
    );
  },
});
