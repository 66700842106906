// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dropDownMixin from './dropDownMixin';
import dropdownManager from './dropdownManager';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

const TAG_TRAINGLE_OFFSET = 10;

function getBottomPosition(viewPortSize, top, listEl) {
  return viewPortSize.height - top - listEl.scrollHeight;
}

function isFitRight(viewPortSize, left, width) {
  return viewPortSize.width >= left + width;
}

function isFitBottom(bottom) {
  return bottom >= dropdownManager.INDENT_FROM_EDGE;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'contextMenu',
  mixins: [dropDownMixin],
  className: 'context-menu',
  propTypes: {
    icon: PropTypes.string,
    notificationCount: PropTypes.number,
    onNotificationClick: PropTypes.func,
    shrink: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      toggleIcon: false,
      template() {
        return (
          <div className={cx('symbol-more', { shrink: this.props.shrink })}>
            <symbols.symbol name={this.props.icon || 'more'} />
            {_.isNumber(this.props.notificationCount) &&
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/is-nan
            !_.isNaN(this.props.notificationCount) &&
            _.isFunction(this.props.onNotificationClick) ? (
              <span
                key="notification"
                onClick={this.onNotificationClick}
                className="notification"
              >
                {this.props.notificationCount}
              </span>
            ) : null}
          </div>
        );
      },
      setSelectedAnyway: true,
    };
  },

  getOptionsLocation(ddEl, listEl) {
    const ddBoundingClientRect = ddEl.getBoundingClientRect();
    const viewPortSize = dropdownManager.getViewportSize();
    const width =
      this.props.optionsWidth || listEl.getBoundingClientRect().width + 1;
    let left = ddBoundingClientRect.left + ddBoundingClientRect.width;
    let top = ddBoundingClientRect.top - TAG_TRAINGLE_OFFSET;
    let bottom = getBottomPosition(viewPortSize, top, listEl);
    const optionalClasses = ['tag-triangle'];

    if (isFitRight(viewPortSize, left, width)) {
      optionalClasses.push('tag-left');
    } else {
      left = ddBoundingClientRect.left - width;
      optionalClasses.push('tag-right');
    }

    if (isFitBottom(bottom)) {
      optionalClasses.push('tag-side-top');
    } else {
      top =
        ddBoundingClientRect.bottom - listEl.scrollHeight + TAG_TRAINGLE_OFFSET;
      bottom = getBottomPosition(viewPortSize, top, listEl);
      optionalClasses.push('tag-side-bottom');
    }

    return {
      style: {
        top,
        bottom,
        left,
        width,
      },

      optionalClasses,
    };
  },
  onNotificationClick(e) {
    this.props.onNotificationClick(e);
    e.stopPropagation();
  },
});
