// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputMixin from './inputMixin';
import registerDocumentEventsMixin from './registerDocumentEventsMixin';
import * as util from '@/util';
import * as utils from '@wix/santa-editor-utils';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import { Composites, Slider, TextLabel } from '@wix/wix-base-ui';

const { normalizeNumber } = utils.math;

/**
 * @property {number} min
 * @property {number} max
 * @property {number} step
 * @property {string} units
 * @property {string} defaultValue
 */

// eslint-disable-next-line react/prefer-es6-class
const slider = createReactClass({
  displayName: 'wixBaseUISlider',
  mixins: [
    inputMixin,
    registerDocumentEventsMixin,
    util.translationMixin,
    reportUIChangeMixin,
  ],
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  propTypes: {
    min: PropTypes.number,
    max: PropTypes.number,
    stepperMin: PropTypes.number,
    stepperMax: PropTypes.number,
    steppeStep: PropTypes.number,
    stepperFactor: PropTypes.number,
    selectedUnits: PropTypes.string,
    onUnitsChange: PropTypes.func,
    units: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    automationId: PropTypes.string,
    step: PropTypes.number,
    defaultValue: PropTypes.number,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    onSlideEnd: PropTypes.func,
    isSmallStepper: PropTypes.bool,
    afterBlur: PropTypes.func,
    middleValueForPolynomial: PropTypes.number,
  },

  getDefaultProps() {
    return {
      max: 100,
      min: 0,
      step: 1,
      units: '',
      isSmallStepper: false,
      stepperFactor: 1,
    };
  },

  getSliderValue(props) {
    const value = util.valueLink.getValueFromProps(props);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(value)) {
      return parseFloat(value);
    }
    return value;
  },
  getInitialState() {
    this.sliderX = 0;
    this.sliderWidth = 0;

    const value = this.getSliderValue(this.props);
    const defaultValue =
      this.props.defaultValue !== undefined
        ? Number(this.props.defaultValue)
        : this.props.min;
    const finalizedValue = _.isNumber(value) ? value : defaultValue;

    const normalizeValue = normalizeNumber(
      finalizedValue,
      this.props.min,
      this.props.max,
      this.props.step,
    );

    return { value: normalizeValue * this.props.stepperFactor };
  },

  getIsSpx() {
    return /^(-?[\d.]+)spx$/.test(this.props.valueLink?.value);
  },

  getUnit() {
    return this.getIsSpx() ? 'spx' : this.props.units;
  },

  addSpxIfNeeded(value: number) {
    const isSpx = this.getIsSpx();
    if (isSpx) {
      return `${value}spx`;
    }

    return value;
  },

  handleChange(newVal, isStepperChange) {
    if (this.state.value !== newVal) {
      this.setState({ value: newVal });
      util.valueLink.callOnChangeIfExists(
        this.props,
        this.getValueFromStepper(newVal),
        { isStepperChange },
      );
    }
  },

  handleStepperChange(newVal) {
    newVal = normalizeNumber(
      newVal,
      this.getStepperMin(),
      this.getStepperMax(),
      this.getStepperStep(),
    );
    this.handleChange(newVal, true);
    this.reportUIChange({
      value: this.getValueFromStepper(newVal),
      value_change_origin: 'Input',
    });
  },

  getStepperMax(optionalProps) {
    let stepperMax;

    if (optionalProps) {
      stepperMax = optionalProps.stepperMax || optionalProps.max;
    }

    if (!stepperMax) {
      stepperMax = this.props.stepperMax || this.props.max;
    }

    return stepperMax;
  },

  getStepperMin(optionalProps) {
    let stepperMin;

    if (optionalProps) {
      stepperMin = optionalProps.stepperMin;
      if (isNaN(stepperMin)) {
        stepperMin = optionalProps.min;
      }
    }

    if (isNaN(stepperMin)) {
      stepperMin = this.props.stepperMin;
      if (isNaN(stepperMin)) {
        stepperMin = this.props.min;
      }
    }

    return stepperMin;
  },

  getStepperStep(optionalProps) {
    let stepperStep;

    if (optionalProps) {
      stepperStep = optionalProps.stepperStep;
      if (isNaN(stepperStep)) {
        stepperStep = optionalProps.step;
      }
    }

    if (isNaN(stepperStep)) {
      stepperStep = this.props.stepperStep;
      if (isNaN(stepperStep)) {
        stepperStep = this.props.step;
      }
    }

    return stepperStep;
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const valueFromProps = this.getSliderValue(nextProps);
    if (valueFromProps !== this.getValueFromStepper(this.state.value)) {
      const newVal = normalizeNumber(
        valueFromProps * nextProps.stepperFactor,
        this.getStepperMin(nextProps),
        this.getStepperMax(nextProps),
        this.getStepperStep(nextProps),
      );
      this.setState({ value: newVal });
    }
  },

  getValueFromStepper(value: string | number) {
    const numericValue = parseFloat(value);
    const normalizedValue = numericValue / this.props.stepperFactor;
    const spxMaybeValue = this.addSpxIfNeeded(normalizedValue);

    return spxMaybeValue;
  },

  render() {
    return (
      <Composites.SliderLabeled>
        <TextLabel
          value={this.getLabel()}
          shouldTranslate={this.props.shouldTranslate}
          disabled={this.props.disabled}
        />
        <Slider
          min={this.getStepperMin()}
          max={this.getStepperMax()}
          value={this.state.value}
          step={this.getStepperStep()}
          onChange={this.handleStepperChange}
          unit={this.getUnit()}
          disabled={this.props.disabled}
        />
      </Composites.SliderLabeled>
    );
  },
});

export default slider;
