import React from 'react';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  Divider,
} from '@wix/wix-base-ui';
import { cx } from '@/util';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import type { IAction, IDivider } from '../types';

interface IProps {
  actions: IAction[];
  isContextMenuOpen: boolean;
  onContextMenuToggle(isOpen: boolean): void;
}

function isDivider(action: IAction): action is IDivider {
  return action.type === 'divider';
}

export const MenuItemContextMenu = ({
  actions,
  onContextMenuToggle,
  isContextMenuOpen,
}: IProps) => (
  <ContextMenu
    className={cx('flat', 'sortable-list-item__context-menu')}
    isOpen={isContextMenuOpen}
    onToggle={onContextMenuToggle}
    automationId="sortable-list-item-context-menu"
    optionsContainerClassName="sortable-list-item__context-menu-options"
  >
    <ContextMenuContent key={actions.length}>
      {actions.map((action, key) => {
        if (isDivider(action)) {
          return <Divider key={key} />;
        }

        return (
          <ContextMenuAction
            key={action.title}
            onClick={action.onClick}
            disabled={action.disabled}
            automationId={action.automationId}
            className="sortable-list-item__context-menu-action"
          >
            <Symbol
              className="sortable-list-item__context-menu-action-icon"
              name={action.symbolName}
            />
            <span className="sortable-list-item__context-menu-action-text">
              {action.title}
            </span>
          </ContextMenuAction>
        );
      })}
    </ContextMenuContent>
  </ContextMenu>
);
