import React from 'react';
import PropTypes from 'prop-types';

import { cx } from '@/util';

import Toggle from './toggle';
import Tooltip from '../popovers/tooltip';
import constants from '@/constants';

interface Props {
  name?: string;
  className?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
  tooltipValue?: unknown;
  automationId?: string;
  secondaryColors?: boolean;
  smallSize?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'toggleButton';

  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltipValue: PropTypes.any,
    automationId: PropTypes.string,
    secondaryColors: PropTypes.bool,
    smallSize: PropTypes.bool,
  };

  render() {
    return (
      <Tooltip
        value={this.props.tooltipValue}
        disabled={!this.props.tooltipValue}
        styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
      >
        <span className={this.props.className}>
          <Toggle
            name={this.props.name}
            value={this.props.value}
            instanceId={this.props.name}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            className={cx({
              'toggle-button': true,
              checked: this.props.value,
              secondary: this.props.secondaryColors,
              small: this.props.smallSize,
            })}
            automationId={this.props.automationId}
          />
        </span>
      </Tooltip>
    );
  }
}
