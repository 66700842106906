import * as stateManagement from '@/stateManagement';
import { events as BIEvents } from '@/coreBi';
import { SiteGlobalDataApiKey } from '@/apis';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch } from 'types/redux';
import type { BusinessType, OwnProps, DispatchProps } from './types';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { origin }: OwnProps,
) => ({
  businessTypeClick: (businessType: BusinessType) => {
    dispatch(
      stateManagement.bi.actions.event(BIEvents.changeBusinessType.BTClick, {
        origin,
        business_type: JSON.stringify(businessType),
      }),
    );

    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'panels.focusPanels.changeBusinessTypePanel',
        { origin },
        false,
      ),
    );
  },
});

export const mapStateToProps = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);

  return siteGlobalDataAPI.getBusinessType();
};

export const mergeProps = (
  { industryId, structureId, displayName }: BusinessType,
  { businessTypeClick }: DispatchProps,
  { isSubtitleView }: { isSubtitleView: boolean },
) => {
  return {
    onBusinessTypeClick: () =>
      businessTypeClick({
        industryId,
        structureId,
      }),
    businessTypeName: displayName,
    isSubtitleView,
  };
};
