import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { utils as themeUtils } from '@/theme';
import constants from '@/constants';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import { HorizontalTabs } from '@wix/wix-base-ui';
import Stepper from '../panelInputs/stepper/stepper';
import TextInput from '../panelInputs/textInput';

interface ColorObject {
  hue: number;
  saturation: number;
  brightness: number;
}

interface ColorFormatProps {
  value: ColorObject;
  onChangeInValidationStatus: (isValid: boolean) => void;
  onChange: (newColor: ColorObject) => void;
}

interface ColorFormatState {
  selectedFormat: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ColorFormatProps, ColorFormatState>({
  displayName: 'colorFormat',
  propTypes: {
    value: PropTypes.object.isRequired,
    onChangeInValidationStatus: PropTypes.func,
  },
  mixins: [PureRenderMixin],
  getInitialState() {
    const hsb = util.valueLink.getValueFromProps(this.props);
    const rgb = themeUtils.hsbToRgb(hsb);
    return {
      selectedFormat: constants.UI.COLOR_FORMATS.HEX,
      currentExactHSB: hsb,
      currentRGB: rgb,
      currentHEX: themeUtils.rgbToHex(rgb),
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.value, this.props.value) &&
      !_.isEqual(nextProps.value, this.state.currentExactHSB)
    ) {
      const rgb = themeUtils.hsbToRgb(nextProps.value);
      this.setState({
        currentExactHSB: nextProps.value,
        currentRGB: rgb,
        currentHEX: themeUtils.rgbToHex(rgb),
      });
    }
  },
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedFormat !== this.state.selectedFormat &&
      this.props.onChangeInValidationStatus
    ) {
      this.props.onChangeInValidationStatus(true);
    }
  },
  getFormatOptions() {
    return [
      {
        label: 'ColorPicker_ColorSpace_HEX',
        value: constants.UI.COLOR_FORMATS.HEX,
      },
      {
        label: 'ColorPicker_ColorSpace_RGB',
        value: constants.UI.COLOR_FORMATS.RGB,
      },
      {
        label: 'ColorPicker_ColorSpace_HSB',
        value: constants.UI.COLOR_FORMATS.HSB,
      },
    ];
  },
  getColorAsExactHSB() {
    return _.clone(this.state.currentExactHSB);
  },
  getColorAsHEX() {
    const colorAsHEX = this.state.currentHEX;
    return colorAsHEX.substr(1);
  },
  getColorAsRGB() {
    return _.clone(this.state.currentRGB);
  },
  handleRedChange(redVal: AnyFixMe) {
    const colorAsRGB = this.getColorAsRGB();
    colorAsRGB.red = redVal;
    const colorAsHSB = themeUtils.rgbToHsbExact(colorAsRGB);
    this.setState(
      {
        currentExactHSB: colorAsHSB,
        currentRGB: colorAsRGB,
        currentHEX: themeUtils.rgbToHex(colorAsRGB),
      },
      () =>
        util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
    );
  },
  handleGreenChange(greenVal: AnyFixMe) {
    const colorAsRGB = this.getColorAsRGB();
    colorAsRGB.green = greenVal;
    const colorAsHSB = themeUtils.rgbToHsbExact(colorAsRGB);
    this.setState(
      {
        currentExactHSB: colorAsHSB,
        currentRGB: colorAsRGB,
        currentHEX: themeUtils.rgbToHex(colorAsRGB),
      },
      () =>
        util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
    );
  },
  handleBlueChange(blueVal: AnyFixMe) {
    const colorAsRGB = this.getColorAsRGB();
    colorAsRGB.blue = blueVal;
    const colorAsHSB = themeUtils.rgbToHsbExact(colorAsRGB);
    this.setState(
      {
        currentExactHSB: colorAsHSB,
        currentRGB: colorAsRGB,
        currentHEX: themeUtils.rgbToHex(colorAsRGB),
      },
      () =>
        util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
    );
  },
  handleHueChange(hueVal: AnyFixMe) {
    const colorAsHSB = this.getColorAsExactHSB();
    if (Math.round(colorAsHSB.hue) !== hueVal) {
      colorAsHSB.hue = util.math.ensureWithinLimits(hueVal, 0, 360);
      const colorAsRGB = themeUtils.hsbToRgb(colorAsHSB);
      this.setState(
        {
          currentExactHSB: colorAsHSB,
          currentRGB: colorAsRGB,
          currentHEX: themeUtils.rgbToHex(colorAsRGB),
        },
        () =>
          util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
      );
    }
  },
  handleSaturationChange(saturationVal: AnyFixMe) {
    const colorAsHSB = this.getColorAsExactHSB();
    if (Math.round(colorAsHSB.saturation) !== saturationVal) {
      colorAsHSB.saturation = saturationVal;
      const colorAsRGB = themeUtils.hsbToRgb(colorAsHSB);
      this.setState(
        {
          currentExactHSB: colorAsHSB,
          currentRGB: colorAsRGB,
          currentHEX: themeUtils.rgbToHex(colorAsRGB),
        },
        () =>
          util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
      );
    }
  },
  handleBrightnessChange(brightnessVal: AnyFixMe) {
    const colorAsHSB = this.getColorAsExactHSB();
    if (Math.round(colorAsHSB.brightness) !== brightnessVal) {
      colorAsHSB.brightness = brightnessVal;
      const colorAsRGB = themeUtils.hsbToRgb(colorAsHSB);
      this.setState(
        {
          currentExactHSB: colorAsHSB,
          currentRGB: colorAsRGB,
          currentHEX: themeUtils.rgbToHex(colorAsRGB),
        },
        () =>
          util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
      );
    }
  },
  handleHexChange(hexVal: AnyFixMe) {
    hexVal = hexVal.startsWith('#') ? hexVal : `#${hexVal}`;
    const colorAsRGB = themeUtils.hexToRgb(hexVal);
    const colorAsHSB = themeUtils.rgbToHsbExact(colorAsRGB);
    this.setState(
      {
        currentExactHSB: colorAsHSB,
        currentRGB: colorAsRGB,
        currentHEX: hexVal,
      },
      () =>
        util.valueLink.callOnChangeIfExists(this.props, _.clone(colorAsHSB)),
    );
  },
  validateHexValue(hexVal: AnyFixMe) {
    return /^[0-9A-F]{6}$/i.test(hexVal);
  },
  handleTabChange(tab: AnyFixMe) {
    this.setState({ selectedFormat: tab });
  },
  inputWithStateHandler(value: string, prevValue: string) {
    if (value.charAt(0) === '#') {
      return value.substr(1);
    }
    if (value.length > 6) {
      return prevValue;
    }
    return value;
  },
  render() {
    const FORMATS = constants.UI.COLOR_FORMATS;

    return (
      <div className="color-format">
        <HorizontalTabs
          options={this.getFormatOptions()}
          value={this.state.selectedFormat}
          onChange={this.handleTabChange}
          arrowed
        />

        <div className="color-values-wrapper">
          {this.state.selectedFormat === FORMATS.HEX
            ? (() => {
                const colorAsHEX = this.getColorAsHEX();

                return (
                  <div key="hexSection" className="color-value-hex">
                    <TextInput
                      validator={this.validateHexValue}
                      invalidMessage="ColorPicker_ColorSpace_HEXErrorTooltip"
                      value={colorAsHEX}
                      maxLength={7}
                      onChange={this.handleHexChange}
                      inputWithStateHandler={this.inputWithStateHandler}
                      onChangeInValidationStatus={
                        this.props.onChangeInValidationStatus
                      }
                    />
                  </div>
                );
              })()
            : null}
          {this.state.selectedFormat === FORMATS.RGB
            ? (() => {
                const colorAsRGB = this.getColorAsRGB();

                return (
                  <div key="rgbSection" className="color-values">
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={255}
                        value={colorAsRGB.red}
                        step={1}
                        onChange={this.handleRedChange}
                      />
                    </div>
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={255}
                        value={colorAsRGB.green}
                        step={1}
                        onChange={this.handleGreenChange}
                      />
                    </div>
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={255}
                        value={colorAsRGB.blue}
                        step={1}
                        onChange={this.handleBlueChange}
                      />
                    </div>
                  </div>
                );
              })()
            : null}
          {this.state.selectedFormat === FORMATS.HSB
            ? (() => {
                const colorAsExactHSB = this.getColorAsExactHSB();

                return (
                  <div key="hsbSection" className="color-values">
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={360}
                        units="°"
                        value={Math.round(colorAsExactHSB.hue)}
                        step={1}
                        onChange={this.handleHueChange}
                      />
                    </div>
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={100}
                        units="%"
                        value={Math.round(colorAsExactHSB.saturation)}
                        step={1}
                        onChange={this.handleSaturationChange}
                      />
                    </div>
                    <div className="color-value-item">
                      <Stepper
                        min={0}
                        max={100}
                        units="%"
                        value={Math.round(colorAsExactHSB.brightness)}
                        step={1}
                        onChange={this.handleBrightnessChange}
                      />
                    </div>
                  </div>
                );
              })()
            : null}
        </div>
      </div>
    );
  },
});
