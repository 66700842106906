import inputMixin from './panelInputs/inputMixin';
import registerDocumentEventsMixin from './panelInputs/registerDocumentEventsMixin';
import classNameMixin from './mixins/classNameMixin';
import reportUIChangeMixin from './mixins/reportUIChangeMixin';
import radioButtonsMixin from './controls/radioButtonsMixin';
import commonData from './commonData/commonData';
import sortByDragList from './sortByDragList/sortByDragList';
import * as symbols from '@wix/santa-editor-symbols';
import autoCompleteTextInput from './panelInputs/autoCompleteTextInput';
import textInput from './panelInputs/textInput';
import { DomainInput } from './panelInputs/domainInput/DomainInput';
import proportionsSlider from './panelInputs/proportionsSlider';
import radioGroup from './panelInputs/radioGroup';
import radioList from './panelInputs/radioList';
import buttonGroup from './panelInputs/buttonGroup';
import changeMediaInput from './panelInputs/changeMediaInput';
import thumbnailSlider from './panelInputs/thumbnailSlider';
import labelWithCircleButton from './panelInputs/labelWithCircleButton';
import wixStaticMediaMixin from './panelInputs/media/wixStaticMediaMixin';
import popover from './popovers/tooltip';
import tooltip from './popovers/tooltip';
import tooltipManager from './popovers/tooltipManager';
import tooltipsRenderer from './popovers/tooltipsRenderer';
import floatingBubble from './popovers/floatingBubble';
import bubble from './popovers/bubble';
import * as popoverTemplates from './popovers/templates/popoverTemplates';
import richTextParagraph from './panelInputs/richTextParagraph';
import slider from './panelInputs/slider';
import wixBaseUISlider from './panelInputs/wixBaseUISlider';
import stepper from './panelInputs/stepper/stepper';
import stepperWithSVG from './panelInputs/stepper/stepperWithSVG';
import prefixedTextInput from './panelInputs/prefixedTextInput';
import button from './controls/button';
import button2 from './controls/button2';
import fileInput from './controls/fileInput';
import filesDrop from './controls/filesDrop';
import sectionDividerLabeledC06 from './controls/sectionDividerLabeledC06';
import select from './panelInputs/dropDown/select';
import contextMenu from './panelInputs/dropDown/contextMenu';
import combobox from './panelInputs/dropDown/combobox';
import options from './panelInputs/dropDown/options';
import option from './panelInputs/dropDown/option';
import optgroup from './panelInputs/dropDown/optgroup';
import footer from './panelInputs/dropDown/footer';
import dropdownManager from './panelInputs/dropDown/dropdownManager';
import iconsMenu from './panelInputs/dropDown/iconsMenu';
import selectiveTextInputWithMandatoryButton from './panelInputs/selectiveTextInputWithMandatoryButton';
import textInputWithButton from './panelInputs/textInputWithButton';
import textInputWithFixedButton from './panelInputs/textInputWithFixedButton';
import checkboxWithButton from './panelInputs/checkboxWithButton';
import actionWithSymbol from './panelInputs/actionWithSymbol';
import tabHolder from './tabs/tabHolder';
import tabHeader from './tabs/tabHeader';
import tabContent from './tabs/tabContent';
import tabLabel from './tabs/tabLabel';
import tabShowMoreLabel from './tabs/tabShowMoreLabel';
import tab from './tabs/tab';
import angle from './controls/angle';
import treeDocker from './treeView/treeDocker';
import treeView from './treeView/treeView';
import datePicker from './controls/datePicker';
import thumbnails from './controls/thumbnails';
import thumbnailsWithIcons from './controls/thumbnailsWithIcons';
import thumbnailsVertical from './controls/thumbnailsVertical';
import mandatory from './controls/mandatory';
import buttonsGroup from './controls/buttonsGroup';
import horizontalTabs from './controls/horizontalTabs';
import linkToggle from './controls/linkToggle';
import infoIcon from './controls/infoIcon';
import verticalTabs from './controls/verticalTabs';
import cornerRadiusInput from './controls/cornerRadiusInput';
import alignment from './controls/alignment';
import textAlignment from './controls/textAlignment';
import colorScheme from './panelInputs/colorScheme';
import playButton from './controls/playButton';
import thumbnailPreviewPlay from './controls/thumbnailPreviewPlay';
import imagePreview from './panelInputs/media/imagePreview';
import organizeImagesInput from './panelInputs/media/organizeImagesInput';
import videoInput from './panelInputs/media/videoInput';
import videoPreview from './panelInputs/media/videoPreview';
import videoSearch from './panelInputs/media/videoSearch';
import videoSelector from './panelInputs/media/videoSelector';
import videoTypeSelector from './panelInputs/media/videoTypeSelector';
import videoUpload from './panelInputs/media/videoUpload';
import { VideoPlayButton } from './panelInputs/media/videoPlayButton';
import imageChange from './panelInputs/media/imageChange';
import imageChangeRemove from './panelInputs/media/imageChangeRemove';
import imageChangeAdjust from './panelInputs/media/imageChangeAdjust';
import logoChange from './panelInputs/media/logoChange';
import listItemWithImage from './controls/listItemWithImage';
import listItemWithSymbol from './controls/listItemWithSymbol';
import arrowButton from './controls/arrowButton';
import colorSpace from './colorPicker/colorSpace';
import colorFormat from './colorPicker/colorFormat';
import { paletteDisplayer } from './colorPicker/paletteDisplayer';
import palettesList from './colorPicker/palettesList';
import colorPickerInput from './colorPicker/colorPickerInput';
import colorPickerInputWithOpacity from './colorPicker/colorPickerInputWithOpacity';
import colorPickerInputWithOpacitySlider from './colorPicker/colorPickerInputWithOpacitySlider';
import iframe from './controls/iframe';
import preloader from './controls/preloader';
import video from './controls/video';
import toggle from './controls/toggle';
import toggleButton from './controls/toggleButton';
import dockControl from './controls/dock';
import accordion from './panelInputs/accordion';
import splitter from './controls/splitter/splitter';
import image from './controls/image';
import imageWithMask from './controls/imageWithMask';
import radioWithIcon from './controls/radioWithIcon';
import checkableTextInput from './panelInputs/checkableTextInput';
import labeledStepper from './panelInputs/labeledStepper';
import generateUICompBySchema from './utils/generateUICompBySchema';
import generateUICompByPropertySchema from './utils/generateUICompByPropertySchema';
import generateSeparatorBetweenComponents from './utils/generateSeparatorBetweenComponents';
import * as propertySchemaValidationUtils from './utils/propertySchemaValidationUtils';
import {
  MenuList,
  MenuItem,
  DropMode,
  tree as menuItemTree,
} from './MenuList/index';
import Search from './panelInputs/Search';
import SectionHeader, {
  type SectionHeaderProps as SectionHeaderInterface,
} from './controls/sectionHeader/sectionHeader';
import { PaginatedThumbnails } from './controls/PaginatedThumbnails/PaginatedThumbnails';
import {
  OptionalCollapseLabel,
  type OptionalCollapseLabelGetterProps,
  type OptionalCollapseLabelSetterProps,
} from './controls/OptionalCollapseLabel';
import { SymbolWrapper } from './symbolWrapper/symbolWrapper';
import { InfoIconWithSpy } from './infoIconWithSpy/infoIconWithSpy';
import BusinessTypePanelLink from './businessTypePanelLink/businessTypePanelLink';
import BusinessTypeInput from './businessTypeInput/businessTypeInput';
import AiTextCreatorEntryPoint from './aiTextCreatorEntryPoint';
import AiImageCreatorEntryPoint from './aiImageCreatorEntryPoint';
import {
  WixBaseUISliderWithBI,
  type WixBaseUISliderWithBIProps,
} from './panelInputs/wixBaseUISliderWithBI';
import { MenuIcon } from './menuIcon/menuIcon';
import AiTermsAndConditions from './aiTermsAndConditions';
import AiInfoBlock from './aiInfoBlock/aiInfoBlock';
import BusinessInfo from './businessInfo/businessInfo';
import Suggestion from './suggestion';
import { FacesRating } from './FacesRating/FacesRating';
import * as LiveChat from './liveChat';
import { GfppBackgroundIcon } from './gfppBackgroundIcon/gfppBackgroundIcon';

const dropDown = {
  select,
  contextMenu,
  combobox,
  options,
  option,
  optgroup,
  footer,
  dropDownManager: dropdownManager,
  iconsMenu,
};

const { symbol } = symbols;

const tabs = {
  holder: tabHolder,
  header: tabHeader,
  content: tabContent,
  label: tabLabel,
  showMoreLabel: tabShowMoreLabel,
  tab,
};

const media = {
  wixStaticMediaMixin,
};
const utils = {
  generateUICompBySchema,
  generateUICompByPropertySchema,
  generateSeparatorBetweenComponents,
  propertySchemaValidationUtils,
};

export type { WixBaseUISliderWithBIProps };

export {
  inputMixin,
  registerDocumentEventsMixin,
  classNameMixin,
  radioButtonsMixin,
  commonData, // This is not a control : used to shared data
  sortByDragList,
  symbol,
  SymbolWrapper,
  autoCompleteTextInput,
  textInput,
  DomainInput,
  changeMediaInput,
  thumbnailSlider,
  labelWithCircleButton,
  popover,
  tooltip,
  tooltipManager,
  tooltipsRenderer,
  floatingBubble,
  bubble,
  popoverTemplates,
  richTextParagraph,
  InfoIconWithSpy,
  imageChange,
  imageChangeRemove,
  imageChangeAdjust,
  logoChange,
  slider,
  wixBaseUISlider,
  WixBaseUISliderWithBI,
  stepper,
  stepperWithSVG,
  prefixedTextInput,
  button,
  button2,
  fileInput,
  filesDrop,
  sectionDividerLabeledC06 as sectionDividerLabeled,
  dropDown,
  selectiveTextInputWithMandatoryButton,
  textInputWithButton,
  textInputWithFixedButton,
  textAlignment,
  colorScheme,
  checkboxWithButton,
  actionWithSymbol,
  radioGroup,
  radioList,
  buttonGroup,
  tabs,
  angle,
  treeDocker,
  treeView,
  toggle,
  toggleButton,
  datePicker,
  thumbnails,
  thumbnailsWithIcons,
  thumbnailsVertical,
  mandatory,
  buttonsGroup,
  horizontalTabs,
  linkToggle,
  infoIcon,
  verticalTabs,
  cornerRadiusInput,
  alignment,
  playButton,
  thumbnailPreviewPlay,
  imagePreview,
  organizeImagesInput,
  videoInput,
  videoPreview,
  videoSearch,
  videoSelector,
  videoTypeSelector,
  videoUpload,
  VideoPlayButton,
  listItemWithImage,
  listItemWithSymbol,
  arrowButton,
  colorSpace,
  colorFormat,
  paletteDisplayer,
  palettesList,
  colorPickerInput,
  colorPickerInputWithOpacity,
  colorPickerInputWithOpacitySlider,
  iframe,
  preloader,
  video,
  dockControl as dock,
  accordion,
  reportUIChangeMixin,
  media,
  splitter,
  image,
  imageWithMask,
  radioWithIcon,
  proportionsSlider,
  checkableTextInput,
  labeledStepper,
  utils,
  MenuList,
  MenuItem,
  DropMode,
  menuItemTree,
  Search,
  SectionHeader,
  PaginatedThumbnails,
  OptionalCollapseLabel,
  BusinessTypePanelLink,
  BusinessTypeInput,
  AiTextCreatorEntryPoint,
  AiImageCreatorEntryPoint,
  AiTermsAndConditions,
  MenuIcon,
  AiInfoBlock,
  BusinessInfo,
  Suggestion,
  FacesRating,
  LiveChat,
  GfppBackgroundIcon,
};

export type SectionHeaderProps = SectionHeaderInterface;

export * from './MenuList/types';
export * from './controls/PaginatedThumbnails/types';
export * from './liveChat/types';

export type {
  OptionalCollapseLabelGetterProps,
  OptionalCollapseLabelSetterProps,
};
export type OptionalCollapseLabelProps = OptionalCollapseLabelGetterProps &
  OptionalCollapseLabelSetterProps;
