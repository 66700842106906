import * as stateManagement from '@/stateManagement';

import type {
  MapStateToProps,
  MapDispatchToPropsFunction,
  Dispatch,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type {
  TermsDispatchProps,
  TermsOwnProps,
  TermsStateProps,
} from './aiTermsAndConditionsTypes';

const { setIsAiTermsAndConditionsShown } = stateManagement.ai.actions;
const { getIsAiTermsAndConditionsShown } = stateManagement.ai.selectors;

export const mapStateToProps: MapStateToProps<
  TermsStateProps,
  TermsOwnProps
> = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const state = editorAPI.store.getState();
  const hasBeenShown = getIsAiTermsAndConditionsShown(state);

  return {
    hasBeenShown,
  };
};

export const mapDispatchToProps: MapDispatchToPropsFunction<
  TermsDispatchProps,
  TermsOwnProps
> = (dispatch: Dispatch) => {
  return {
    setBeenShown() {
      dispatch(setIsAiTermsAndConditionsShown(true));
    },
  };
};
