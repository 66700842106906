// @ts-nocheck
import _ from 'lodash';
import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { translate } from '@/i18n';
import classNameMixin from '../../mixins/classNameMixin';
import cacheMixin from './cacheMixin';
import dropdownManager from './dropdownManager';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'option',

  mixins: [classNameMixin, cacheMixin],

  propTypes: {
    value: PropTypes.any,
    index: PropTypes.number,
  },

  getDefaultProps() {
    return {
      type: 'option',
    };
  },

  getInitialState() {
    return {
      selected: dropdownManager.getSelectedIndex() === this.props.index,
      hovered: this.props.hovered,
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newVal = nextProps.value;
    const nextState = {};

    if (this.props.value !== newVal) {
      nextState.value = newVal;
    }

    nextState.selected =
      dropdownManager.getSelectedIndex() === this.props.index;
    this.setState(nextState);
  },

  select() {
    dropdownManager.select(this);
  },

  getData() {
    const { props } = this;

    return {
      value: props.value,
      index: props.index,
      content: this.getCached('content'),
      searchText: props.searchText,
    };
  },

  hover() {
    ReactDOM.findDOMNode(this).classList.add('hovered');
  },

  unhover() {
    ReactDOM.findDOMNode(this).classList.remove('hovered');
  },

  onMouseEnter() {
    dropdownManager.setHovered(this);
  },

  render() {
    let className = 'option dropdown-option';
    let content = this.props.children || '';

    if (this.state.selected) {
      className += ' selected';
    } else if (this.state.hovered || this.props.hovered) {
      className += ' hovered';
    }
    if (this.props.disabled) {
      className += ' disabled';
    }

    if (content && typeof content === 'string') {
      content = content.trim();

      if (this.props.shouldTranslate) {
        content = translate(content);
      }
    }

    this.updateCache('content', content);

    dropdownManager.addOption(this);

    return React.createElement(
      'li',
      {
        onClick: this.props.disabled ? _.noop : this.select,
        className: this.generateClassName(className),
        style: this.props.style,
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.unhover,
      },
      content,
    );
  },
});
