// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import { Composites, RichText } from '@wix/wix-base-ui';

const VIDEO_SEARCH_PANEL_NAME = 'videoSearch.panels.videoSearchPanel';
const { VIDEO_PLAYER_TYPES } = constants;
const VIDEO_TYPE = constants.UI.VIDEO.TYPE;

const playerToVideoTypeMap = {
  [VIDEO_PLAYER_TYPES.DAILYMOTION]: VIDEO_TYPE.DAILYMOTION,
  [VIDEO_PLAYER_TYPES.VIMEO]: VIDEO_TYPE.VIMEO,
  [VIDEO_PLAYER_TYPES.YOUTUBE]: VIDEO_TYPE.YOUTUBE,
};

const videoToPlayerTypeMap = {
  [VIDEO_TYPE.DAILYMOTION]: VIDEO_PLAYER_TYPES.DAILYMOTION,
  [VIDEO_TYPE.VIMEO]: VIDEO_PLAYER_TYPES.VIMEO,
  [VIDEO_TYPE.YOUTUBE]: VIDEO_PLAYER_TYPES.YOUTUBE,
};

//TYPE WAS GENERATED, remove this line when reviewed
interface VideoSearchProps {
  openPanel: FunctionFixMe;
  closePanel: FunctionFixMe;
  textLabel?: string;
  buttonLabel: string;
  value: AnyFixMe;
}

class VideoSearch extends React.Component<VideoSearchProps> {
  static displayName = 'videoSearch';

  static propTypes = {
    openPanel: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired,
    textLabel: PropTypes.string,
    buttonLabel: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    value: PropTypes.oneOf(_.values(VIDEO_PLAYER_TYPES)).isRequired,
  };

  onVideoSelected = (newVideoId, newVideoType) => {
    const videoUrl = util.videoPlayerUtils.getUrlFromId(
      videoToPlayerTypeMap[newVideoType],
      newVideoId,
    );
    this.props.closePanel(VIDEO_SEARCH_PANEL_NAME);
    this.props.onChange(videoUrl);
  };

  openVideoSearchPanel = () => {
    this.props.openPanel(
      VIDEO_SEARCH_PANEL_NAME,
      {
        videoType: playerToVideoTypeMap[this.props.value],
        onVideoSelected: this.onVideoSelected.bind(this),
      },
      true,
    );
  };

  onClick = () => {
    this.props.onClick();
    this.openVideoSearchPanel();
  };

  render() {
    return (
      <Composites.RichText>
        <RichText className="light">
          <p>
            {translate(this.props.textLabel)}
            <a onClick={this.onClick}>{translate(this.props.buttonLabel)}</a>
          </p>
        </RichText>
      </Composites.RichText>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  closePanel(panelName) {
    dispatch(stateManagement.panels.actions.closePanelByName(panelName));
  },
});

const WrappedPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(VideoSearch);

export default WrappedPanel;
