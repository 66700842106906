// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as videoSelectorEditorAPIMapper from './videoSelectorEditorAPIMapper';
import React from 'react';
import { Button, Tooltip } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import { VideoPlayButton } from './videoPlayButton';

const { fittingTypes } = util.imageTransform;

const { PLAYBACK_STATES, VIDEO_READY_STATES, NORMALIZED_PLAYING_STATES } =
  constants;
const playButtonTranslations = {
  [VIDEO_READY_STATES.PLAYING_PREVIEW]:
    'MEDIA_PLAYER_SETTINGS_PLAYBACK_PREVIEW_READY_TOOLTIP',
  [VIDEO_READY_STATES.IN_PROCESS]:
    'MEDIA_PLAYER_SETTINGS_PLAYBACK_NOT_READY_TOOLTIP',
};

const TOP_PANEL_HEIGHT = 162;
const TOP_PANEL_WIDTH = 288;

//TYPE WAS GENERATED, remove this line when reviewed
interface VideoSelectorProps {
  videoCategory?: string;
  value?: AnyFixMe;
  onChange?: FunctionFixMe;
  onPreviewVideo?: FunctionFixMe;
  onPauseVideo?: FunctionFixMe;
  mediaManagerMediaPath?: string;
  enablePlay?: boolean;
  useMediaServices?: boolean;
  enableChangeVideo?: boolean;
  disabledPlayTooltip?: string;
}

class VideoSelector extends React.Component<VideoSelectorProps> {
  static displayName = 'videoSelector';

  static propTypes = {
    videoCategory: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onPreviewVideo: PropTypes.func,
    onPauseVideo: PropTypes.func,
    mediaManagerMediaPath: PropTypes.string,
    enablePlay: PropTypes.bool,
    useMediaServices: PropTypes.bool,
    enableChangeVideo: PropTypes.bool,
    disabledPlayTooltip: PropTypes.string,
  };

  static defaultProps = {
    onPreviewVideo() {},
    onPauseVideo() {},
    onChange() {},
    enablePlay: true,
    enableChangeVideo: true,
  };

  state = {
    readyState: VIDEO_READY_STATES.NO_VIDEO,
    playbackState: 'waiting',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.enablePlay &&
      this.getNormalizePlayState(this.state.playbackState) ===
        PLAYBACK_STATES.PLAYING
    ) {
      this.stopVideo();
      this.setState({ playbackState: PLAYBACK_STATES.PAUSED });
    }
  }

  componentDidMount() {
    this.props.registerMedia(this.handlePlayerStateChange);
  }

  componentWillUnmount() {
    this.stopVideo();
    this.props.unregisterMedia();
  }

  getNormalizePlayState = (playbackState) => {
    const isPlaying = NORMALIZED_PLAYING_STATES.includes(playbackState);
    return isPlaying ? PLAYBACK_STATES.PLAYING : playbackState;
  };

  handlePlayerStateChange = (state) => {
    const playbackState = this.getNormalizePlayState(state.playbackState);
    if (
      playbackState !== this.state.playbackState ||
      state.mediaReadyState !== this.state.mediaReadyState
    ) {
      if (state.playbackState === PLAYBACK_STATES.ENDED) {
        this.stopVideo();
      }

      this.setState({
        playbackState,
        readyState: state.mediaReadyState,
      });
    }
  };

  getPreferredPosterData = () => {
    const { value } = this.props;

    if (!value) {
      return;
    }

    const currentPoster = value.posterImageRef;
    if (
      value.generatedPosters &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(value.generatedPosters, currentPoster.uri)
    ) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      const { width, height } = _.last(value.qualities);
      return {
        uri: _.head(value.generatedPosters),
        width,
        height,
      };
    }
    return currentPoster;
  };

  getPosterStyle = () => {
    const posterData = this.getPreferredPosterData();

    if (!posterData) {
      return {};
    }

    const { width, height, uri } = posterData;
    const previewDisplayData = util.imageTransform.getFromImageData(
      { width, height, uri },
      TOP_PANEL_WIDTH,
      TOP_PANEL_HEIGHT,
      fittingTypes.SCALE_TO_FIT,
      'center',
      'bg',
    );
    const style = {
      backgroundImage: `url(${previewDisplayData.uri})`,
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(style, previewDisplayData.css.container);
    return style;
  };

  getPlayBtnTranslation = () => {
    if (!this.props.enablePlay) {
      return this.props.disabledPlayTooltip;
    }
    return _.get(playButtonTranslations, this.state.readyState, '');
  };

  isVideoEnded = () => {
    return this.state.playbackState === PLAYBACK_STATES.ENDED;
  };

  previewVideo = () => {
    this.props.executeBehavior('playPreview');
    this.props.onPreviewVideo();
  };

  stopVideo = () => {
    this.props.executeBehavior('stopPreview');
    this.props.onPauseVideo();
  };

  isVideoPlaying = () => {
    return (
      this.getNormalizePlayState(this.state.playbackState) ===
      PLAYBACK_STATES.PLAYING
    );
  };

  isPlayDisabled = () => {
    return !this.props.enablePlay || this.isVideoInProcess();
  };

  isVideoInProcess = () => {
    return this.state.readyState === VIDEO_READY_STATES.IN_PROCESS;
  };

  changeMedia = () => {
    this.stopVideo();
    this.props.changeVideo();
  };

  render() {
    return (
      <div className="bg-panel-top video-selector">
        <div className="bg-panel-preview">
          <div
            ref="previewMedia"
            style={this.getPosterStyle()}
            className="bg-panel-preview-media"
          />
          <div className="bg-panel-preview-buttons">
            <Tooltip
              shouldTranslate={true}
              closeOnMouseClick={true}
              disabled={!this.getPlayBtnTranslation()}
              content={this.getPlayBtnTranslation()}
            >
              <VideoPlayButton
                key={`play_${this.isVideoEnded()}`}
                onPlay={this.previewVideo}
                onStop={this.stopVideo}
                isInProcess={this.isVideoInProcess()}
                disabled={this.isPlayDisabled()}
              />
            </Tooltip>
          </div>
        </div>

        {this.props.enableChangeVideo ? (
          <div key="panelTopActions" className="bg-panel-top-actions">
            <div className="bg-panel-action">
              <Button
                onClick={() => {
                  this.changeMedia();
                }}
                className="btn-confirm-secondary"
              >
                <Symbols.symbol name="image-change" />
                <span className="change-media-button-text">
                  {translate('MEDIA_PLAYER_SETTINGS_MEDIA_BUTTON_LABEL')}
                </span>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const WrappedPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  videoSelectorEditorAPIMapper.mapDispatchToProps,
)(VideoSelector);

export default WrappedPanel;
