import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { compIcon } from '@/util';

interface SymbolProps {
  name: string;
  className?: string;
  onClick?: React.MouseEventHandler;
  style?: React.CSSProperties;
  customDimensions?: React.SVGAttributes<SVGElement>;
  hintId?: string;
}

interface SymbolWrapperProps extends React.SVGAttributes<SVGElement> {
  name: string;
  size?: string | number;
}

export const SymbolWrapper: React.FC<SymbolProps | SymbolWrapperProps> = ({
  name,
  ...props
}) => {
  const WixUiIcon = compIcon.getWBUIcon(name);
  return WixUiIcon ? (
    <WixUiIcon {...props} />
  ) : (
    <Symbol name={name} {...props} />
  );
};
