// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as util from '@/util';
import React from 'react';
import { cx } from '@/util';
import { utils as themeUtils } from '@/theme';

const rootColorsKeys = [
  'color_11',
  'color_18',
  'color_22',
  'color_28',
  'color_34',
];

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'paletteList',
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    palettes: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueLink: PropTypes.shape({
      value: PropTypes.object,
      requestChange: PropTypes.func.isRequired,
    }).isRequired,
    onPreview: PropTypes.func.isRequired,
  },
  componentDidMount() {
    if (this.refs.selectedPalette) {
      const $paletteColorsWrapper = $(ReactDOM.findDOMNode(this));
      const $selectedPaletteNode = $(
        ReactDOM.findDOMNode(this.refs.selectedPalette),
      );
      const selectedTop = $selectedPaletteNode.position().top;
      const wrapperHeight = $paletteColorsWrapper.height();

      $paletteColorsWrapper.scrollTop(selectedTop - 0.5 * wrapperHeight);
    }
  },
  arePalettesEqual(palette1, palette2) {
    return _.isEmpty(
      _.difference(this.getRootColors(palette1), this.getRootColors(palette2)),
    );
  },
  getPaletteOptionProps(palette) {
    const selectedPalette = util.valueLink.getValueFromProps(this.props);
    const isSelected = this.arePalettesEqual(selectedPalette, palette);

    const props = {
      className: `palette-option${isSelected ? ' selected' : ''}`,
    };
    if (isSelected) {
      props.ref = 'selectedPalette';
    }

    return props;
  },
  getPaletteOptionColorClasses(color) {
    return {
      'palette-option-color': true,
      'white-color': themeUtils.getDistanceToWhite(color) < 3,
    };
  },
  getRootColors(paletteColors) {
    return _(paletteColors).pick(rootColorsKeys).values().value();
  },
  previewPalette(palette) {
    this.props.onPreview(palette);
  },
  selectPalette(palette, paletteIndex) {
    util.valueLink.callOnChangeIfExists(this.props, palette, { paletteIndex });
  },
  resetPalette() {
    this.props.onPreview(util.valueLink.getValueFromProps(this.props));
  },
  render() {
    return (
      <div
        {...this.filteredProps()}
        className={cx('palettes-list', this.filteredProps()?.className)}
      >
        <div onMouseLeave={this.resetPalette} className="palette-options">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.palettes, (palette, paletteIndex) => (
            <div
              key={`paletteOption-${paletteIndex}`}
              onMouseEnter={this.previewPalette.bind(null, palette)}
              onMouseDown={this.selectPalette.bind(null, palette, paletteIndex)}
              className="palette-option-wrapper"
            >
              <div {...this.getPaletteOptionProps(palette)}>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getRootColors(palette), (color, colorIndex) => (
                  <span
                    key={`paletteRootColor-${colorIndex}`}
                    style={{
                      backgroundColor: color,
                    }}
                    className={cx(this.getPaletteOptionColorClasses(color))}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  },
});
