import type { Channel } from '@wix/duplexer-js';
import { MessageRoles } from './index';

export interface ChatProviderOwnProps {}

export interface ChatProviderStateProps {
  metaSiteInstance: string;
  msid: string;
  toneOfVoice: string;
}

export interface ChatProviderTypes
  extends ChatProviderOwnProps,
    ChatProviderStateProps {}

export interface SystemMessage {
  content: string | JSX.Element;
  type: null | MessageRoles.ASSISTANT | MessageRoles.LEGAL;
}

export interface ChatContextTypes {
  clearMessage: () => void;
  systemMessage: SystemMessage;
  setSystemMessage: (message: SystemMessage) => void;
  streamMessage: (messages: ChatCompletionRequestMessage[]) => void;
  chatChannel: Channel<any>;
  isConnected: boolean;
  isStreamingNow: boolean;
  showStreamingPlaceholder: boolean;
  setShowStreamingPlaceholder: (message: boolean) => void;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  messages: ChatCompletionRequestMessage[];
  setMessages: React.Dispatch<
    React.SetStateAction<ChatCompletionRequestMessage[]>
  >;
  isImproveTextMode: boolean;
  setIsImproveTextMode: React.Dispatch<React.SetStateAction<boolean>>;
  toneOfVoice: string;
  checkLegal: (query: LegalChecklQuery) => Promise<LegalCheckResponse>;
  usage: { promptTokens: number; completionTokens: number };
}

export interface Chunk {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: Array<{
    index: number;
    delta: { content: string };
    finish_reason: string | null;
  }>;
}

export interface Payload {
  data: Chunk[];
  timestamp: number;
  usage: { promptTokens: number };
}

export enum ChatCompletionRequestMessageRoleEnum {
  USER = 'user',
  ASSISTANT = 'assistant',
  LEGAL = 'legal',
  USER_INVALID = 'user_invalid_message',
  AUTO_MESSAGE = 'auto-message',
}

export interface ChatCompletionRequestMessage {
  role: MessageRoles;
  content?: string;
}

export interface StreamCompletion {
  msid: string;
  messages: ChatCompletionRequestMessage[];
  metaSiteInstance: string;
}
export interface Usage {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface LegalCheckResponse {
  choices: [
    {
      text: 'PROHIBITED' | 'VALID';
      index: 0;
    },
  ];
  usage: Usage;
  currentPromptsVersion: string;
  currentGptParamsVersion: string;
}

export interface LegalChecklQuery {
  promptParams: Record<string, string>;
  useChatCompletion: boolean;
  messages: { role: string; content: string }[];
  promptsVersion: string;
  gptParamsVersion: string;
  settings: {
    ignoreOffensiveContentModeration: string[];
  };
}
