// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '@/util';
import inputMixin from './inputMixin';
import React from 'react';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'ButtonGroup',
  mixins: [inputMixin],
  handleClick(idx) {
    const option = this.props.options[idx];
    util.valueLink.callOnChangeIfExists(this.props, option.value);
  },
  render() {
    const selectedValue = util.valueLink.getValueFromProps(this.props);

    return (
      <span {...this.topLevelProps}>
        <span className="label">{this.getLabel()}</span>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option, optionIndex) => (
          <div
            onClick={() => {
              this.handleClick(optionIndex);
            }}
          >
            <span className="option">
              {option.label} {selectedValue === option.value ? 'selected' : ''}
            </span>
          </div>
        ))}
      </span>
    );
  },
});
