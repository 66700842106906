// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';

export default {
  propTypes: {
    mediaManager: PropTypes.shape({
      open: PropTypes.func.isRequired,
      categories: PropTypes.object.isRequired,
    }).isRequired,
    focusEditorFunc: PropTypes.func.isRequired,
    mediaManagerCategory: PropTypes.string,
    mediaManagerPath: PropTypes.string,
    mediaManagerHeaderLabel: PropTypes.string,
    mediaManagerButtonLabel: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },

  _changeImage(doneCB, payload, info) {
    if (!payload) {
      return;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    this.dataKeys = _.keys(util.valueLink.getValueFromProps(this.props));
    this.dataKeys = _.isEmpty(this.dataKeys)
      ? // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/keys
        _.keys(_.head(payload))
      : this.dataKeys;

    const newImageData = _.pick(payload[0], this.dataKeys);
    newImageData.uri = payload[0].fileName;
    newImageData.originalImageDataRef = null;
    newImageData.type = 'Image';
    newImageData.crop = null;
    newImageData.hasAnimation = !!payload[0].fileInput?.animated;

    doneCB(newImageData, info);
  },
  openMediaGallery(doneCB) {
    const { mediaManager } = this.props;
    const category =
      this.props.mediaManagerCategory || mediaManager.categories.IMAGE;
    let mediaManagerKeys;

    if (
      this.props.mediaManagerHeaderLabel ||
      this.props.mediaManagerButtonLabel
    ) {
      mediaManagerKeys = {};
      mediaManagerKeys.title = translate(this.props.mediaManagerHeaderLabel);
      mediaManagerKeys.submitButton = translate(
        this.props.mediaManagerButtonLabel,
      );
    }
    mediaManager.open(category, {
      multiSelect: false,
      callback: (items, info) => {
        this._changeImage(doneCB, items, info);
        this.props.focusEditorFunc();
      },
      path: this.props.mediaManagerPath,
      translation: mediaManagerKeys,
    });
  },
};
