// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import constants from '@/constants';
import React from 'react';
import UIButton from '../../controls/button';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

const VIDEO_SEARCH_PANEL_NAME = 'videoSearch.panels.videoSearchPanel';
const VIDEO_TYPE_TO_BASE_URL = constants.UI.VIDEO.URL;
const VIDEO_TYPE_TO_URL_QUERY_PARAMS = constants.UI.VIDEO.QUERY_PARAMS;

const VALUE_PROP_SHAPE = PropTypes.shape({
  videoType: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
});

function getVideoUrlQueryString(videoType) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const params = _.map(
    VIDEO_TYPE_TO_URL_QUERY_PARAMS[videoType],
    function (val, key) {
      return `${key}=${val}`;
    },
  ).join('&');
  return !_.isEmpty(params) ? `?${params}` : '';
}

function getVideoUrl(videoData) {
  const videoUrl =
    VIDEO_TYPE_TO_BASE_URL[videoData.videoType] + videoData.videoId;
  const queryString = getVideoUrlQueryString(videoData.videoType);
  return videoUrl + queryString;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'videoInput',
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    panelManager: PropTypes.object,
    nonEmptyButtonLabel: PropTypes.string.isRequired,
    emptyButtonLabel: PropTypes.string.isRequired,
    onSearchClick: PropTypes.func,
    value: VALUE_PROP_SHAPE,
    valueLink: PropTypes.shape({
      value: VALUE_PROP_SHAPE,
      requestChange: PropTypes.func,
    }),
    canChangeVideo: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onSearchClick: _.noop,
      canChangeVideo: true,
    };
  },

  getInitialState() {
    return {
      isPlaying: false,
      isMouseHovering: false,
    };
  },

  getButton() {
    return {
      nonEmptyButtonLabel: this.props.nonEmptyButtonLabel,
      nonEmptyButtonIcon: 'magnifyingGlass',
      emptyButtonLabel: this.props.emptyButtonLabel,
      emptyButtonIcon: 'magnifyingGlass',
      onClick: this._openVideoSearchPanel,
    };
  },

  getButtonIcon(button) {
    return this.isEmpty() ? button.emptyButtonIcon : button.nonEmptyButtonIcon;
  },

  getButtonLabel(button) {
    return this.isEmpty()
      ? button.emptyButtonLabel
      : button.nonEmptyButtonLabel;
  },

  _onVideoSelected(doneCB, newVideoId, newVideoType) {
    if (this.props.canChangeVideo && this.props.panelManager) {
      this.props.panelManager.closePanelByName(VIDEO_SEARCH_PANEL_NAME);
      doneCB({
        videoType: newVideoType || 'YOUTUBE',
        videoId: newVideoId,
      });
    }
  },

  _openVideoSearchPanel(doneCB) {
    const videoData = util.valueLink.getValueFromProps(this.props);
    if (this.props.canChangeVideo && this.props.panelManager) {
      this.props.onSearchClick();

      this.props.panelManager.openPanel(
        VIDEO_SEARCH_PANEL_NAME,
        {
          videoType: videoData?.videoType,
          onVideoSelected: this._onVideoSelected.bind(this, doneCB),
        },
        true,
      );
    }
  },

  getURL() {
    const videoData = util.valueLink.getValueFromProps(this.props);
    if (videoData?.videoId) {
      let videoUrl = getVideoUrl(videoData);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      videoUrl += _.includes(videoUrl, '?') ? '&' : '?';
      return `${videoUrl}autoplay=${this.state.isPlaying ? '1' : '0'}`;
    }
    return null;
  },

  isEmpty() {
    const videoData = util.valueLink.getValueFromProps(this.props);
    return !videoData?.videoId;
  },

  playVideo() {
    this.setState({ isPlaying: true });
  },

  pauseVideo() {
    this.setState({ isPlaying: false });
  },

  onClick(button) {
    button.onClick((...args) =>
      util.valueLink.callOnChangeIfExists(this.props, ...args),
    );
  },

  onMouseEnter() {
    this.setState({ isMouseHovering: true });
  },

  onMouseLeave() {
    this.setState({ isMouseHovering: false });
  },

  getFilteredProps() {
    return _.omit(this.filteredProps(), 'emptySymbolName');
  },

  render() {
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...this.getFilteredProps()}
        className={cx(
          `${util.inheritClassName(this.props, 'video-input')}${
            this.isEmpty() ? ' empty' : ''
          }`,
          this.getFilteredProps()?.className,
        )}
      >
        {!this.isEmpty() ? (
          <div key="wrapper" className="video-wrapper">
            <iframe src={this.getURL()} />
          </div>
        ) : null}

        {!this.isEmpty() ? (
          <div key="videoControls" className="video-controls">
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className={cx({
                'blocking-layer': true,
                pause: !this.state.isPlaying,
                play: this.state.isPlaying,
              })}
            />
            <div
              onClick={() => {
                if (this.state.isPlaying) {
                  this.pauseVideo();
                } else {
                  this.playVideo();
                }
              }}
              className={cx({
                'play-button': !this.state.isPlaying,
                'pause-button':
                  this.state.isPlaying && this.state.isMouseHovering,
              })}
            >
              <span
                className={cx({
                  triangle: !this.state.isPlaying,
                  pause: this.state.isPlaying,
                })}
              />
            </div>
          </div>
        ) : null}

        {this.isEmpty() ? (
          <div
            key="placeholder"
            className={cx({ 'placeholder-wrapper': true })}
          >
            <symbols.symbol name="videoInvalid" key="symbol" />
          </div>
        ) : null}
        {!this.state.isPlaying && this.props.canChangeVideo
          ? (() => {
              const button = this.getButton();

              return (
                <span key="button" className="button-wrapper">
                  <UIButton
                    label={this.getButtonLabel(button)}
                    icon={this.getButtonIcon(button)}
                    key={this.getButtonLabel(button)}
                    onClick={() => {
                      this.onClick(button);
                    }}
                    className={`action${
                      this.getButtonIcon(button) ? ' has-icon' : ''
                    } btn-confirm-secondary`}
                  />
                </span>
              );
            })()
          : null}
      </div>
    );
  },
});
