// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { Tooltip } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  title?: string;
  desc?: string;
  symbol: string;
  expandSymbol?: string;
  expandSymbolDisabled?: string;
  callback: FunctionFixMe;
  disabled?: boolean;
  customTooltipContent?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'listItemWithSymbol';

  static propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    symbol: PropTypes.string.isRequired,
    expandSymbol: PropTypes.string,
    expandSymbolDisabled: PropTypes.string,
    callback: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    customTooltipContent: PropTypes.func,
  };

  render() {
    return (
      <div
        onClick={() => {
          this.onItemClick();
        }}
        className="listItem list-item-with-symbol"
      >
        <symbols.symbol name={this.props.symbol} className="image" />
        <div className={this.getClassesWithDisabled('content')}>
          {this.props.title ? (
            <div key="title" className="title ellipsis">
              {translate(this.props.title)}
            </div>
          ) : null}
          {this.props.desc ? (
            <div key="desc" className="desc ellipsis">
              {translate(this.props.desc)}
            </div>
          ) : null}
        </div>
        {!this.props.customTooltipContent ? (
          <Tooltip key="tooltip" {...this.getTooltipProps()}>
            <symbols.symbol
              name={this.getExpandSymbolName()}
              className={this.getClassesWithDisabled('expandSymbol')}
            />
          </Tooltip>
        ) : null}

        {this.props.customTooltipContent ? (
          <this.props.customTooltipContent key="custom-tooltip-content" />
        ) : null}
      </div>
    );
  }

  getClassesWithDisabled = (className) => {
    return className + (this.props.disabled ? ' disabled' : '');
  };

  getExpandSymbolName = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(this.props.expandSymbol)) {
      return 'arrowWithStates';
    }
    return this.props.disabled
      ? this.props.expandSymbolDisabled
      : this.props.expandSymbol;
  };

  getTooltipProps = () => {
    if (!this.props.disabled && this.props.tooltip) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        { alignment: 'RIGHT' },
        _.pick(this.props.tooltip, 'key', 'id', 'content'),
      );
    }

    if (this.props.disabled && this.props.disabledTooltip) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        { alignment: 'RIGHT' },
        _.pick(this.props.disabledTooltip, 'key', 'id', 'content'),
      );
    }

    return { disabled: true };
  };

  onItemClick = () => {
    if (this.props.callback) {
      this.props.callback();
    }
  };
}
