// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from '@/i18n';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  label?: string;
  shortcut?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'keyboardShortcutTooltip';

  static propTypes = {
    label: PropTypes.string,
    shortcut: PropTypes.string,
  };

  state = {};

  render() {
    return (
      <div className="keyboardShortcutTooltip">
        {this.props.label ? (
          <span key="label" className="label">
            {translate(this.props.label)}
          </span>
        ) : null}
        {this.props.shortcut ? (
          <span key="shortcut" className="shortcut">
            ({this.props.shortcut})
          </span>
        ) : null}
      </div>
    );
  }
}
