import * as stateManagement from '@/stateManagement';

import type {
  MapStateToProps,
  MapDispatchToPropsFunction,
  Dispatch,
} from 'types/redux';
import type {
  AiTextCreatorEntryPointOwnProps,
  AiTextCreatorEntryPointStateProps,
  AiTextCreatorEntryPointDispatchProps,
} from './aiTextCreatorEntryPointTypes';
import type { EditorState } from '@/stateManagement';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

export const mapStateToProps: MapStateToProps<
  AiTextCreatorEntryPointStateProps,
  AiTextCreatorEntryPointOwnProps
> = ({ state }: { state: EditorState }) => {
  return {
    openPanelName: stateManagement.panels.selectors.selectOpenPanels(state)[0]
      ?.name as string,
  };
};

export const mapDispatchToProps: MapDispatchToPropsFunction<
  AiTextCreatorEntryPointDispatchProps,
  AiTextCreatorEntryPointOwnProps
> = (dispatch: Dispatch) => {
  return {
    sendBI(event: BiEventDefinition, params: BiEventFields = {}) {
      dispatch(stateManagement.bi.actions.event(event, params));
    },
  };
};
