// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import radioButtonsMixin from './radioButtonsMixin';
import React from 'react';
import InfoIcon from './infoIcon';
import RadioWithIcon from './radioWithIcon';
import * as util from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'thumbnailsWithIcons',
  mixins: [radioButtonsMixin],
  propTypes: {
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
  },
  render() {
    const selectedValue = util.valueLink.getValueFromProps(this.props);

    return (
      <div className="thumbnails-with-icons">
        <div className="control-header">
          {this.props.label ? (
            <span key="label" className="thumbnails-with-icons-label">
              {this.translateIfNeeded(this.props.label)}
            </span>
          ) : null}

          {this.props.shouldDisplayInfoIcon ? (
            <InfoIcon
              key="infoIcon"
              title={this.props.infoTitle}
              text={this.props.infoText}
              alignment={this.props.infoAlignment}
              fitToBounds={this.props.infoFitToBounds}
              className={`info-${this.props.name}`}
            />
          ) : null}
        </div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option) => (
          <RadioWithIcon
            key={option.value}
            symbolName={option.symbolName}
            imageUrl={option.imageUrl}
            title={this.translateIfNeeded(option.title)}
            tooltip={option.tooltip}
            group={this.getRadioGroupId()}
            value={option.value === selectedValue}
            onChange={() => {
              this.handleChange(option.value);
            }}
          />
        ))}
      </div>
    );
  },
});
