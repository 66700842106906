import React, { useRef, type FC } from 'react';
import Message from '../message';
import { cx } from '@/util';
import {
  MessageRoles,
  type ChatCompletionRequestMessage,
  type SystemMessage,
} from '../types';

import styles from './messages.scss';
import { translate } from '@/i18n';

interface MessagesProps {
  messages: ChatCompletionRequestMessage[];
  lastSystemMessage: SystemMessage;
  showStreamingPlaceholder: boolean;
  isStreamingNow: boolean;
  onSeeOrLessMoreClick?: () => void;
}

const Messages: FC<MessagesProps> = ({
  messages,
  lastSystemMessage,
  showStreamingPlaceholder,
  isStreamingNow,
  onSeeOrLessMoreClick,
}) => {
  const containerRef = useRef(null);

  const isLastSystemMessageAssistant =
    lastSystemMessage?.type === MessageRoles.ASSISTANT;

  return (
    <div className={styles.messages} ref={containerRef}>
      {messages.map((item, index) => {
        const isAssistantMessage =
          item.role === MessageRoles.ASSISTANT ||
          item.role === MessageRoles.LEGAL ||
          item.role === MessageRoles.AUTO_MESSAGE;

        const isUserMessage =
          item.role === MessageRoles.USER ||
          item.role === MessageRoles.USER_INVALID;

        return (
          <div
            className={cx(styles.message__item, {
              [styles.message__item_assistant]: isAssistantMessage,
            })}
            key={index}
          >
            <Message
              content={item.content}
              userMessage={isUserMessage}
              withControls={
                !isStreamingNow && item.role === MessageRoles.ASSISTANT
              }
            />
          </div>
        );
      })}
      <div className={cx(styles.message__item, styles.message__item_assistant)}>
        {showStreamingPlaceholder ? (
          <Message content={translate('ai_text_creator_chat_loader')} />
        ) : (
          lastSystemMessage?.content && (
            <Message
              withControls={!isStreamingNow && isLastSystemMessageAssistant}
              content={lastSystemMessage?.content}
              withSuggestions={isLastSystemMessageAssistant}
              onSeeOrLessMoreClick={onSeeOrLessMoreClick}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Messages;
