// @ts-nocheck
import PropTypes from 'prop-types';
import inputMixin from '../panelInputs/inputMixin';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import * as util from '@/util';

const { translationMixin } = util;
let seq = 0;

export default {
  propTypes: {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ]).isRequired,
        className: PropTypes.string,
      }),
    ),
  },
  mixins: [inputMixin, reportUIChangeMixin, translationMixin],

  contextTypes: {
    reportUIChange: PropTypes.func,
  },

  getRadioGroupId() {
    if (!this.groupId) {
      this.groupId = `rb_${seq++}`;
    }
    return this.groupId;
  },

  handleChange(newSelectedValue, newSelectedType) {
    util.valueLink.callOnChangeIfExists(
      this.props,
      newSelectedValue,
      newSelectedType,
    );
    this.reportUIChange({ value: newSelectedValue });
  },
};
