// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import React from 'react';

const LAYOUTS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'SplitterPane',
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    layout: PropTypes.oneOf([LAYOUTS.VERTICAL, LAYOUTS.HORIZONTAL]).isRequired,
    size: PropTypes.string,
    minSize: PropTypes.string,
    maxSize: PropTypes.string,
    disableEvents: PropTypes.bool,
    collapsed: PropTypes.bool,
    fitWhenCollapsed: PropTypes.bool,
    automationId: PropTypes.string,
  },
  getDefaultProps() {
    return {
      layout: LAYOUTS.VERTICAL,
      fitWhenCollapsed: false,
    };
  },
  getClass() {
    const autoSizeClass = _.isEmpty(this.props.size)
      ? 'splitter-pane-auto-size'
      : null;
    return _.compact([
      'splitter-pane',
      `splitter-pane-${this.props.layout}`,
      this.props.className,
      autoSizeClass,
    ]).join(' ');
  },
  getStyle() {
    const needToFit = this.props.collapsed && this.props.fitWhenCollapsed;
    const style = {
      position: 'relative',
      display:
        this.props.collapsed && !this.props.fitWhenCollapsed ? 'none' : '',
    };
    if (this.props.layout === LAYOUTS.HORIZONTAL) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(style, {
        height: needToFit ? 'auto' : this.props.size,
        width: '100%',
        minHeight: needToFit ? '' : this.props.minSize,
        maxHeight: this.props.maxSize,
      });
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(style, {
        width: needToFit ? 'auto' : this.props.size,
        height: '100%',
        minWidth: needToFit ? '' : this.props.minSize,
        maxWidth: this.props.maxSize,
      });
    }
    if (_.isEmpty(this.props.size)) {
      style.flex = 1;
      style.WebkitFlex = 1;
    }
    if (this.props.style) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(style, this.props.style);
    }
    return _.pickBy(style);
  },
  render() {
    return (
      <div
        data-aid={this.props.automationId}
        className={this.getClass()}
        {...this.filteredProps()}
        style={{
          ...this.getStyle(),
          ...this.filteredProps()?.style,
        }}
      >
        {this.props.children}
      </div>
    );
  },
});
