// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputMixin from '../panelInputs/inputMixin';
import * as util from '@/util';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'fileInput',
  propTypes: {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onlyText: PropTypes.bool,
    fileTypes: PropTypes.string,
  },
  mixins: [inputMixin, reportUIChangeMixin, util.translationMixin],
  render() {
    return (
      <label
        disabled={!!this.props.disabled}
        className={`file-input-control ${
          this.props.onlyText ? 'text' : 'button'
        }`}
      >
        <input
          type="file"
          ref="input"
          disabled={!!this.props.disabled}
          multiple={true}
          onClick={this.onClick}
          accept={this.props.fileTypes}
          onChange={(e) => {
            this.handleChange(e);
          }}
          className={`input-${this.props.name}`}
        />

        {!this.props.onlyText ? (
          <symbols.symbol name="upload-btn" key="button-symbol" />
        ) : null}

        <span className="label">
          {this.translateIfNeeded(this.props.label)}
        </span>
      </label>
    );
  },
  handleChange(evt) {
    const fileList = evt.target.files;
    this.reportUIChange({ value: fileList });
    util.valueLink.callOnChangeIfExists(this.props, fileList);
  },
  onClick() {
    this.refs.input.value = null;
  },
});
