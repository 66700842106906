import type { ISuggestResult } from '@/presetApi';
import type { BusinessType } from '@/siteGlobalData';

export const suggestItemsToOptions = (
  suggestResult = {
    results: [],
    relatedResults: [],
  } as ISuggestResult,
) =>
  suggestResult.results
    .concat(suggestResult.relatedResults.slice(0, 5))
    .map((res) => res.displayName);

export const getBusinessTypeByString = (
  str: string,
  inputSuggestions: ISuggestResult,
): BusinessType => {
  if (!inputSuggestions) {
    return {
      displayName: str,
      industryId: undefined,
      structureId: undefined,
    };
  }

  const { relatedResults, results } = inputSuggestions;
  const suggestItem = [...results, ...relatedResults].find(
    (item) => item.displayName.toLowerCase() === str.toLowerCase(),
  );

  return {
    displayName: suggestItem?.displayName || str,
    industryId: suggestItem?.industryId,
    structureId: suggestItem?.structureId,
  };
};
