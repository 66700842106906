:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._input_y767x_7 {
  border-radius: 6px;
  background: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  position: relative; }
  ._input_y767x_7 textarea {
    height: 96px !important;
    padding-right: 52px !important; }
  ._input__button_y767x_15 {
    width: 30px;
    height: 30px;
    padding: 7.5px 0 0 8px;
    border-radius: 8px;
    background: linear-gradient(79deg, #116dff 36%, #2fffe6 119.85%);
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    bottom: 6px;
    right: 9px; }
  ._input__button__disabled_y767x_26 {
    opacity: 0.5; }
  ._input__contentEditable_y767x_28 {
    min-height: 18px;
    max-height: 72px;
    padding: 12px 50px 12px 12px;
    overflow-y: scroll;
    border-radius: 6px;
    border: 1px solid #d6e6fe;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05); }
