// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import inputMixin from './inputMixin';

// eslint-disable-next-line you-dont-need-lodash-underscore/assign
export default _.assign({}, inputMixin, {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newVal = util.valueLink.getValueFromProps(nextProps);
    if (util.valueLink.getValueFromProps(this.props) !== newVal) {
      this.setState({ value: newVal });
    }
  },
});
