// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import radioMixin from './radioButtonsMixin';
import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'HorizontalTabs',
  mixins: [radioMixin],
  getDefaultProps() {
    return {
      options: [
        { label: 'Image_Button_Settings_Regular_View_Label', value: 1 },
        { label: 'Image_Button_Settings_Hover_View_Label', value: 2 },
        { label: 'Image_Button_Settings_Clicked_View_Label', value: 3 },
      ],
    };
  },
  render() {
    return (
      <div className={this.getClassName('control-tabs-horizontal')}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option) => {
          const selectedValue = util.valueLink.getValueFromProps(this.props);

          return (
            <label
              key={option.value}
              className={`container ${option.className || ''}`}
            >
              <input
                type="radio"
                name={this.getRadioGroupId()}
                value={option.value}
                checked={option.value === selectedValue}
                onChange={() => {
                  this.handleChange(option.value);
                }}
                className="input"
              />
              <span className="tab-decorator">
                <span className="text-label">
                  {this.props.shouldTranslate
                    ? translate(option.label)
                    : option.label}
                </span>
              </span>
            </label>
          );
        })}
      </div>
    );
  },
});
