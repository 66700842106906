// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import OpacitySlider from '../panelInputs/opacitySlider';
import ColorPicker from './colorPickerInput';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    label: PropTypes.string,
    helpText: PropTypes.string,
    isSmallStepper: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },
  getDefaultProps() {
    return {
      isSmallStepper: false,
    };
  },
  mixins: [util.propTypesFilterMixin, util.translationMixin],
  displayName: 'colorPickerInputWithOpacitySlider',
  linkColor() {
    return {
      value: util.valueLink.getValueFromProps(this.props).color,
      requestChange: function (color) {
        const alpha =
          util.valueLink.getValueFromProps(this.props).alpha === 0.0
            ? 1
            : util.valueLink.getValueFromProps(this.props).alpha;
        util.valueLink.callOnChangeIfExists(this.props, { color, alpha });
      }.bind(this),
    };
  },
  linkOpacity() {
    return {
      value: util.valueLink.getValueFromProps(this.props).alpha * 100,
      requestChange: function (alpha) {
        util.valueLink.callOnChangeIfExists(this.props, {
          color: util.valueLink.getValueFromProps(this.props).color,
          alpha: alpha / 100,
        });
      }.bind(this),
    };
  },

  render() {
    return (
      <div className="color-picker-input-with-opacity">
        {this.props.label ? (
          <label
            key="label"
            className={cx({
              'color-picker-input-with-opacity-label': true,
              disabled: this.props.disabled,
            })}
          >
            {this.translateIfNeeded(this.props.label)}
          </label>
        ) : null}
        <div className="color-picker-input-with-opacity-slider">
          <OpacitySlider
            isSmallStepper={this.props.isSmallStepper}
            disabled={this.props.disabled}
            units="%"
            color={util.valueLink.getValueFromProps(this.props).color}
            colorResolver={this.props.colorResolver}
            valueLink={this.linkOpacity()}
            min={0}
            max={100}
            step={1}
          />
          <ColorPicker
            disabled={this.props.disabled}
            valueLink={this.linkColor()}
            {...this.filteredProps()}
          />
        </div>

        {this.props.helpText ? (
          <Divider long={true} key="helpTextDivider" />
        ) : null}

        {this.props.helpText ? (
          <div key="helpText" className="rich-text-paragraph">
            <p className="rich-text-paragraph-content">
              {this.translateIfNeeded(this.props.helpText)}
            </p>
          </div>
        ) : null}
      </div>
    );
  },
});
