import React, { type FC, useContext } from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { ChatContext } from '../';
import ContentEditable, {
  type ContentEditableEvent,
} from 'react-contenteditable';

import styles from './chatInput.scss';

import { convertToPlain } from './chatInputHelpers';

import type { InputProps } from './chatInputTypes';
import { cx } from '@/util';

const ChatInput: FC<InputProps> = ({ onSendMessage, disabled }) => {
  const chatContext = useContext(ChatContext);

  const sendMessage = () => {
    const plainText = convertToPlain(chatContext.inputValue);

    if (!plainText) return;

    onSendMessage(plainText);

    setTimeout(() => {
      chatContext.setInputValue('');
    }, 0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      sendMessage();
    }
  };

  const handleClick = () => {
    sendMessage();
  };

  const handleChange = (v: ContentEditableEvent) => {
    chatContext.setInputValue(v.target.value);
  };

  return (
    <div className={styles.input} onKeyDown={handleKeyDown}>
      <ContentEditable
        className={styles.input__contentEditable}
        html={chatContext.inputValue}
        onChange={handleChange}
        disabled={disabled}
        tagName="div"
        placeholder="Describe what you want in your text..."
      />
      <div
        onClick={handleClick}
        className={cx(styles.input__button, {
          [styles.input__button__disabled]: disabled,
        })}
      >
        <Symbol name="aiTextCreatorV2Plane" />
      </div>
    </div>
  );
};

export default ChatInput;
