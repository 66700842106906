// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import radioMixin from './radioButtonsMixin';
import React from 'react';
import InfoIcon from './infoIcon';
import * as util from '@/util';
import { translate } from '@/i18n';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'ButtonsGroup',
  PropTypes: {
    align: PropTypes.string,
  },
  mixins: [radioMixin],
  render() {
    return (
      <div className="control-buttons-group">
        {this.props.title ? (
          <p key="title">{translate(this.props.title)}</p>
        ) : null}
        {this.props.title && (this.props.infoText || this.props.infoTitle) ? (
          <InfoIcon
            key="infoIcon"
            text={this.props.infoText}
            title={this.props.infoTitle}
            size={18}
          />
        ) : null}
        <div
          className={`group-buttons-container${
            this.props.align ? `-${this.props.align}` : ''
          }`}
        >
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.options, (option) => {
            const selectedValue = util.valueLink.getValueFromProps(this.props);

            return (
              <label key={option.value} className={option.className}>
                <input
                  type="radio"
                  name={this.getRadioGroupId()}
                  value={option.value}
                  checked={option.value === selectedValue}
                  disabled={this.props.disabled}
                  onChange={() => {
                    this.handleChange(option.value);
                  }}
                />
                <span className={cx({ disabled: this.props.disabled })}>
                  {this.translateIfNeeded(option.label)}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    );
  },
});
