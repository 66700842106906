import React, { type FC } from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Text } from '@wix/wix-base-ui';
import { cx } from '@/util';

import styles from './aiInfoBlock.scss';

interface AiInfoBlockProps {
  title?: string;
  text: string;
  panelName?: string;
}

const AiInfoBlock: FC<AiInfoBlockProps> = ({ title, text, panelName }) => (
  <div
    className={cx(styles.wrapper, {
      [styles.wrapper_small]: panelName === 'AiWriterPanel',
    })}
  >
    <div className={styles.symbolWrapper}>
      <Symbol
        name="aiIllustration"
        className={cx(styles.symbol, {
          [styles.symbol_small]: panelName === 'AiWriterPanel',
        })}
      />
    </div>
    <div>
      <Text size="medium" weight="bold" shouldTranslate={false}>
        {title}
      </Text>
      <div className={styles.text}>
        <Text size="small" enableEllipsis={false} shouldTranslate={false}>
          {text}
        </Text>
      </div>
    </div>
  </div>
);

export default AiInfoBlock;
