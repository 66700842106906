// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import React from 'react';
import {
  ButtonContent,
  Composites,
  TextLabel,
  UploadButton,
} from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface VideoRefSelectorProps {
  videoCategory?: string;
  label?: string;
  buttonLabel?: string;
  loadingLabel?: string;
  onClick?: FunctionFixMe;
  onChange?: FunctionFixMe;
  mediaManagerMediaPath?: string;
  useMediaServices?: boolean;
}

class VideoRefSelector extends React.Component<VideoRefSelectorProps> {
  static displayName = 'videoUpload';

  static propTypes = {
    videoCategory: PropTypes.string,
    label: PropTypes.string,
    buttonLabel: PropTypes.string,
    loadingLabel: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    mediaManagerMediaPath: PropTypes.string,
    useMediaServices: PropTypes.bool,
  };

  state = {
    uploadStatus: 'NONE',
    fileNameUploaded: '',
  };

  changeMedia = () => {
    this.setState({
      uploadStatus: 'IN_PROCESS',
    });

    this.props.onClick();

    this.props.changeVideo((payload, info) => {
      const videoObj = _.head(payload);

      this.setState({
        uploadStatus: videoObj ? 'UPLOAD_SUCCESS' : 'NONE',
        fileNameUploaded: videoObj ? videoObj.title : '',
      });

      this.props.onChange(payload, info);
    });
  };

  render() {
    return (
      <Composites.UploadButtonLabeled className="video-upload">
        <TextLabel value={this.props.label} shouldTranslate={true} />
        <UploadButton
          value={this.state.fileNameUploaded}
          onClick={() => this.changeMedia()}
          buttonContent={{
            beforeUpload: React.createElement(ButtonContent, {
              value: this.props.buttonLabel,
              className: 'no-margin',
              symbol: React.createElement(Symbols.symbol, {
                name: 'image-change',
              }),
            }),
            afterUpload: React.createElement(ButtonContent, {
              value: this.props.buttonLabel,
              className: 'no-margin',
              symbol: React.createElement(Symbols.symbol, {
                name: 'image-change',
              }),
            }),
          }}
          processMessage={this.props.loadingLabel}
          status={this.state.uploadStatus}
          shouldTranslate={true}
          className="video-upload-button"
        />
      </Composites.UploadButtonLabeled>
    );
  }
}

const changeVideo =
  (
    onChange,
    useMediaServices,
    selectedComponents,
    videoCategory,
    mediaManagerMediaPath,
    origin: string,
  ) =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = _.head(
      selectedComponents || editorAPI.selection.getSelectedComponents(),
    );
    const { mediaManager } = editorAPI.mediaServices;
    videoCategory = videoCategory || mediaManager.categories.VIDEO;

    if (useMediaServices) {
      editorAPI.mediaServices.changeVideo(compRef, videoCategory, origin);
    } else {
      mediaManager.open(videoCategory, 'editor_media_player', {
        callback: onChange,
        path: mediaManagerMediaPath,
      });
    }
  };

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeVideo: (onChange) =>
    dispatch(
      changeVideo(
        onChange,
        ownProps.useMediaServices,
        ownProps.selectedComponents,
        ownProps.videoCategory,
        ownProps.mediaManagerMediaPath,
        ownProps.origin,
      ),
    ),
});

const WrappedPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(VideoRefSelector);

export default WrappedPanel;
