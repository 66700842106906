import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import toggleMixin from '../controls/toggleMixin';
import React from 'react';
import UIButton from '../controls/button';
import { Checkbox } from '@wix/wix-base-ui';
import * as util from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'CheckboxWithButton',
  mixins: [toggleMixin as AnyFixMe],
  propTypes: {
    buttonLabel: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
    isCheckboxDisabled: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    label: PropTypes.string,
  },
  render() {
    const isChecked = util.valueLink.getValueFromProps(this.props);

    return (
      <div
        className={util.inheritClassName(
          this.props,
          'control-checkbox-with-button',
        )}
      >
        <div className="controls-wrapper">
          <Checkbox
            indeterminate={this.props.indeterminate}
            value={isChecked}
            onChange={this.handleChange}
            // @ts-expect-error
            ref="checkbox"
            disabled={this.props.isCheckboxDisabled}
            label={this.props.label}
            shouldTranslate={this.props.shouldTranslate}
            labelAfterSymbol={true}
          />
          {isChecked ? (
            <UIButton
              key="ui-button"
              label={this.props.buttonLabel}
              onClick={this.props.onButtonClick}
              shouldTranslate={this.props.shouldTranslate}
              disabled={Boolean(this.props.isButtonDisabled)}
              className="checkbox-button"
            />
          ) : null}
        </div>
      </div>
    );
  },
});
