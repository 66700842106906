import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { Button } from '@wix/wix-base-ui';

interface ButtonProps {
  label?: string;
  className?: string;
  class?: string;
  disabled?: boolean;
  shouldTranslate?: boolean;
  icon?: string;
  hintId?: string;
  onClick?: (e: React.MouseEvent) => void;
  automationId?: string;
  aid?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ButtonProps>({
  displayName: 'Button',
  mixins: [util.translationMixin as AnyFixMe],
  propTypes: {
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    hintId: PropTypes.string,
    onClick: PropTypes.func,
    automationId: PropTypes.string,
  },
  render() {
    return (
      <Button
        disabled={this.props.disabled}
        automationId={this.props.automationId}
        onClick={this.props.onClick}
        className={this.props.className}
      >
        {this.props.icon ? (
          <symbols.symbol
            key={`symbol-${this.props.icon}`}
            name={this.props.icon}
            className={this.props.label ? 'symbol-with-label' : ''}
          />
        ) : null}
        <span>{this.translateIfNeeded(this.props.label)}</span>
      </Button>
    );
  },
});
