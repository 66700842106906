// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from '@/i18n';
import { Composites, RichText, TextLabel } from '@wix/wix-base-ui';

function template(props) {
  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className="list-item-with-image-tooltip-content"
    >
      <Composites.RichTextLabeled>
        <TextLabel type="T06" value={props.title} />
        <RichText>
          <p>
            {translate(props.description)}
            <span>
              <a onClick={props.onLearnMore}>
                {translate(props.learnMoreText)}
              </a>
            </span>
          </p>
        </RichText>
      </Composites.RichTextLabeled>
    </div>
  );
}

const TooltipContent = template;

TooltipContent.displayName = 'ListItemWithImageTooltipContent';
TooltipContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  learnMoreText: PropTypes.string,
  onLearnMore: PropTypes.func,
};

export default TooltipContent;
