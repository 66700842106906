:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._massage_1ps4z_7 {
  padding: 18px 18px 18px 12px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #dfe5eb;
  background: white; }
  ._massage__wrapper_1ps4z_12 {
    display: flex;
    flex-direction: column; }
  ._massage__suggestion_1ps4z_15 {
    padding-top: 6px; }
  ._massage_user_1ps4z_17 {
    border-radius: 8px 0px 8px 8px;
    border: 1px solid #a8caff;
    background: #e7f0ff;
    padding: 12px 18px;
    margin-left: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  ._massage__content_1ps4z_24 {
    display: flex;
    gap: 0 6px; }
