// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import $ from 'zepto';
import experiment from 'experiment';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { cx } from '@/util';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import * as utils from '@wix/santa-editor-utils';
import inputMixin from '../inputMixin';
import DropDownSelect from '../dropDown/select';
import DropDownOption from '../dropDown/option';
import { calculateInput, EntryType } from './stepper.utils';
const { normalizeNumber } = utils.math;

const DOWN = 40;
const UP = 38;
const ENTER = 13;
const ESC = 27;

// eslint-disable-next-line react/prefer-es6-class
const stepper = createReactClass<any>({
  displayName: 'stepper',
  mixins: [inputMixin, core.mixins.editorAPIMixin],
  propTypes: {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    selectedUnits: PropTypes.string,
    onUnitsChange: PropTypes.func,
    units: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    afterBlur: PropTypes.func,
    isInvalidValue: PropTypes.bool,
    dynamicInputSize: PropTypes.object,
    inputInFocus: PropTypes.bool,
    automationId: PropTypes.string,
    target: PropTypes.string,
    biParams: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  },

  getDefaultProps() {
    return {
      max: 100,
      min: 0,
      step: 1,
      units: '',
    };
  },

  getInitialState() {
    const value = util.valueLink.getValueFromProps(this.props);
    return {
      value: normalizeNumber(
        value !== undefined ? Number(value) : 0,
        this.props.min,
        this.props.max,
        this.props.step,
      ),
      edited: false,
    };
  },

  componentDidMount() {
    if (this.props.inputInFocus) {
      ReactDOM.findDOMNode(this.refs.input).focus();
    }
  },

  handleKeyDown(e) {
    const { keyCode } = e;
    const shiftKeyPressed = e.shiftKey;

    if (keyCode === ENTER) {
      e.preventDefault();
      ReactDOM.findDOMNode(this.refs.input).blur();
    }
    if (keyCode === ESC) {
      e.preventDefault();
      this.handleCancel();
    }
    if (keyCode === UP) {
      e.preventDefault();
      if (shiftKeyPressed) {
        this.changeValueByStep(true, 10 * this.props.step);
      } else {
        this.changeValueByStep(true);
      }
    }
    if (keyCode === DOWN) {
      e.preventDefault();
      if (shiftKeyPressed) {
        this.changeValueByStep(false, 10 * this.props.step);
      } else {
        this.changeValueByStep(false);
      }
    }
  },

  getWrapperClasses() {
    const classes = {
      'input-stepper': true,
      disabled: this.isDisabled(),
      edited: this.state.edited,
      'has-units': this.props.units,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      'with-units-dd': this.props.units && _.isArray(this.props.units),
      'units-dd-expanded':
        this.refs.unitsDropdown && this.refs.unitsDropdown.isExpanded(),
      'bad-value': this.props.isInvalidValue,
      'no-padding': this.props.noPadding,
    };

    if (this.props.className) {
      classes[this.props.className] = true;
    }

    return classes;
  },

  changeValueByStep(isIncrease, stepToOverride) {
    stepToOverride = stepToOverride || this.props.step;
    let newVal = isIncrease
      ? parseFloat(this.state.value) + stepToOverride
      : this.state.value - stepToOverride;
    newVal = normalizeNumber(
      newVal,
      this.props.min,
      this.props.max,
      this.props.step,
    );
    this.acceptChanges(newVal);
  },

  isTemporaryValue(value) {
    const temporaryValue = ['.', '-', ''];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(temporaryValue, value);
  },

  handleChange(e) {
    const enteredValue = e.target.value;

    if (experiment.isOpen('se_calculationInput') && !this.props.units) {
      if (!/^[0-9*+\-/ ]*$/.test(enteredValue)) {
        return;
      }

      this.setState({ value: enteredValue });
    } else {
      if (!/^-?[0-9]*(\.[0-9]*)?$/.test(enteredValue)) {
        return;
      }
      this.setState({ value: enteredValue });
    }
  },

  handleCancel() {
    const latestValue = util.valueLink.getValueFromProps(this.props);
    const self = this;
    this.setState({ value: latestValue }, function () {
      ReactDOM.findDOMNode(self.refs.input).blur();
    });
  },

  handleBlur() {
    this.setState({ edited: false });
    if (this.props.disabled) {
      return;
    }

    const editorAPI = this.getEditorAPI();
    const isCalculateExperimentOpen = experiment.isOpen('se_calculationInput');
    const oldValue = this.props.value;
    const calulatedValues = isCalculateExperimentOpen
      ? calculateInput(this.state.value)
      : { value: this.state.value, entryType: EntryType.MANUAL };
    const newValue = calulatedValues.value;

    if (isCalculateExperimentOpen && !newValue && newValue !== 0) return;

    if (
      this.props.target === 'toolbar_width' ||
      this.props.target === 'toolbar_height'
    ) {
      editorAPI.bi.event(coreBi.events.toolbar.TOOL_BAR_RESIZED, {
        component_id: this.props.biParams.component_id,
        component_type: this.props.biParams.component_type,
        target: this.props.target,
        entry_type: calulatedValues?.entryType,
        isChanged: oldValue !== newValue,
        valueOrigin: oldValue,
        value: newValue,
      });
    }

    if (
      this.props.target === 'toolbar_x' ||
      this.props.target === 'toolbar_y'
    ) {
      editorAPI.bi.event(coreBi.events.toolbar.TOOLBAR_POSITION_CHANGE, {
        ...this.props.biParams,
        xCoordinate_new:
          this.props.target === 'toolbar_x' ? newValue : undefined,
        yCoordinate_new:
          this.props.target === 'toolbar_y' ? newValue : undefined,
        entry_type: calulatedValues?.entryType,
        isChanged: oldValue !== newValue,
      });
    }
    if (isCalculateExperimentOpen) {
      this.setState({ value: newValue });
    }
    let acceptedValue = normalizeNumber(
      newValue,
      this.props.min,
      this.props.max,
      this.props.step,
    );
    if (this.isTemporaryValue(newValue)) {
      acceptedValue = util.valueLink.getValueFromProps(this.props);
    }
    this.acceptChanges(acceptedValue);
    if (this.props.afterBlur) {
      this.props.afterBlur(acceptedValue);
    }
  },
  getStepperValue(props) {
    const value = util.valueLink.getValueFromProps(props);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(value)) {
      return parseFloat(value);
    }
    return value;
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const valueFromProps = this.getStepperValue(nextProps);
    if (!this.state.edited && valueFromProps !== this.state.value) {
      const newVal = normalizeNumber(
        valueFromProps,
        isNaN(nextProps.min) ? this.props.min : nextProps.min,
        nextProps.max || this.props.max,
        nextProps.step || this.props.step,
      );
      this.setState({ value: newVal });
    }
  },

  onFocus() {
    const $input = $(ReactDOM.findDOMNode(this.refs.input));
    this.setState({ edited: true }, function () {
      const inputElem = _.head($input);
      if (inputElem) {
        inputElem.select();
        $input.one('mouseup', function (e) {
          e.preventDefault();
        });
      }
    });
  },
  acceptChanges(value) {
    this.setState({ value });
    util.valueLink.callOnChangeIfExists(this.props, value);
  },

  getStepperWidth() {
    if (this.props.dynamicInputSize) {
      const value = Math.floor(this.getStepperValue(this.props));
      let valueLength = String(Math.abs(value)).length;
      valueLength += value < 0 ? 1 : 0;
      return {
        width: Math.max(
          valueLength * this.props.dynamicInputSize.characterWidth,
          this.props.dynamicInputSize.minWidth,
        ),
      };
    }

    return {};
  },

  render() {
    const { units } = this.props;

    return (
      <div
        onClick={() => {
          this.refs.input.focus();
        }}
        style={this.getStepperWidth()}
        className={cx(this.getWrapperClasses())}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <input
          ref="input"
          type="text"
          value={this.state.value}
          disabled={!!this.props.disabled}
          readOnly={!this.state.edited}
          maxLength="8"
          data-aid={this.props.automationId}
          step={this.props.step}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={() => {
            this.onFocus();
          }}
          onBlur={() => {
            this.handleBlur();
          }}
          className="input"
        />
        {units ? (
          <span key="units" className="units-container">
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/is-array */}
            {!_.isArray(units) ? (
              <span key="units-single" className="units">
                {units}
              </span>
            ) : null}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/is-array */}
            {_.isArray(units) ? (
              <DropDownSelect
                key="units-select"
                ref="unitsDropdown"
                value={this.props.selectedUnits}
                onChange={this.props.onUnitsChange}
                optionsWidth={54}
                shouldTranslate={false}
                disabled={!!this.props.disabled}
                className="units-dd"
              >
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(units, (unitsItem, unitsItemIndex) => (
                  <DropDownOption
                    value={unitsItem.value}
                    key={`unitsItem${unitsItemIndex}`}
                    className="units-dropdown"
                  >
                    {unitsItem.label}
                  </DropDownOption>
                ))}
                {'>'}
              </DropDownSelect>
            ) : null}
          </span>
        ) : null}
      </div>
    );
  },
});

export default stepper;
