// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as util from '@/util';
import inputMixin from './inputMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import Tooltip from '../popovers/tooltip';

const MIN_SIZE = 5;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'proportionsSlider',
  mixins: [inputMixin],
  propTypes: {
    width: PropTypes.number,
    value: PropTypes.array,
  },
  getInitialState() {
    this.throttleCallOnChangeIfExists = _.debounce(
      (...args) => util.valueLink.callOnChangeIfExists(this.props, ...args),
      300,
    );
    return {
      proportions: util.valueLink.getValueFromProps(this.props),
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ proportions: util.valueLink.getValueFromProps(nextProps) });
  },
  getDefaultProps() {
    return {
      value: [50, 50],
      width: 100,
    };
  },
  getProportions() {
    const { proportions } = this.state;
    const sum = _.sum(proportions);
    const diffFrom100 = 100 - sum;

    if (diffFrom100) {
      proportions[0] += diffFrom100;
    }

    return proportions;
  },
  updateProportions(newProportions) {
    if (!_.isEqual(newProportions, this.getProportions())) {
      this.setState({ proportions: newProportions });
      this.throttleCallOnChangeIfExists(newProportions);
    }
  },
  updateDividerPosition(currentProportions, dividerIndex, delta) {
    const newProportions = _.clone(currentProportions);
    const newCurrentIndexWidth = newProportions[dividerIndex] - delta;
    const newPrevIndexWidth = newProportions[dividerIndex - 1] + delta;

    if (newCurrentIndexWidth >= MIN_SIZE && newPrevIndexWidth >= MIN_SIZE) {
      newProportions[dividerIndex] = newCurrentIndexWidth;
      newProportions[dividerIndex - 1] = newPrevIndexWidth;

      this.updateProportions(newProportions);
    }
  },
  onDividerStartDrag(dividerIndex, event) {
    event.preventDefault();
    this.dragInfo = {
      index: dividerIndex,
      x: event.pageX,
      proportions: this.getProportions(),
    };
    $(window.document).on('mousemove', this.onDividerDrag);
    $(window.document).on('mouseup', this.onDividerEndDrag);
  },
  onDividerDrag(event) {
    event.preventDefault();
    if (!this.dragInfo) {
      return;
    }
    const deltaX = event.pageX - this.dragInfo.x;
    const deltaPercentage = Math.round((100 * deltaX) / this.props.width);

    this.updateDividerPosition(
      this.dragInfo.proportions,
      this.dragInfo.index,
      deltaPercentage,
    );
  },
  onDividerEndDrag(event) {
    event.preventDefault();
    delete this.dragInfo;
    $(window.document).off('mousemove', this.onDividerDrag);
    $(window.document).off('mouseup', this.onDividerEndDrag);
  },
  render() {
    return (
      <div style={{ width: this.props.width }} className="proportions-slider">
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.getProportions(), (column, columnIndex) => (
          <div
            key={`column-${columnIndex}`}
            style={{ width: `${column}%` }}
            className="column"
          >
            <div className="label">{column}%</div>
            {columnIndex !== 0 ? (
              <Tooltip
                value="STRIP_CONTAINER_LAYOUTS_CUSTOMIZE_PROPORTIONS_TOOLTIP"
                key={`divider-${columnIndex}`}
              >
                <span
                  onMouseDown={this.onDividerStartDrag.bind(this, columnIndex)}
                  className="divider"
                >
                  <symbols.symbol name="proportionsSliderDivider" />
                </span>
              </Tooltip>
            ) : null}
          </div>
        ))}
      </div>
    );
  },
});
