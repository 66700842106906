import React, { type FC } from 'react';

import { hoc, textUtils } from '@/util';
import { Button } from '@wix/wix-base-ui';

// import { BIContext } from '../biContext/biContext';
import CopyButton from '../copyButton';

import { mapDispatchToProps, mapStateToProps } from './messageControlsMapper';

import styles from './messageControls.scss';

import type { MessageControlsProps } from './messageControlsTypes';

const MessageControls: FC<MessageControlsProps> = ({
  updateComponentText,
  messageText,
}) => {
  // const biContext = useContext(BIContext);

  const handleCopyClick = () => {
    textUtils.copyTextToClipBoard(messageText);

    // biContext.sendUseTextClick({
    //   clickOrigin: 'copy (button)',
    //   text: messageText,
    // });
  };

  const handleUseItClick = () => {
    updateComponentText(messageText);

    // biContext.sendUseTextClick({
    //   clickOrigin: 'use text (button)',
    //   text: messageText,
    // });
  };

  return (
    <div className={styles.controls}>
      <div className={styles.controls__left}>
        <CopyButton tooltipContent="Copy text" onClick={handleCopyClick} />

        <Button
          onClick={handleUseItClick}
          shouldTranslate={false}
          className="btn-sm"
        >
          Use it
        </Button>
      </div>
    </div>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MessageControls);
