import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import { Copy } from '@wix/wix-ui-icons-common/classic-editor';
import styles from './copyButton.scss';

const CopyButton: React.FunctionComponent<{
  tooltipContent: string;
  onClick: () => void;
}> = ({ onClick, tooltipContent }) => {
  return (
    <Tooltip shouldTranslate={false} content={tooltipContent} closeOnMouseClick>
      <Copy onClick={onClick} className={styles.copyButton} />
    </Tooltip>
  );
};

export default CopyButton;
