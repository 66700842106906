import React, { useState, useRef, useEffect } from 'react';
import { cx } from '@/util';
import {
  ChevronUpSmall,
  ChevronDownSmall,
} from '@wix/wix-ui-icons-common/classic-editor';
import { Text } from '@wix/wix-base-ui';
import styles from './dropdown.scss';

import type { DropdownProps, Option } from './dropdownTypes';

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  label,
  shouldTranslate,
  onDropdownOpen,
  onDropdownToggle,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (disabled) return;

    const isOpenLocal = !isOpen;

    if (isOpenLocal && onDropdownOpen) onDropdownOpen(label);

    setIsOpen(isOpenLocal);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const calculatePosition = () => {
    if (dropdownRef.current) {
      const buttonRect = dropdownRef.current.getBoundingClientRect();

      setPosition({
        top: buttonRect.bottom + window.scrollY,
        left: buttonRect.left + window.scrollX,
        width: dropdownRef.current.offsetWidth,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      calculatePosition();
    }

    if (onDropdownToggle) onDropdownToggle(isOpen);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={cx(styles.dropdown, {
        [styles.dropdown_open]: isOpen,
      })}
      ref={dropdownRef}
    >
      <div className={styles.dropdown__header} onClick={toggleDropdown}>
        <div className={styles.dropdown__text}>
          <Text size="tiny" shouldTranslate={shouldTranslate}>
            {selectedOption ? selectedOption.label : label}
          </Text>
        </div>

        <div className={styles.dropdown__icon}>
          {isOpen ? <ChevronUpSmall /> : <ChevronDownSmall />}
        </div>
      </div>
      {isOpen && (
        <ul
          className={styles.dropdown__list}
          style={{
            ...position,
          }}
        >
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              <Text size="tiny" shouldTranslate={false}>
                {option.label}
              </Text>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
