// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { ImSureInputIsSafeInjectHtml } from '@/util';
import React from 'react';

function convertFlipToSVGTransform(crop) {
  const { width, height, x, y, flip } = crop;
  const transX = x * 2 + width;
  const transY = y * 2 + height;
  let scale;
  let translate;

  switch (flip) {
    case 'x':
      scale = '1 -1';
      translate = `0 -${transY}`;
      break;
    case 'y':
      scale = '-1 1';
      translate = `-${transX} 0`;
      break;
    case 'xy':
      scale = '-1 -1';
      translate = `-${transX} -${transY}`;
      break;
    default:
      scale = '1 1';
      translate = '0 0';
  }

  return `scale(${scale}) translate(${translate})`;
}

const svgImageTemplate = ({
  src,
  x,
  y,
  width,
  height,
  transform,
  preserveAspectRatio,
  filter,
  mask,
}) => {
  filter = filter ? ` filter="${filter}"` : '';
  mask = mask ? ` mask="${mask}"` : '';
  return `<image xlink:href="${src}" x="${x || 0}" y="${
    y || 0
  }" width="${width}" height="${height}" transform="${transform}" preserveAspectRatio="${preserveAspectRatio}"${filter}${mask}/>`;
};

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  imageData: AnyFixMe;
  imageWidth: number;
  imageHeight: number;
  fittingType?: string;
  crop?: AnyFixMe;
  draggable?: boolean;
  debounce?: number;
  onLoad?: FunctionFixMe;
  isClip?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'Image';

  static propTypes = {
    imageData: PropTypes.object.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    fittingType: PropTypes.string,
    crop: PropTypes.object,
    draggable: PropTypes.bool,
    debounce: PropTypes.number,
    onLoad: PropTypes.func,
    isClip: PropTypes.bool,
  };

  static defaultProps = {
    draggable: true,
  };

  constructor(props) {
    super(props);
    const effectType = props.imageData?.filterEffect?.effectType;
    const effectName = effectType || (props.imageData?.effectName ?? 'none');

    if (effectName !== 'none') {
      this.effectName = effectName;
      this.filterId = `filter_${_.uniqueId()}`;
    }

    this.state = {
      imageUrl: '',
    };
  }

  getSvgStyle = () => {
    return {
      width: this.props.imageWidth,
      height: this.props.imageHeight,
      left: 0,
      top: 0,
      overflow: 'hidden',
      position: 'absolute',
    };
  };

  filterId = '';
  effectName = '';
  imageHtmlTag = 'img';

  UNSAFE_componentWillMount() {
    this.updateImageUrl = _.debounce(
      this.updateImageUrl,
      this.props.debounce || 0,
      { trailing: true },
    );
  }

  componentDidMount() {
    this.mounted = true;
    if (this.imageUrlToSetOnMount) {
      this.updateImageUrl(this.imageUrlToSetOnMount);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateImageUrl = (imageUrl) => {
    if (this.mounted) {
      this.setState(
        {
          imageUrl,
        },
        () => {
          delete this.imageUrlToSetOnMount;
        },
      );
    } else {
      this.imageUrlToSetOnMount = imageUrl;
    }
  };

  setImageHtmlTag = () => {
    const { crop } = this.props;
    const hasCrop = crop && (crop.svgId || crop.svgStr);

    if (this.effectName || hasCrop) {
      if (hasCrop) {
        this.maskId = this.maskId || `mask_${_.uniqueId()}`;
      } else if (this.maskId) {
        this.maskId = null;
      }

      if (this.effectName || hasCrop) {
        this.imageHtmlTag = 'svg';
      }
    } else {
      this.imageHtmlTag = 'img';

      if (this.maskId) {
        this.maskId = null;
      }
    }
  };

  getContainerStyle = (baseStyle) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props.style || {}, baseStyle);
  };

  getImageStyle = (baseStyle) => {
    if (this.effectName) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        {
          WebkitFilter: this.filterId ? `url(#${this.filterId})` : '',
          filter: this.filterId ? `url(#${this.filterId})` : '',
        },
        baseStyle,
      );
    }
    return baseStyle;
  };

  getImageProps = () => {
    this.setImageHtmlTag();

    const imageTransformedData = util.imageTransform.getFromImageData(
      this.props.imageData,
      this.props.imageWidth,
      this.props.imageHeight,
      this.props.fittingType || util.imageTransform.fittingTypes.SCALE_TO_FILL,
      util.imageTransform.alignTypes.CENTER,
      this.imageHtmlTag,
    );
    if (
      this.props.debounce &&
      imageTransformedData.uri !== this.state.imageUrl
    ) {
      this.updateImageUrl(imageTransformedData.uri);
    }

    return imageTransformedData;
  };

  getUrl = (newUrl) => {
    if (this.props.debounce) {
      return this.state.imageUrl;
    }
    return newUrl;
  };

  getFilterDefinition = () => {
    return this.filterId
      ? util.imageEffects.getFilter(
          this.filterId,
          this.effectName,
          this.props.imageData?.filterEffect,
        )
      : '';
  };

  getMaskDefinition = () => {
    const { crop } = this.props;

    if (crop) {
      const { svgStr, svgDomId } = crop;
      let { width, height, x, y } = crop;

      if (!width) {
        width = '100%';
        height = '100%';
        x = 0;
        y = 0;
      }

      const transform = convertFlipToSVGTransform(crop);

      return util.imageEffects.getMask(
        this.maskId,
        svgDomId,
        svgStr,
        { width, height, x, y, transform },
        this.props.isClip,
      );
    }

    return '';
  };

  getLegacySvgStyle = (imageProps) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        width: Math.round(this.props.imageWidth),
        height: Math.round(this.props.imageHeight),
      },
      imageProps.css.container,
    );
  };

  getMaskContour = () => {
    const { svgDomId, maskContour, width, height, x, y } = this.props.crop;
    const contourWidth = maskContour.width;
    const contourColor = maskContour.color;

    return `<use xlink:href="#${svgDomId}" x="${x}" y="${y}" width="${width}" height="${height}" stroke="${contourColor}" stroke-width="${contourWidth}"></use>`;
  };

  getLegacySvgInnerHtml = (imageProps) => {
    const defs = `<defs>${this.getFilterDefinition()}${this.getMaskDefinition()}</defs>`;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageTemplateObj = _.assign(
      {
        filter: this.filterId && `url(#${this.filterId})`,
        mask: this.maskId && `url(#${this.maskId})`,
        src: imageProps.uri,
      },
      imageProps.attr.img,
    );
    const image = svgImageTemplate(imageTemplateObj);
    const { crop } = this.props;
    const hasMaskContour = crop?.maskContour;

    return `${defs}${hasMaskContour ? this.getMaskContour() : ''}${image}`;
  };

  /**
   * generate unique key for each dimension
   * @param imageProps
   * @returns {string}
   */
  getLegacyKey = (imageProps) => {
    const { crop } = this.props;
    const cropKey = crop
      ? `_${crop.x}_${crop.y}_${crop.width}_${crop.height}${
          crop.flip ? `_${crop.flip}` : ''
        }${crop.svgId ? `_${crop.svgId}` : ''}`
      : '';
    return `svg_type_svg${
      imageProps.attr.img.width * imageProps.attr.img.height
    }${cropKey}`;
  };

  render() {
    const imageProps = this.getImageProps();

    return (
      <div
        style={this.getContainerStyle(imageProps.css.container)}
        className={util.inheritClassName(this.props)}
      >
        {this.imageHtmlTag === 'img' ? (
          <img
            draggable={this.props.draggable}
            key="image_type_img"
            src={this.getUrl(imageProps.uri)}
            style={this.getImageStyle(imageProps.css.img)}
            onLoad={this.props.onLoad}
          />
        ) : null}

        {this.effectName && this.imageHtmlTag === 'img' ? (
          <svg key="svg_type_img" version="1.1" style={this.getSvgStyle()}>
            <ImSureInputIsSafeInjectHtml
              tag="defs"
              html={this.getFilterDefinition()}
            />
          </svg>
        ) : null}

        {this.imageHtmlTag === 'svg' ? (
          <ImSureInputIsSafeInjectHtml
            tag="svg"
            key={this.getLegacyKey(imageProps)}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            html={this.getLegacySvgInnerHtml(imageProps)}
            {...imageProps.attr.container}
            style={{
              ...this.getLegacySvgStyle(imageProps),
              ...imageProps.attr.container?.style,
            }}
          />
        ) : null}
      </div>
    );
  }
}
