import React, { type FC } from 'react';
import { hoc } from '@/util';
import { translate } from '@/i18n';
import { TextButton, Text } from '@wix/wix-base-ui';
import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
} from './businessTypePanelLink.mapper';
import type { Props } from './types';

const BusinessTypePanelLink: FC<Props> = ({
  onBusinessTypeClick,
  businessTypeName,
}) => {
  const businessTypeLabel = translate(
    businessTypeName
      ? 'add_section_content_injection_business_type_header'
      : 'add_section_content_injection_no_bt_label',
  );
  const businessTypeValue =
    businessTypeName || translate('add_section_content_injection_no_bt_cta');

  return (
    <>
      <Text shouldTranslate={false} size="tiny">
        {businessTypeLabel}
      </Text>

      <TextButton
        size="tiny"
        shouldTranslate={false}
        onClick={onBusinessTypeClick}
        dataHook="business-type-link-button"
      >
        {businessTypeValue}
      </TextButton>
    </>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BusinessTypePanelLink);
