// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import inputMixin from './inputMixin';
import * as util from '@/util';
import React from 'react';

let seq = 0;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'RadioGroup',
  propTypes: {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  },
  mixins: [inputMixin, util.translationMixin],

  getRadioGroupId() {
    if (!this.groupId) {
      this.groupId = `rg_${seq++}`;
    }
    return this.groupId;
  },

  handleChange(newSelectedValue) {
    util.valueLink.callOnChangeIfExists(this.props, newSelectedValue);
  },

  handleOnMouseOver(newSelectedValue) {
    if (!_.has(this.props, 'onMouseOver')) {
      return;
    }
    this.props.onMouseOver(newSelectedValue);
  },

  handleOnRadioSelect(newSelectedValue) {
    if (!_.has(this.props, 'onRadioSelect')) {
      return;
    }
    this.props.onRadioSelect(newSelectedValue);
  },

  render() {
    return (
      <span>
        <span ref="label" className="label">
          {this.translateIfNeeded(this.getLabel())}
        </span>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option, optionIndex) => {
          const selectedValue = util.valueLink.getValueFromProps(this.props);

          return (
            <label key={`label${optionIndex}`}>
              <input
                ref={`radio_${optionIndex}`}
                type="radio"
                name={this.getRadioGroupId()}
                onClick={() => {
                  this.handleOnRadioSelect(option.value);
                }}
                onMouseOver={() => {
                  this.handleOnMouseOver(option.value);
                }}
                onChange={() => {
                  this.handleChange(option.value);
                }}
                checked={selectedValue === option.value}
              />
              <span ref={`option_${optionIndex}`} className="option">
                {this.translateIfNeeded(option.label)}
              </span>
            </label>
          );
        })}
      </span>
    );
  },
});
