import React from 'react';
import { TextInput, CloseButton } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import { SafeInjectHtml } from '@/util';

interface SearchProps {
  value: string;
  placeholder: string;
  clearButton: boolean;
  options: string[];
  onChange: (value: string) => void;
  onItemClick: (value: string) => void;
  onEnter?: (value: string) => void;
  focus?: boolean;
}

interface SearchState {
  showOptions: boolean;
  active: number;
}

const highlight = (subject: string, match: string) =>
  subject
    .replace(new RegExp(match, 'i'), '<strong>$&</strong>')
    .replace(/> /g, '>&nbsp;');

class Search extends React.Component<SearchProps, SearchState> {
  static defaultProps = {
    clearButton: true,
    placeholder: 'Search',
  };

  activeListItemRef: React.RefObject<HTMLLIElement>;

  constructor(props: AnyFixMe) {
    super(props);
    this.activeListItemRef = React.createRef();
  }

  state: SearchState = {
    showOptions: false,
    active: null,
  };

  onFocus = () => {
    this.setState({
      showOptions: true,
    });
    this.resetActiveIndex();
  };

  onBlur = () => {
    this.setState({
      showOptions: false,
    });
  };

  onChange = (value: string) => {
    this.props.onChange(value);
    this.resetActiveIndex();
  };

  onItemClick = (value: string) => {
    this.props.onItemClick(value);
  };

  onEnter = (value: string) => {
    /*
     * Generaly it should be the same as the onItemClick
     * but we needed 2 different bi's -> had to create 2 defferent methods
     */

    return this.props.onEnter
      ? this.props.onEnter(value)
      : this.props.onItemClick(value);
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, blur: () => void) => {
    const { options } = this.props;
    const { active } = this.state;
    switch (e.key) {
      case 'Enter': {
        if (active !== null) {
          e.preventDefault();
          this.onEnter(options[active]);
          blur(); // Callback from baseUILib.TextInput
        }
        break;
      }

      case 'ArrowDown': {
        e.preventDefault();
        const newActiveIndex = (active + 1) % options.length;
        this.setState({ active: newActiveIndex }, this.insureVisibility);
        break;
      }

      case 'ArrowUp': {
        e.preventDefault();
        const newActiveIndex = active === 0 ? options.length - 1 : active - 1;
        this.setState({ active: newActiveIndex }, this.insureVisibility);
        break;
      }

      default:
        break;
    }
  };

  onClear = () => {
    this.props.onChange('');
  };

  resetActiveIndex = () => {
    this.setState({ active: 0 });
  };

  insureVisibility = () => {
    this.activeListItemRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
    });
  };

  renderOptions() {
    const { options, value } = this.props;

    return (
      <ul className="control-search-options">
        {options.map((item: string, index) => (
          <li
            key={item}
            data-aid={`control-search-option-${index}`}
            ref={index === this.state.active ? this.activeListItemRef : null}
            className={`control-search-options__item ${
              index === this.state.active ? 'active' : ''
            }`}
            onMouseDown={() => {
              this.onItemClick(item);
            }}
            onMouseEnter={() => this.setState({ active: index })}
          >
            <SafeInjectHtml
              tag="div"
              className="control-search-options__item-content"
              html={highlight(item, value)}
            />
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { value, placeholder, clearButton, focus } = this.props;
    const { showOptions, active } = this.state;

    return (
      <div className="control-search">
        <div className="control-search-input">
          <TextInput
            value={value}
            automationId={'control-search-input'}
            placeholder={placeholder}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            focus={focus}
            blurOnEnterKey={!active}
            onKeyDown={this.onKeyDown}
          />

          <span className="control-search-input__prefix">
            <Symbols.symbol
              className="control-search-input__symbol"
              name="search_all_code"
            />
          </span>

          {clearButton && value && (
            <span className="control-search-input__suffix">
              <CloseButton onClick={this.onClear} />
            </span>
          )}
        </div>

        {value && showOptions && this.renderOptions()}
      </div>
    );
  }
}

export default Search;
