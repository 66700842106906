// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import inputMixin from '../inputMixin';
import React from 'react';
import UIButton from '../../controls/button';
import Tooltip from '../../popovers/tooltip';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

const BUTTON_PROP = PropTypes.shape({
  nonEmptyButtonLabel: PropTypes.string,
  nonEmptyButtonIcon: PropTypes.string,
  emptyButtonLabel: PropTypes.string,
  emptyButtonIcon: PropTypes.string,
  onClick: PropTypes.func,
});

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'imagePreview',
  mixins: [inputMixin],
  propTypes: {
    emptySymbolName: PropTypes.string,
    getImageTransformData: PropTypes.func.isRequired,
    imageFittingType: PropTypes.string,
    buttons: PropTypes.oneOfType([PropTypes.arrayOf(BUTTON_PROP), BUTTON_PROP])
      .isRequired,
  },
  getDefaultProps() {
    return {
      buttons: [],
    };
  },
  getInitialState() {
    return {
      imageIndex: 0,
      style: {},
    };
  },
  UNSAFE_componentWillReceiveProps(nexProps) {
    if (this.state.imageIndex >= this.getNumberOfImages(nexProps)) {
      this.setState({
        imageIndex: 0,
      });
    }
  },
  getButtons() {
    let { buttons } = this.props;
    if (!buttons) {
      buttons = [];
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    } else if (!_.isArray(buttons)) {
      buttons = [buttons];
    }
    return buttons;
  },

  getImageTransformData() {
    const imageTransformData = this.props.getImageTransformData(
      this._getImages(this.props)[this.state.imageIndex],
      this.props.imageFittingType,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, imageTransformData.css, {
      backgroundImage: `url(${imageTransformData.url})`,
    });
  },
  getButtonIcon(button) {
    return this.isEmpty() ? button.emptyButtonIcon : button.nonEmptyButtonIcon;
  },
  getButtonLabel(button) {
    return this.isEmpty()
      ? button.emptyButtonLabel
      : button.nonEmptyButtonLabel;
  },
  getButtonTooltip(button) {
    return this.isEmpty()
      ? button.emptyButtonTooltip
      : button.nonEmptyButtonTooltip;
  },
  isButtonDisabled(button) {
    return !!button.disabled;
  },
  _showImage(imageIndex) {
    const images = this._getImages(this.props);
    if (imageIndex < 0) {
      imageIndex = images.length - 1;
    } else if (imageIndex === images.length) {
      imageIndex = 0;
    }
    this.setState({
      imageIndex,
    });
  },
  showPrevImage() {
    this._showImage(this.state.imageIndex - 1);
  },
  showNextImage() {
    this._showImage(this.state.imageIndex + 1);
  },

  _getImages(props) {
    let images = util.valueLink.getValueFromProps(props);
    if (_.isEmpty(images)) {
      images = [];
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    } else if (!_.isArray(images)) {
      images = [images];
    }
    return images;
  },
  getNumberOfImages(props) {
    return this._getImages(props).length;
  },
  isEmpty() {
    const images = this._getImages(this.props);
    return _.isEmpty(images) || _.isEmpty(images[this.state.imageIndex].uri);
  },

  onClick(button) {
    button.onClick((...args) =>
      util.valueLink.callOnChangeIfExists(this.props, ...args),
    );
  },
  getButtonClasses(button) {
    return {
      action: true,
      'has-icon': !!this.getButtonIcon(button),
      'has-label': !!this.getButtonLabel(button),
      'no-margin': true,
    };
  },
  render() {
    return (
      <div
        className={`${util.inheritClassName(this.props, 'image-preview')}${
          this.isEmpty() ? ' empty' : ''
        }`}
      >
        {!this.isEmpty() ? (
          <div key="wrapper" className="image-wrapper">
            {/* <img src="{this.getURL()}" style="{this.getStyle()}" onLoad="{this.updateStyle}"/> */}
            <div
              style={this.getImageTransformData()}
              className="image"
              data-hook="image-preview-wrapper"
            />
          </div>
        ) : null}

        {this.getNumberOfImages(this.props) > 1 ? (
          <span
            onClick={this.showPrevImage}
            key="prev"
            className="arrow-button prev"
          />
        ) : null}
        {this.getNumberOfImages(this.props) > 1 ? (
          <span
            onClick={this.showNextImage}
            key="next"
            className="arrow-button next"
          />
        ) : null}

        {this.isEmpty() ? (
          <div
            key="placeholder"
            className={cx({
              'placeholder-wrapper': true,
              'symbol-in-the-middle': this.getButtons().length === 0,
            })}
          >
            {this.props.emptySymbolName ? (
              <symbols.symbol name={this.props.emptySymbolName} key="symbol" />
            ) : null}
            <div className="corner-borders top" />
            <div className="corner-borders bottom" />
          </div>
        ) : null}

        {this.getButtons().length > 0
          ? (() => {
              const buttons = this.getButtons();

              return (
                <span key="buttons" className="button-wrapper">
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(buttons, (button, buttonIndex) => (
                    <Tooltip
                      key={
                        this.getButtonLabel(button) || `button${buttonIndex}`
                      }
                      value={this.getButtonTooltip(button)}
                      disabled={!this.getButtonTooltip(button)}
                    >
                      <UIButton
                        label={this.getButtonLabel(button)}
                        icon={this.getButtonIcon(button)}
                        disabled={this.isButtonDisabled(button)}
                        onClick={() => {
                          this.onClick(button);
                        }}
                        className={cx(this.getButtonClasses(button))}
                      />
                    </Tooltip>
                  ))}
                </span>
              );
            })()
          : null}
      </div>
    );
  },
});
