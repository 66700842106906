import type { MapStateToProps } from 'types/redux';
import type {
  MessageControlsDispatchProps,
  MessageControlsOwnProps,
  MessageControlsStateProps,
} from './messageControlsTypes';

export const mapStateToProps: MapStateToProps<
  MessageControlsStateProps,
  MessageControlsOwnProps
> = () => {
  return {
    componentId: '',
    parentComponentId: '',
    componentText: '',
  };
};

export const mapDispatchToProps: MessageControlsDispatchProps = {
  updateComponentText: () => {},
};
