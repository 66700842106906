// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from '@/constants';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../controls/infoIcon';
import * as util from '@/util';
import { translate } from '@/i18n';
import { ActionWithSymbol, TextLabel } from '@wix/wix-base-ui';
import { cx } from '@/util';

const successProps = {
  name: 'inputValidationSuccess',
  className: 'validation-icon validation-icon-success',
};

const defaultSymbol = 'linkBtnThin';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  isDisabled?: boolean;
  displayedValue: string;
  label?: string;
  onClick: FunctionFixMe;
  buttonIcon?: string;
  showButton?: boolean;
  showSuccessSymbol?: boolean;
  infoIconText?: string;
  infoIconTitle?: string;
  eventsToShowButtonTooltip?: Array<AnyFixMe>;
  eventsToHideButtonTooltip?: Array<AnyFixMe>;
  tooltipText?: string;
  tooltipWidth?: number;
  tooltipStyle?: AnyFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'labelWithCircleButton';

  static propTypes = {
    isDisabled: PropTypes.bool,
    displayedValue: PropTypes.string.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    buttonIcon: PropTypes.string,
    showButton: PropTypes.bool,
    showSuccessSymbol: PropTypes.bool,
    infoIconText: PropTypes.string,
    infoIconTitle: PropTypes.string,
    eventsToShowButtonTooltip: PropTypes.array,
    eventsToHideButtonTooltip: PropTypes.array,
    tooltipText: PropTypes.string,
    tooltipWidth: PropTypes.number,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    tooltipStyle: PropTypes.oneOf(_.values(constants.UI.TOOLTIP.STYLE_TYPE)),
  };

  static defaultProps = {
    showButton: true,
  };

  getSymbol = () => {
    if (this.props.showSuccessSymbol) {
      return React.createElement(symbols.symbol, successProps);
    }
    if (!this.props.showButton) {
      return null;
    }
    if (this.props.buttonIcon === defaultSymbol) {
      return undefined;
    }
    return React.createElement(symbols.symbol, { name: this.props.buttonIcon });
  };

  render() {
    return (
      <div
        className={util.inheritClassName(
          this.props,
          `label-with-circle-button${this.props.isDisabled ? ' disabled' : ''}`,
        )}
      >
        {this.props.label ? (
          <label
            key="linkToLabel"
            className={cx({ 'with-button': this.props.showButton })}
          >
            {translate(this.props.label)}
          </label>
        ) : null}
        {this.props.infoIconText ? (
          <InfoIcon
            key="linkToIconInfo"
            text={this.props.infoIconText}
            title={this.props.infoIconTitle}
            className="link-to-icon-info"
          />
        ) : null}
        <div className="container-action">
          <ActionWithSymbol
            action={this.props.onClick}
            symbol={this.getSymbol()}
            disabled={this.props.isDisabled}
          >
            <TextLabel
              value={this.props.displayedValue}
              shouldTranslate={false}
            />
          </ActionWithSymbol>
        </div>
      </div>
    );
  }
}
