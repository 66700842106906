// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import inputMixin from './inputMixin';
import inputValidationMixin from './inputValidationMixin';
import inputValidationAsyncMixin from './inputValidationAsyncMixin';
import tooltipManager from '../popovers/tooltipManager';
import React from 'react';
import Tooltip from '../popovers/tooltip';
import constants from '@/constants';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../controls/infoIcon';
import { TextInput, TextInputMultiline } from '@wix/wix-base-ui';
import { cx } from '@/util';

const SUCCESS_DURATION = 1000;
const TEXT_INPUT_TOOLTIP_ERROR = 'text-input-async-validation-error-';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'textInputAsync',
  propTypes: {
    shouldTranslate: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultText: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    className: PropTypes.string,
    isMultiLine: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    onChangeInValidationStatus: PropTypes.func,
    textAreaClass: PropTypes.string,
    isWarning: PropTypes.bool,
    prefix: PropTypes.string,
    validateOnBlurOnly: PropTypes.bool,
    alwaysShowInfoIcon: PropTypes.bool,
    blurOnEnterKey: PropTypes.bool,
    selectionActionCounter: PropTypes.number,
    validateInitialValue: PropTypes.bool,
  },
  mixins: [
    ReactLinkedStateMixin,
    inputMixin,
    inputValidationMixin,
    inputValidationAsyncMixin,
    util.translationMixin,
    util.blockOuterScrollMixin,
  ], //eslint-disable-line react/no-deprecated
  getInitialState() {
    const value = this.getInitialValue();
    //todo Shimi_Liderman 7/6/15 20:55 should validate initial value? if not valid what is the initial lastValidValue?
    this.lastValidValue = value;
    return {
      value,
      validationStatus: this.ValidationStatus.NONE,
      isFocused: this.props.focus,
    };
  },
  componentDidMount() {
    this.mounted = true;

    if (this.props.validateInitialValue && this.props.asyncValidator) {
      this.props.asyncValidator(
        this.state.value,
        () =>
          this.setState({ validationStatus: this.ValidationStatus.SUCCESS }),
        () => this.setState({ validationStatus: this.ValidationStatus.ERROR }),
      );
    }
    this.toggleTooltip();
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextValue = util.valueLink.getValueFromProps(nextProps);
    if (
      nextValue &&
      nextValue !== util.valueLink.getValueFromProps(this.props) &&
      nextValue !== this.state.value
    ) {
      this.lastValidValue = nextValue;
      this.setState({
        value: util.stringUtils.removeNonSupportedCharacters(nextValue),
      });
    }
  },
  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state;
    const { validationStatus } = this.state;
    const hasLostFocus = prevState.isFocused && !this.state.isFocused;
    const hasValidationStatusChanged =
      prevState.validationStatus !== validationStatus;
    const didValueChange = value !== this.lastValidValue;

    this.toggleTooltip();

    if (hasLostFocus && didValueChange) {
      this.validate(
        value,
        this.onValidationSuccess.bind(this, value),
        this.onValidationFailure,
      );
    }

    if (
      hasValidationStatusChanged &&
      validationStatus === this.ValidationStatus.SUCCESS
    ) {
      this.onValueValid(value);
    }
  },
  componentWillUnmount() {
    this.mounted = false;
  },
  getInitialValue() {
    const valueFromProps = util.valueLink.getValueFromProps(this.props);
    return valueFromProps
      ? valueFromProps
      : this.translateIfNeeded(this.props.defaultText) || '';
  },

  getWrapperClasses() {
    const classes = {
      'control-text-input': true,
      'always-show-info-icon': !!this.props.alwaysShowInfoIcon,
      success: this.state.validationStatus === this.ValidationStatus.SUCCESS,
      error: this.state.validationStatus === this.ValidationStatus.ERROR,
      warning: this.props.isWarning,
      'is-disabled': this.isDisabled(),
      focused: this.state.isFocused,
      'has-label': this.props.label,
    };

    if (this.props.className) {
      classes[this.props.className] = true;
    }

    return classes;
  },
  focusInput(e) {
    if (e) {
      e.preventDefault();
    }
    const callback = this.props.onFocus || _.noop;
    this.setState({ isFocused: true }, callback.bind(null, e));
  },
  onBlur(e, isCanceled) {
    if (isCanceled) {
      const callback = this.props.onBlur || _.noop;
      this.setState(
        {
          isFocused: false,
          value: this.lastValidValue,
          validationStatus: this.ValidationStatus.NONE,
        },
        callback.bind(null, e),
      );
    } else {
      window.setTimeout(
        function () {
          this.setState({ isFocused: !window.document.hasFocus() });
        }.bind(this),
        0,
      );
    }
  },
  ValidationStatus: {
    NONE: 'none',
    SUCCESS: 'success',
    ERROR: 'error',
  },
  getInputProps() {
    return {
      shouldTranslate: this.props.shouldTranslate,
      prefix: this.props.prefix,
      type: this.props.type,
      innerInputClass: this.props.innerInputClass,
      value: this.state.value,
      onChange: this.handleChange,
      disabled: this.isDisabled(),
      placeholder: this.props.placeholder,
      maxLength: this.props.maxLength,
      spellCheck: false,
      focus: this.state.isFocused,
      dir: this.props.dir,
      onFocus: this.focusInput,
      onBlur: this.onBlur,
      blurOnEnterKey: this.props.blurOnEnterKey,
      initialSelectionStart: this.props.initialSelectionStart,
      initialSelectionEnd: this.props.initialSelectionEnd,
      selectionActionCounter: this.props.selectionActionCounter,
    };
  },
  getTooltipId() {
    if (!this.uniqueTooltipId) {
      this.uniqueTooltipId = TEXT_INPUT_TOOLTIP_ERROR + _.uniqueId();
    }

    return this.uniqueTooltipId;
  },
  onValidationSuccess(value, result) {
    if (this.mounted) {
      this.successResultObject = result;
      this.setState({ validationStatus: this.ValidationStatus.SUCCESS });
    } else {
      util.valueLink.callOnChangeIfExists(
        this.props,
        value,
        this.successResultObject,
      );
    }
  },
  onValidationFailure() {
    if (this.mounted) {
      this.setState({ validationStatus: this.ValidationStatus.ERROR });
    }
  },
  validate(value, onSuccess, onFailure) {
    if (this.isValid(value)) {
      this.isAsyncValid(value, onSuccess, onFailure);
    } else {
      onFailure(this.getInvalidMessage());
    }
  },
  hideSuccessIndicator() {
    if (this.mounted) {
      this.setState({ validationStatus: this.ValidationStatus.NONE });
    }
  },
  onValueValid(value) {
    if (!this.props.alwaysShowValidationTooltip) {
      window.setTimeout(this.hideSuccessIndicator, SUCCESS_DURATION);
      tooltipManager.hide(this.getTooltipId());
    }
    this.lastValidValue = value;
    util.valueLink.callOnChangeIfExists(
      this.props,
      value,
      this.successResultObject,
    );
  },
  toggleTooltip() {
    const { isFocused } = this.state;
    const { validationStatus } = this.state;
    const tooltipId = this.getTooltipId();

    if (
      !isFocused &&
      (validationStatus === this.ValidationStatus.ERROR ||
        (validationStatus === this.ValidationStatus.SUCCESS &&
          !!this.props.alwaysShowValidationTooltip))
    ) {
      tooltipManager.show(tooltipId);
    } else {
      tooltipManager.hide(tooltipId);
    }
  },
  handleChange(value) {
    this.setState({ value });
  },
  getHtmlProps() {
    return _.omit(this.filteredProps(), [
      'initialSelectionStart',
      'initialSelectionEnd',
      'blurOnEscKey',
    ]);
  },
  render() {
    return (
      <div className={cx(this.getWrapperClasses())} {...this.getHtmlProps()}>
        {this.hasLabel() ? (
          <label key="textInputControlTitle" className="text-input-label">
            {this.translateIfNeeded(this.getLabel())}
          </label>
        ) : null}
        {this.hasLabel() && (this.props.infoText || this.props.infoTitle) ? (
          <InfoIcon
            key="infoIcon"
            text={this.props.infoText}
            title={this.props.infoTitle}
            size={18}
          />
        ) : null}
        {!this.props.isMultiLine ? (
          <TextInput key="input" {...this.getInputProps()} />
        ) : null}
        {this.props.isMultiLine ? (
          <TextInputMultiline key="input" {...this.getInputProps()} />
        ) : null}

        <Tooltip
          id={this.getTooltipId()}
          value={
            this.getTooltipMessage
              ? this.getTooltipMessage()
              : this.getValidationStatusMessage()
          }
          width="300px"
          styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
          shouldTranslate={this.props.shouldTranslate}
          openTriggers={[]}
          closeTriggers={[]}
        >
          <span className="validation-icon-wrapper">
            <span
              onClick={this.focusInput}
              className="validation-icon validation-icon-success"
            >
              <symbols.symbol name="inputValidationSuccess" />
            </span>

            <span
              onClick={this.focusInput}
              className="validation-icon validation-icon-error"
            >
              <symbols.symbol name="inputValidationError" />
            </span>

            <span className="validation-icon validation-icon-warning">
              <symbols.symbol name="inputValidationWarning" />
            </span>
          </span>
        </Tooltip>
      </div>
    );
  },
});
