@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

html {
  font-family: sans-serif; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }
  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

body {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

button {
  border-radius: 0; }

[data-z-counter] {
  z-index: 0; }

[data-z-counter='0'] {
  z-index: auto; }

._santa-preview-container_1boag_194 html,
._santa-preview-container_1boag_194 body,
._santa-preview-container_1boag_194 div,
._santa-preview-container_1boag_194 span,
._santa-preview-container_1boag_194 applet,
._santa-preview-container_1boag_194 object,
._santa-preview-container_1boag_194 iframe,
._santa-preview-container_1boag_194 h1,
._santa-preview-container_1boag_194 h2,
._santa-preview-container_1boag_194 h3,
._santa-preview-container_1boag_194 h4,
._santa-preview-container_1boag_194 h5,
._santa-preview-container_1boag_194 h6,
._santa-preview-container_1boag_194 p,
._santa-preview-container_1boag_194 blockquote,
._santa-preview-container_1boag_194 pre,
._santa-preview-container_1boag_194 a,
._santa-preview-container_1boag_194 abbr,
._santa-preview-container_1boag_194 acronym,
._santa-preview-container_1boag_194 address,
._santa-preview-container_1boag_194 big,
._santa-preview-container_1boag_194 cite,
._santa-preview-container_1boag_194 code,
._santa-preview-container_1boag_194 del,
._santa-preview-container_1boag_194 dfn,
._santa-preview-container_1boag_194 em,
._santa-preview-container_1boag_194 font,
._santa-preview-container_1boag_194 img,
._santa-preview-container_1boag_194 ins,
._santa-preview-container_1boag_194 kbd,
._santa-preview-container_1boag_194 q,
._santa-preview-container_1boag_194 s,
._santa-preview-container_1boag_194 samp,
._santa-preview-container_1boag_194 small,
._santa-preview-container_1boag_194 strike,
._santa-preview-container_1boag_194 strong,
._santa-preview-container_1boag_194 sub,
._santa-preview-container_1boag_194 sup,
._santa-preview-container_1boag_194 tt,
._santa-preview-container_1boag_194 var,
._santa-preview-container_1boag_194 b,
._santa-preview-container_1boag_194 u,
._santa-preview-container_1boag_194 i,
._santa-preview-container_1boag_194 center,
._santa-preview-container_1boag_194 dl,
._santa-preview-container_1boag_194 dt,
._santa-preview-container_1boag_194 dd,
._santa-preview-container_1boag_194 ol,
._santa-preview-container_1boag_194 ul,
._santa-preview-container_1boag_194 li,
._santa-preview-container_1boag_194 fieldset,
._santa-preview-container_1boag_194 form,
._santa-preview-container_1boag_194 label,
._santa-preview-container_1boag_194 legend,
._santa-preview-container_1boag_194 table,
._santa-preview-container_1boag_194 caption,
._santa-preview-container_1boag_194 tbody,
._santa-preview-container_1boag_194 tfoot,
._santa-preview-container_1boag_194 thead,
._santa-preview-container_1boag_194 tr,
._santa-preview-container_1boag_194 th,
._santa-preview-container_1boag_194 td,
._santa-preview-container_1boag_194 nav,
._santa-preview-container_1boag_194 button,
._santa-preview-container_1boag_194 section,
._santa-preview-container_1boag_194 header,
._santa-preview-container_1boag_194 footer,
._santa-preview-container_1boag_194 title {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

._sortable-list-item__dropzone_1boag_269 {
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--dropZoneSize) * 1px); }

._sortable-list-item__dropzone_1boag_269::before {
  display: none;
  content: '';
  position: absolute;
  left: calc(var(--nestingLevel) * 24px);
  right: 0;
  height: 2px;
  background: #116dff; }

._sortable-list-item__dropzone_after_1boag_284 {
  bottom: 0; }

._sortable-list-item__dropzone_before_1boag_287 {
  top: -2px; }

._sortable-list-item__dropzone_1boag_269:first-child ._sortable-list-item__dropzone_before_1boag_287 {
  top: 0; }

._sortable-list-item__dropzone_after_1boag_284::before {
  bottom: 0; }

._sortable-list-item__dropzone_before_1boag_287::before {
  top: 0; }

._sortable-list-item__dropzone_drop_1boag_299::before {
  display: block; }

._sortable-list-item_1boag_269._sortable-list-item_edit-state_1boag_302 {
  display: flex;
  align-items: center;
  background-color: #d6e6fe;
  padding: 6px 18px 6px 18px;
  height: 36px; }

._sortable-list-item_edit-state__input_1boag_309._input-container_1boag_309 {
  width: calc(100% - 36px); }

._sortable-list-item_edit-state__input_1boag_309._input-container_1boag_309 ._input_1boag_309 {
  background: #fff !important;
  height: 24px !important;
  font-size: 14px !important;
  color: #3b4057;
  border-radius: 4px; }

._sortable-list-item_edit-state__button_1boag_319 {
  width: 54px;
  flex: 0;
  margin: 0 0 0 6px !important;
  padding: 6px 24px !important; }

._sortable-list-item__suffix_1boag_325 {
  flex: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 6px; }
  ._sortable-list-item__suffix_1boag_325:hover {
    cursor: pointer; }

._sortable-list-item__context-menu-action_1boag_335 {
  display: flex; }

._sortable-list-item__context-menu-action-icon_1boag_338 {
  width: 20px;
  flex: content 0 0; }

._sortable-list-item__context-menu-action-text_1boag_342 {
  flex: 1; }

._sortable-list-item__context-menu-action-icon_1boag_338 > * {
  fill: #3b4057 !important;
  color: #3b4057 !important; }

._sortable-list-item__context-menu-options_1boag_349 ._dropdown-options_1boag_349 {
  width: unset !important; }

._sortable-list-item-ghost__layer-wrapper_1boag_352 {
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  position: fixed; }

._sortable-list-item__ghost-layer_1boag_359 {
  transform: translate3d(calc(var(--xOffset) * 1px), calc(var(--yOffset) * 1px), 0);
  width: calc(var(--itemWidth) * 1px); }

._sortable-list-item-ghost_1boag_352 {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20px [icon] minmax(0, 1fr) [label];
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 6px;
  background: rgba(231, 240, 255, 0.6);
  border-radius: 4px;
  box-shadow: 2px 2px 14px 0 rgba(0, 6, 36, 0.18); }

._sortable-list-item-ghost__icon_1boag_375 {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center; }
  ._sortable-list-item-ghost__icon_1boag_375 * {
    fill: #3b4057 !important;
    color: #3b4057 !important; }

._sortable-list-item-ghost__text_1boag_384 {
  padding-left: 6px;
  max-width: 100%; }

._sortable-list-item_1boag_269 {
  position: relative;
  height: 36px;
  background: #ffffff;
  padding-right: 6px;
  align-items: center;
  box-sizing: border-box; }
  ._sortable-list-item_1boag_269:hover {
    background-color: #e7f0ff; }
  ._sortable-list-item_selected_1boag_397 {
    background: #d6e6fe; }
    ._sortable-list-item_selected_1boag_397 ._sortable-list-item__prefix_1boag_399 * {
      fill: #116dff !important;
      color: #116dff !important; }
    ._sortable-list-item_selected_1boag_397 ._sortable-list-item__text_1boag_402 ._control-label_1boag_402 {
      color: #116dff !important; }
  ._sortable-list-item__context-menu_1boag_335, ._sortable-list-item__custom-button_1boag_404 {
    margin: 6px; }
  ._sortable-list-item_1boag_269:not(:hover) ._sortable-list-item__context-menu_1boag_335 {
    visibility: hidden; }
  ._sortable-list-item_1boag_269:not(:hover) ._sortable-list-item__custom-button_1boag_404 {
    visibility: hidden; }
  ._sortable-list-item_1boag_269:not(:hover) ._sortable-list-item__drag-indicator_1boag_410 {
    visibility: hidden; }

._sortable-list-item__view_1boag_413 {
  cursor: move;
  display: grid;
  grid-template-columns: 6px [drag-indicator] 18px [arrow] calc( 20px + var(--nestingLevel) * 17px) [icon] minmax(0, 1fr) [label] -webkit-min-content [icons] -webkit-min-content [context-menu];
  grid-template-columns: 6px [drag-indicator] 18px [arrow] calc( 20px + var(--nestingLevel) * 17px) [icon] minmax(0, 1fr) [label] min-content [icons] min-content [context-menu];
  grid-template-rows: 36px;
  align-items: center;
  justify-items: end;
  padding-left: 6px;
  width: 100%; }
  ._sortable-list-item__view_1boag_413 ._control-label_1boag_402 {
    cursor: move; }

._sortable-list-item__prefix_1boag_399 {
  position: relative;
  height: 100%;
  justify-self: end;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px; }
  ._sortable-list-item__prefix_1boag_399 * {
    fill: #3b4057 !important;
    color: #3b4057 !important; }

._sortable-list-item__drag-indicator_1boag_410 {
  display: flex; }

._sortable-list-item__text_1boag_402 {
  justify-self: start;
  padding-left: 6px;
  max-width: 100%; }

._sortable-list-item__nesting-line_1boag_446 {
  position: relative;
  align-self: stretch;
  justify-self: stretch;
  left: calc(var(--nestingLevel) * 18px - 18px); }
  ._sortable-list-item__nesting-line_1boag_446::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    left: 10px;
    border-left: 1px solid #dfe5eb; }
    ._sortable-list-item_last_1boag_459 ._sortable-list-item__nesting-line_1boag_446::after {
      bottom: 50%;
      border-left: 1px solid #dfe5eb;
      border-bottom: 1px solid #dfe5eb; }
  ._sortable-list-item__nesting-line_1boag_446._sortable-list-item__nesting-line__sub_1boag_463::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    left: -16px;
    border-right: 1px solid #dfe5eb; }
    ._sortable-list-item_last-upperlevel_1boag_471 ._sortable-list-item__nesting-line_1boag_446._sortable-list-item__nesting-line__sub_1boag_463::before {
      bottom: 50%;
      border-left: 1px solid #dfe5eb;
      border-bottom: 1px solid #dfe5eb;
      border-right: none;
      left: -8px; }

._sortable-list-item_drop-on_1boag_478 {
  box-shadow: inset 0 0 0 2px #116dff; }

._sortable-list-item_dragging_1boag_481 {
  background-color: #e7f0ff;
  box-shadow: none !important;
  cursor: grabbing !important;
  color: #f00 !important; }
  ._sortable-list-item_dragging_1boag_481 > *:not(._sortable-list-item__nesting-line_1boag_446) {
    opacity: 0; }

._sortable-list-item__arrow_1boag_489:not(._sortable-list-item__nesting-line_1boag_446) {
  width: 16px;
  height: 16px;
  display: none; }

._sortable-list-item_with-arrow_1boag_494 ._sortable-list-item__arrow_1boag_489:not(._sortable-list-item__nesting-line_1boag_446) {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out; }

._sortable-list-item_with-arrow_1boag_494 ._sortable-list-item__arrow_1boag_489::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #868aa5;
  position: absolute;
  top: calc(50% - 4px);
  left: 20px;
  cursor: pointer; }

._sortable-list-item_with-arrow_1boag_494 ._sortable-list-item__arrow_1boag_489._sortable-list-item__nesting-line_1boag_446::before {
  left: 26px; }

._sortable-list-item_with-arrow_1boag_494 ._sortable-list-item__arrow_1boag_489._sortable-list-item__arrow_closed_1boag_516::before {
  transform: rotate(90deg); }

._sortable-list-item_block-dnd_1boag_519 ._sortable-list-item__view_1boag_413 ._control-label_1boag_402 {
  cursor: unset; }

._sortable-list-item_block-dnd_1boag_519 ._sortable-list-item__view_1boag_413 {
  cursor: unset; }

._sortable-list-item_block-dnd_1boag_519 ._sortable-list-item__drag-indicator_1boag_410 {
  visibility: hidden; }

._sortable-list__items-list_1boag_528 {
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  width: 100%; }

._sortable-list__dropzone_before_1boag_537,
._sortable-list__dropzone_after_1boag_538 {
  min-height: 12px; }

._sortable-list__dropzone_after_1boag_538 {
  flex-grow: 1; }

._control-button_1boag_544 ._symbol-with-label_1boag_544 {
  margin-right: 8px; }

._button-wrapper-center_1boag_547 {
  text-align: center;
  margin-bottom: 15px; }

._control-button2_1boag_551 {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center; }
  ._btn-back_1boag_559._control-button2_1boag_551 {
    min-width: 67px;
    height: 21px;
    background-color: #e7f0ff; }
    ._btn-back_1boag_559._control-button2_1boag_551:hover {
      background-color: #d6e6fe; }
  ._default_1boag_565._control-button2_1boag_551, ._btn-confirm-primary_1boag_565._control-button2_1boag_551 {
    background-color: #116dff;
    color: #fff; }
    ._default_1boag_565._control-button2_1boag_551:hover, ._btn-confirm-primary_1boag_565._control-button2_1boag_551:hover {
      background-color: #5999ff; }
    ._default_1boag_565._control-button2_1boag_551:active, ._btn-confirm-primary_1boag_565._control-button2_1boag_551:active {
      background-color: #116dff; }
  ._btn-confirm-primary_1boag_565._control-button2_1boag_551 {
    color: #fff; }
  ._default_1boag_565._control-button2_1boag_551 {
    height: 36px;
    border-radius: 18px; }
    ._default_1boag_565._control-button2_1boag_551 ._symbol_1boag_544:nth-child(2):nth-last-child(2) {
      margin-left: 8px; }
  ._btn-confirm-secondary_1boag_579._control-button2_1boag_551 {
    background-color: #e7f0ff;
    color: #116dff; }
    ._btn-confirm-secondary_1boag_579._control-button2_1boag_551:hover {
      background-color: #d6e6fe; }
    ._btn-confirm-secondary_1boag_579._control-button2_1boag_551:active {
      background-color: #e7f0ff; }
  ._btn-md_1boag_586._control-button2_1boag_551, ._btn-sm_1boag_586._control-button2_1boag_551 {
    padding: 0 18px;
    border-radius: 15px; }
  ._btn-md_1boag_586._control-button2_1boag_551 {
    height: 30px; }
  ._btn-sm_1boag_586._control-button2_1boag_551 {
    height: 24px; }
  ._btn-text_1boag_593._control-button2_1boag_551 {
    background: 0;
    font-size: 18px;
    color: #116dff; }
  ._btn-danger-secondary_1boag_597._control-button2_1boag_551 {
    background-color: #fde3e1;
    color: #e62214; }
    ._btn-danger-secondary_1boag_597._control-button2_1boag_551:hover {
      background-color: #fbd0cd; }
    ._btn-danger-secondary_1boag_597._control-button2_1boag_551:active {
      background-color: #fde3e1; }
  ._btn-danger-primary_1boag_604._control-button2_1boag_551 {
    background-color: #e62214;
    color: #fff; }
    ._btn-danger-primary_1boag_604._control-button2_1boag_551:hover {
      background-color: #ff6d63; }
    ._btn-danger-primary_1boag_604._control-button2_1boag_551:active {
      background-color: #e62214; }
  ._control-button2_1boag_551[disabled] {
    cursor: default; }
    ._default_1boag_565._control-button2_1boag_551[disabled] {
      background-color: #bcbcbc; }
  ._control-button2_1boag_551 > input {
    display: none; }
  ._control-button2_1boag_551 > ._label_1boag_617,
  ._control-button2_1boag_551 > ._symbol_1boag_544 {
    display: inline-block; }
  ._control-button2_1boag_551 > ._label_1boag_617 {
    font-size: initial; }
    ._btn-back_1boag_559._control-button2_1boag_551 > ._label_1boag_617 {
      color: #116dff;
      font-size: 13px; }
    ._default_1boag_565._control-button2_1boag_551 > ._label_1boag_617 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      padding: 0 24px;
      color: #fff; }
    ._btn-text_1boag_593._control-button2_1boag_551 > ._label_1boag_617 {
      text-decoration: underline; }

._classic-facelift-skin_1boag_636 ._control-button2_1boag_551._default_1boag_565 > ._label_1boag_617 {
  font-family: var(--wbu-font-stack); }

._file-input-control_1boag_639 {
  margin: 0;
  padding: 0;
  cursor: pointer; }
  ._file-input-control_1boag_639._button_1boag_547 {
    box-sizing: border-box;
    height: 30px;
    border-radius: 18px;
    background-color: #fe620f;
    color: #fff;
    margin-left: auto;
    padding-left: 22px; }
    ._file-input-control_1boag_639._button_1boag_547:hover {
      background-color: #ff7d38; }
    ._file-input-control_1boag_639._button_1boag_547:active {
      background-color: #fe620f; }
    ._file-input-control_1boag_639._button_1boag_547[disabled] {
      cursor: default;
      background-color: #bcbcbc; }
    ._file-input-control_1boag_639._button_1boag_547 > ._label_1boag_617,
    ._file-input-control_1boag_639._button_1boag_547 > ._symbol_1boag_544 {
      display: inline-block;
      vertical-align: middle;
      margin-top: 4px; }
    ._file-input-control_1boag_639._button_1boag_547 > ._label_1boag_617 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      padding: 0 24px 0 11px;
      color: #fff; }
  ._file-input-control_1boag_639._text_1boag_674 > ._label_1boag_617 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff; }
  ._file-input-control_1boag_639 > input {
    display: none; }

._input-date-picker_1boag_684 {
  position: relative;
  background-color: #fff;
  border-radius: 4px; }
  ._input-date-picker_1boag_684 ._dp-header_1boag_688 {
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    box-shadow: 0 1px 3.96px 0.04px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #f7f8f8; }
    ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-previous_1boag_699 {
      transform: rotate(-180deg); }
    ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-navigate_1boag_701 {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-navigate_1boag_701:hover {
        background-color: #e7f0ff; }
      ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-navigate_1boag_701:active {
        background-color: #116dff; }
        ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-navigate_1boag_701:active * {
          fill: #fff; }
    ._input-date-picker_1boag_684 ._dp-header_1boag_688 ._dp-title_1boag_715 {
      flex: 1;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #116dff; }
  ._input-date-picker_1boag_684 ._dp-body_1boag_720 table {
    text-align: center;
    width: calc(100% - 24px);
    margin: 8px 12px;
    font-size: 13px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #3b4057; }
    ._input-date-picker_1boag_684 ._dp-body_1boag_720 table td div {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px; }
    ._input-date-picker_1boag_684 ._dp-body_1boag_720 table ._dp-day-name_1boag_732 {
      font-weight: bold; }
  ._input-date-picker_1boag_684 ._dp-day_1boag_732 {
    cursor: pointer; }
    ._input-date-picker_1boag_684 ._dp-day_1boag_732:not(._dp-day-selected_1boag_736):hover {
      background-color: #e7f0ff; }
  ._input-date-picker_1boag_684 ._dp-day-not-in-current-month_1boag_738 {
    color: #dfe5eb; }
  ._input-date-picker_1boag_684 ._dp-day-selected_1boag_736,
  ._input-date-picker_1boag_684 ._dp-day-selected_1boag_736:hover {
    background-color: #116dff;
    color: #fff; }
  ._input-date-picker_1boag_684 ._dp-today_1boag_744:not(._dp-day-not-in-current-month_1boag_738) {
    border: 1px solid #116dff; }
  ._input-date-picker_1boag_684 ._dp-footer_1boag_746 {
    border-top: 1px solid #f7f8f8;
    height: 32px;
    padding: 12px; }
    ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._delete-btn_1boag_750 {
      background-color: #fff;
      padding: 0 12px;
      margin-right: 12px;
      height: 32px;
      vertical-align: middle; }
      ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._delete-btn_1boag_750 path {
        fill: #3b4057; }
      ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._delete-btn_1boag_750:hover path {
        fill: #e62214; }
    ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-today_1boag_760,
    ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-selection_1boag_761 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      background-color: transparent;
      color: #116dff;
      display: inline-block;
      border-radius: 32px;
      line-height: 32px;
      height: 32px;
      padding: 0 20px;
      cursor: pointer; }
      ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-today_1boag_760:hover,
      ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-selection_1boag_761:hover {
        background-color: #e7f0ff; }
    ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-selection_1boag_761 {
      float: right; }
      ._input-date-picker_1boag_684 ._dp-footer_1boag_746 ._goto-selection_1boag_761:disabled {
        cursor: inherit;
        color: #bcbcbc;
        background-color: transparent; }

._control-angle_1boag_785 {
  position: relative;
  height: 108px; }
  ._control-angle_1boag_785 > ._disabled_1boag_788 {
    opacity: 0.4; }
  ._control-angle_1boag_785 > ._circle_1boag_790 {
    width: 72px;
    height: 72px;
    top: 32px;
    left: 48px;
    border-radius: 50%;
    border: solid 4px #d6e1e8;
    position: absolute;
    box-sizing: border-box; }
    ._control-angle_1boag_785 > ._circle_1boag_790:hover > ._knob_1boag_799 {
      background-color: #86ccf7; }
    ._control-angle_1boag_785 > ._circle_1boag_790:active > ._circle-center_1boag_801,
    ._control-angle_1boag_785 > ._circle_1boag_790:active > ._knob_1boag_799 {
      background-color: #0199ef; }
    ._control-angle_1boag_785 > ._circle_1boag_790:active:after {
      border: solid 1px #0199ef; }
    ._control-angle_1boag_785 > ._circle_1boag_790:hover > ._knob_1boag_799, ._control-angle_1boag_785 > ._circle_1boag_790:active > ._knob_1boag_799 {
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3), 0 0 8px 1px #86ccf7; }
    ._control-angle_1boag_785 > ._circle_1boag_790 > ._circle-center_1boag_801 {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      background-color: #d6e1e8;
      top: calc(50% - 3px);
      left: calc(50% - 3px); }
    ._control-angle_1boag_785 > ._circle_1boag_790:after {
      width: 0;
      height: 32px;
      content: '';
      position: absolute;
      border: solid 1px #d6e1e8;
      z-index: -1;
      left: 31px; }
    ._control-angle_1boag_785 > ._circle_1boag_790 ._knob_1boag_799 {
      width: 8px;
      height: 8px;
      position: absolute;
      cursor: pointer;
      background-color: #116dff;
      border-radius: 50%;
      border: solid 4px #fff;
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3);
      box-sizing: border-box;
      top: -5px;
      left: calc(50% - 4px); }
  ._control-angle_1boag_785 > ._control-label_1boag_402 {
    width: 54px;
    color: #3b4057;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  ._animation-panel_1boag_841 ._control-angle_1boag_785 {
    margin: 17px 10px 7px 24px;
    height: 96px; }
    ._animation-panel_1boag_841 ._control-angle_1boag_785 ._input-stepper_1boag_844 {
      left: auto;
      top: 28px;
      right: 0;
      width: 56px;
      padding: 0 6px; }
    ._animation-panel_1boag_841 ._control-angle_1boag_785 ._circle_1boag_790 {
      top: 18px;
      left: 80px; }

._control-thumbnails_1boag_854 {
  position: relative;
  padding: 13px 24px 16px;
  white-space: initial; }
  ._control-thumbnails_1boag_854,
  ._control-thumbnails_1boag_854 * {
    box-sizing: border-box;
    font-size: 0; }
  ._control-thumbnails_1boag_854 ._radio-control_1boag_862 {
    position: relative;
    display: inline-block;
    margin: 0 12px 12px 0;
    vertical-align: top; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._svg-radio_1boag_867 {
      position: relative;
      width: 100%;
      background-color: #e7f0ff;
      display: inline-block;
      border-radius: 4px;
      transition: background-color 0.2s; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._svg-radio_1boag_867:hover {
        background-color: #d6e6fe; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._svg-radio_1boag_867._disabled_1boag_788 {
        background-color: #f0f0f0; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._image-radio_1boag_878 {
      position: relative;
      overflow: hidden;
      width: 100%;
      border: 1px solid #dfe5eb;
      display: inline-block;
      border-radius: 4px; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._image-radio_1boag_878 ._image-radio-src_1boag_885 {
        position: relative;
        display: block;
        top: -1px;
        left: -1px; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862:hover ._image-radio_1boag_878 {
      border: 2px solid #80b1ff; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862:hover ._image-radio_1boag_878 ._image-radio-src_1boag_885 {
        top: -2px;
        left: -2px; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._svg-radio_1boag_867 {
      background-color: #116dff; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._svg-radio_1boag_867._symbol-thumb-none_1boag_897, ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._svg-radio_1boag_867._symbol-transition-none_1boag_897 {
        background-color: #e7f0ff; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._svg-radio_1boag_867 ._cls-5_1boag_899._cls-5_1boag_899 {
        fill: #e7f0ff; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._image-radio_1boag_878 {
      border: 2px solid #116dff; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 input:checked + ._image-radio_1boag_878 ._image-radio-src_1boag_885 {
        top: -2px;
        left: -2px; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._label_1boag_617 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      display: block;
      text-align: center;
      color: #116dff;
      padding-top: 9px; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._label_1boag_617._disabled_1boag_788 {
        color: #bcbcbc; }
    ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._notify-symbol_1boag_918 {
      position: absolute; }
      ._control-thumbnails_1boag_854 ._radio-control_1boag_862 ._notify-symbol_1boag_918._notify-symbol-small-right_1boag_920 {
        right: -8px;
        top: -8px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='0'] ._radio-control_1boag_862 {
    width: 114px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='0'] ._radio-control_1boag_862:nth-of-type(2n) {
      margin-right: 0px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='0'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='0'] input + ._image-radio_1boag_878 {
    height: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='0']._with-title_1boag_929 span:last-of-type {
    max-width: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='1'] ._radio-control_1boag_862 {
    width: 114px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='1'] ._radio-control_1boag_862:nth-of-type(1n) {
      margin-right: 126px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='1'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='1'] input + ._image-radio_1boag_878 {
    height: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='1']._with-title_1boag_929 span:last-of-type {
    max-width: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='2'] ._radio-control_1boag_862 {
    width: 114px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='2'] ._radio-control_1boag_862:nth-of-type(0n) {
      margin-right: 252px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='2'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='2'] input + ._image-radio_1boag_878 {
    height: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='2'][data-items-to-skip='2']._with-title_1boag_929 span:last-of-type {
    max-width: 114px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='0'] ._radio-control_1boag_862 {
    width: 72px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='0'] ._radio-control_1boag_862:nth-of-type(3n) {
      margin-right: 0px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='0'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='0'] input + ._image-radio_1boag_878 {
    height: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='0']._with-title_1boag_929 span:last-of-type {
    max-width: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='1'] ._radio-control_1boag_862 {
    width: 72px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='1'] ._radio-control_1boag_862:nth-of-type(2n) {
      margin-right: 84px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='1'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='1'] input + ._image-radio_1boag_878 {
    height: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='1']._with-title_1boag_929 span:last-of-type {
    max-width: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='2'] ._radio-control_1boag_862 {
    width: 72px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='2'] ._radio-control_1boag_862:nth-of-type(1n) {
      margin-right: 168px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='2'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='2'] input + ._image-radio_1boag_878 {
    height: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='3'][data-items-to-skip='2']._with-title_1boag_929 span:last-of-type {
    max-width: 72px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='0'] ._radio-control_1boag_862 {
    width: 51px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='0'] ._radio-control_1boag_862:nth-of-type(4n) {
      margin-right: 0px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='0'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='0'] input + ._image-radio_1boag_878 {
    height: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='0']._with-title_1boag_929 span:last-of-type {
    max-width: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='1'] ._radio-control_1boag_862 {
    width: 51px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='1'] ._radio-control_1boag_862:nth-of-type(3n) {
      margin-right: 63px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='1'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='1'] input + ._image-radio_1boag_878 {
    height: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='1']._with-title_1boag_929 span:last-of-type {
    max-width: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='2'] ._radio-control_1boag_862 {
    width: 51px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='2'] ._radio-control_1boag_862:nth-of-type(2n) {
      margin-right: 126px; }
  ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='2'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='2'] input + ._image-radio_1boag_878 {
    height: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='4'][data-items-to-skip='2']._with-title_1boag_929 span:last-of-type {
    max-width: 51px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] ._radio-control_1boag_862 {
    width: 38.4px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] ._radio-control_1boag_862:nth-of-type(5n) {
      margin-right: 0px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + ._svg-radio_1boag_867,
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + ._image-radio_1boag_878 {
    border-radius: 6px; }
    ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0'] input + ._image-radio_1boag_878 {
      height: 38.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='0']._with-title_1boag_929 span:last-of-type {
    max-width: 38.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] ._radio-control_1boag_862 {
    width: 38.4px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] ._radio-control_1boag_862:nth-of-type(4n) {
      margin-right: 50.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + ._svg-radio_1boag_867,
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + ._image-radio_1boag_878 {
    border-radius: 6px; }
    ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1'] input + ._image-radio_1boag_878 {
      height: 38.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='1']._with-title_1boag_929 span:last-of-type {
    max-width: 38.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] ._radio-control_1boag_862 {
    width: 38.4px; }
    ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] ._radio-control_1boag_862:nth-of-type(3n) {
      margin-right: 100.8px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + ._svg-radio_1boag_867,
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + ._image-radio_1boag_878 {
    border-radius: 6px; }
    ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + ._svg-radio_1boag_867, ._square_1boag_927._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2'] input + ._image-radio_1boag_878 {
      height: 38.4px; }
  ._control-thumbnails_1boag_854[data-max-thumbs-per-row='5'][data-items-to-skip='2']._with-title_1boag_929 span:last-of-type {
    max-width: 38.4px; }
  ._control-thumbnails_1boag_854._with-title_1boag_929 > div {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #3b4057;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px; }
  ._control-thumbnails_1boag_854 ._info-icon_1boag_1039 {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  ._control-thumbnails_1boag_854:hover ._info-icon_1boag_1039 {
    opacity: 1; }

._thumbnails-with-icons_1boag_1049 ._control-header_1boag_1049 {
  display: flex; }
  ._thumbnails-with-icons_1boag_1049 ._control-header_1boag_1049 ._thumbnails-with-icons-label_1boag_1051 {
    flex: 1; }
  ._thumbnails-with-icons_1boag_1049 ._control-header_1boag_1049 ._info-icon_1boag_1039 {
    opacity: 0;
    float: right;
    position: relative;
    transition-property: opacity;
    transition-duration: 0.15s; }

._thumbnails-with-icons_1boag_1049:hover ._control-header_1boag_1049 ._info-icon_1boag_1039 {
  opacity: 1; }

._thumbnails-with-icons_1boag_1049 ._thumbnails-with-icons-label_1boag_1051,
._thumbnails-with-icons_1boag_1049 ._option-title_1boag_1064 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057;
  text-overflow: ellipsis;
  overflow: hidden; }

._thumbnails-with-icons_1boag_1049 ._thumbnails-with-icons-label_1boag_1051 {
  margin-bottom: 12px; }

._thumbnails-with-icons_1boag_1049 label {
  display: inline-block;
  cursor: pointer;
  text-align: center; }
  ._thumbnails-with-icons_1boag_1049 label:first-of-type {
    margin-right: 12px; }
  ._thumbnails-with-icons_1boag_1049 label svg {
    max-width: 62px;
    max-height: 62px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

._thumbnails-with-icons_1boag_1049 input {
  display: none; }
  ._thumbnails-with-icons_1boag_1049 input + span {
    padding-bottom: 18px;
    display: inline-block;
    border-radius: 4px;
    width: 114px;
    box-shadow: inset 0 0 0 1px #dfe5eb;
    font-size: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; }
  ._thumbnails-with-icons_1boag_1049 input:checked + span {
    box-shadow: inset 0 0 0 2px #116dff; }
    ._thumbnails-with-icons_1boag_1049 input:checked + span ._option-title_1boag_1064 {
      color: #116dff; }
  ._thumbnails-with-icons_1boag_1049 input:not(:checked) + span:hover {
    box-shadow: inset 0 0 0 2px #d6e6fe; }

._thumbnails-with-icons_1boag_1049 ._image-container_1boag_1110 {
  display: block;
  width: 78px;
  height: 83px;
  border-bottom: 1px solid #dfe5eb;
  margin: 0 auto 12px auto;
  position: relative;
  box-sizing: border-box; }

._thumbnails-vertical_1boag_1119 {
  width: 100%;
  text-align: center;
  margin: 24px 0; }
  ._thumbnails-vertical_1boag_1119 label {
    display: block;
    margin: 12px 0;
    line-height: 0; }
  ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127 {
    cursor: pointer;
    box-shadow: 0 0 0 1px #dfe5eb inset;
    border-radius: 6px;
    display: inline-block;
    width: calc(100% - 48px);
    line-height: 0;
    overflow: hidden; }
    ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127 img {
      margin: 20px 24px 13px 25px; }
      ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127 img._withoutLabel_1boag_1137 {
        margin-bottom: 20px; }
    ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127 span {
      margin: 0 0 12px 0;
      line-height: 14px; }
    ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127._right_1boag_1142 {
      text-align: right; }
    ._thumbnails-vertical_1boag_1119 ._thumbnail-container_1boag_1127._left_1boag_1144 {
      text-align: left; }
  ._thumbnails-vertical_1boag_1119 ._thumbnail-title_1boag_1146 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    display: inline-block;
    color: #3b4057; }
  ._thumbnails-vertical_1boag_1119 input {
    display: none; }
    ._thumbnails-vertical_1boag_1119 input:checked + ._thumbnail-container_1boag_1127 {
      box-shadow: 0 0 0 2px #116dff inset; }
      ._thumbnails-vertical_1boag_1119 input:checked + ._thumbnail-container_1boag_1127 ._thumbnail-title_1boag_1146 {
        color: #116dff; }
    ._thumbnails-vertical_1boag_1119 input:not(:checked) + ._thumbnail-container_1boag_1127:hover {
      box-shadow: 0 0 0 2px #d6e6fe inset; }

._control-mandatory_1boag_1164 {
  position: relative;
  cursor: pointer; }
  ._control-mandatory_1boag_1164:after {
    content: attr(data-text);
    display: inline-block;
    margin-left: 13px;
    position: absolute;
    white-space: nowrap;
    line-height: 18px; }
  ._control-mandatory_1boag_1164 input {
    display: none; }
    ._control-mandatory_1boag_1164 input + span {
      width: 16px;
      height: 16px;
      border: solid 1px #116dff;
      display: inline-block;
      border-radius: 50%;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      position: relative;
      background-color: #f7f8f8; }
      ._control-mandatory_1boag_1164 input + span:before {
        content: '*';
        color: #b6c1cd;
        font-size: 30px;
        position: absolute;
        top: 4.5px;
        left: 2.7px;
        line-height: 18px; }
    ._control-mandatory_1boag_1164 input:checked + span {
      background-color: #f7f8f8; }
      ._control-mandatory_1boag_1164 input:checked + span:before {
        color: #116dff; }
    ._control-mandatory_1boag_1164 input:disabled + span {
      border-color: #bcbcbc; }
      ._control-mandatory_1boag_1164 input:disabled + span:before {
        color: #bcbcbc; }
  ._control-mandatory_1boag_1164:hover input + span {
    background-color: #fff; }
    ._control-mandatory_1boag_1164:hover input + span:before {
      color: #b6c1cd; }
  ._control-mandatory_1boag_1164:hover input:checked + span {
    background-color: #e7f0ff; }
    ._control-mandatory_1boag_1164:hover input:checked + span:before {
      color: #116dff; }
  ._control-mandatory_1boag_1164:hover input:disabled + span {
    border-color: #bcbcbc;
    background-color: #f7f8f8; }
    ._control-mandatory_1boag_1164:hover input:disabled + span:before {
      color: #bcbcbc; }

._control-buttons-group_1boag_1216 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  color: #3b4057;
  position: relative; }
  ._control-buttons-group_1boag_1216 p {
    font-size: 14px;
    color: #3b4057;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 0; }
  ._control-buttons-group_1boag_1216 ._group-buttons-container-left_1boag_1231 {
    text-align: left; }
  ._control-buttons-group_1boag_1216 ._group-buttons-container-right_1boag_1233 {
    text-align: right; }
  ._control-buttons-group_1boag_1216 ._group-buttons-container-center_1boag_1235 {
    text-align: center; }
  ._control-buttons-group_1boag_1216 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  ._control-buttons-group_1boag_1216:hover ._info-icon_1boag_1039 {
    display: inline-block; }
  ._control-buttons-group_1boag_1216 input {
    display: none; }
    ._control-buttons-group_1boag_1216 input + span {
      color: #116dff;
      cursor: pointer;
      height: 36px;
      background-color: #edf7ff;
      display: inline-block;
      position: relative;
      white-space: nowrap;
      line-height: 36px;
      vertical-align: middle;
      font-size: 14px;
      padding: 0 14px;
      max-width: 93px;
      min-width: 74px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      box-shadow: 0 2px 0 0 #d4e7fb; }
    ._control-buttons-group_1boag_1216 input:checked + span {
      color: #fff;
      position: relative;
      top: 2px;
      background-color: #116dff;
      box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.11); }
    ._control-buttons-group_1boag_1216 input:disabled + span {
      cursor: default;
      background-color: #f6f6f6; }
    ._control-buttons-group_1boag_1216 input:disabled:checked + span {
      background-color: #bcbcbc; }
    ._control-buttons-group_1boag_1216 input:disabled:not(:checked) + span {
      color: #bcbcbc;
      box-shadow: 0 2px 0 0 #e5e5e5; }
  ._control-buttons-group_1boag_1216 label {
    display: inline-block; }
    ._control-buttons-group_1boag_1216 label input + span:hover {
      background-color: #d6e6fe; }
    ._control-buttons-group_1boag_1216 label:first-of-type span {
      border-radius: 20px 0 0 20px; }
    ._control-buttons-group_1boag_1216 label:last-of-type span {
      border-radius: 0 20px 20px 0; }
  ._animation-panel_1boag_841 ._control-buttons-group_1boag_1216 {
    text-align: center; }

._control-tabs-horizontal_1boag_1289 {
  box-sizing: border-box;
  font-size: 0;
  width: 100%; }
  ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 {
    height: 59px;
    background-color: #fff; }
  ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05); }
  ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 {
    margin: 18px 0;
    width: 240px;
    counter-reset: boxes 0; }
  ._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1),
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1) ~ label, ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1),
    ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1) ~ label {
      width: 100%; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2),
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2) ~ label, ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2),
    ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2) ~ label {
      width: 50%; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3),
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3) ~ label, ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3),
    ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3) ~ label {
      width: 33.33333%; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4),
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4) ~ label, ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4),
    ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4) ~ label {
      width: 25%; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(5),
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(5) ~ label, ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(5),
    ._horizontal_1boag_1308._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(5) ~ label {
      width: 20%; }
    ._vertical_1boag_1327._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 {
      display: block; }
    ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 {
      vertical-align: middle; }
    ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 {
      height: 52px; }
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1),
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(1) ~ label {
        width: 100%; }
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2),
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(2) ~ label {
        width: 50%; }
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3),
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(3) ~ label {
        width: 33.33333%; }
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4),
      ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child:nth-last-child(4) ~ label {
        width: 25%; }
    ._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 {
      display: none; }
      ._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
        font-size: 14px;
        box-sizing: border-box;
        transition-duration: 0.2s;
        position: relative; }
        ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
          height: 47px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 10px;
          background-color: #e7f0ff;
          border-right: solid 1px #d6e6fe;
          transition-property: background-color, border-color; }
          ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309:checked + ._tab-decorator_1boag_1347 {
            background-color: #fff;
            border-color: transparent; }
          ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
            border-right: none; }
        ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347, ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
          color: #3b4057; }
        ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347, ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
          display: block; }
        ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
          transition-property: border-color; }
          ._horizontal_1boag_1308._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
            border-bottom: solid 3px #116dff;
            padding-right: 3px;
            padding-left: 3px;
            height: 84px; }
          ._vertical_1boag_1327._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
            border-right: solid 3px #116dff;
            padding-right: 12px;
            height: calc( 42px + 3px * 2);
            width: calc( 132px + 12px + 3px); }
            ._vertical_1boag_1327._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
              height: 42px; }
        ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
          height: 52px;
          padding: 0 6px;
          border-bottom: solid 3px transparent;
          transition-property: border-color; }
          ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:hover > ._input_1boag_309 + ._tab-decorator_1boag_1347 {
            border-bottom-color: #80b1ff; }
          ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309:checked + ._tab-decorator_1boag_1347 {
            border-bottom-color: #116dff;
            color: #3b4057; }
        ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347:hover {
          background-color: #d6e6fe; }
        ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347:after, ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347:after {
          content: '';
          width: 0;
          height: 0;
          border: solid;
          border-color: transparent transparent #116dff;
          border-width: 0 9px 7px;
          position: absolute;
          bottom: -3px;
          left: calc(50% - 9px);
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.2s; }
          ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309:checked + ._tab-decorator_1boag_1347:after, ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309:checked + ._tab-decorator_1boag_1347:after {
            opacity: 1; }
        ._vertical_1boag_1327._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347:after {
          border-color: transparent transparent transparent #116dff;
          border-width: 9px 0 9px 7px;
          left: calc( 132px + 12px);
          bottom: calc(50% - 3px * 2); }
          ._vertical_1boag_1327._control-tabs-horizontal_1boag_1289:last-child > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347:after {
            bottom: calc(50% - 9px); }
        ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
          display: inline-flex;
          align-items: center;
          height: 49px;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #116dff;
          transition-property: color;
          transition-duration: 0.2s; }
          ._arrowed_1boag_1296._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:hover > ._input_1boag_309:not(:checked) + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            color: #3b4057; }
        ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
          background-color: #e7f0ff;
          display: inline-block;
          width: 100%;
          vertical-align: middle;
          text-align: center;
          transition-property: background-color;
          transition-duration: 0.2s; }
          ._horizontal_1boag_1308._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            height: 48px; }
          ._vertical_1boag_1327._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            height: 42px; }
          ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            border-top-left-radius: 6px; }
            ._horizontal_1boag_1308._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
              border-bottom-left-radius: 6px; }
            ._vertical_1boag_1327._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:first-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
              border-top-right-radius: 6px; }
          ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            border-bottom-right-radius: 6px; }
            ._horizontal_1boag_1308._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
              border-top-right-radius: 6px; }
            ._vertical_1boag_1327._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
              border-bottom-left-radius: 6px; }
          ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309:checked + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            background-color: #116dff; }
          ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:hover > ._input_1boag_309:not(:checked) + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
            background-color: #d6e6fe; }
        ._tabbed_1boag_1293._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302:last-child > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418 {
          border-right: none; }
        ._arrowed-box_1boag_1298._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418:after {
          content: counter(boxes, decimal);
          counter-increment: boxes 1;
          position: absolute;
          bottom: 15px;
          font-size: 12px;
          color: #116dff;
          text-align: center;
          left: calc(50% - 3px); }
        ._vertical_1boag_1327._control-tabs-horizontal_1boag_1289 > ._container_1boag_1302 > ._input_1boag_309 + ._tab-decorator_1boag_1347 > ._text-label_1boag_1418:after {
          left: calc( 132px + 12px + 3px + 7px + 7px);
          bottom: calc(50% - 3px * 2); }

._control-tabs-vertical_1boag_1473 {
  display: block;
  font-size: 0;
  width: 48px;
  height: 100%;
  background-color: #e7f0ff;
  box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d6e6fe; }
  ._control-tabs-vertical_1boag_1473 ._tab-text_1boag_1480 {
    display: none; }
  ._control-tabs-vertical_1boag_1473._closed_1boag_1482 {
    width: 288px;
    background-color: transparent;
    box-shadow: none; }
    ._control-tabs-vertical_1boag_1473._closed_1boag_1482 svg {
      left: 17px; }
    ._control-tabs-vertical_1boag_1473._closed_1boag_1482 ._tab-text_1boag_1480 {
      display: inline-block; }
  ._control-tabs-vertical_1boag_1473 label {
    cursor: pointer;
    display: block; }
  ._control-tabs-vertical_1boag_1473 input {
    display: none; }
    ._control-tabs-vertical_1boag_1473 input + span {
      display: inline-block;
      width: 100%;
      height: 48px;
      position: relative;
      line-height: 48px; }
      ._control-tabs-vertical_1boag_1473 input + span svg {
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        fill: #116dff; }
    ._control-tabs-vertical_1boag_1473 input:checked + span {
      background-color: #ffffff;
      border-top: 1px solid #d6e6fe;
      border-bottom: 1px solid #d6e6fe; }
      ._control-tabs-vertical_1boag_1473 input:checked + span svg {
        fill: #000624; }
    ._control-tabs-vertical_1boag_1473 input:not(:checked) + span {
      box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d6e6fe; }
      ._control-tabs-vertical_1boag_1473 input:not(:checked) + span:hover {
        background-color: #d6e6fe; }
  ._control-tabs-vertical_1boag_1473 label:first-child input:checked + span {
    border-top: none;
    border-bottom: 1px solid #d6e6fe; }
  ._control-tabs-vertical_1boag_1473 label:last-child input:checked + span {
    border-bottom: none; }

._control-link_1boag_1525 div {
  width: 29px;
  height: 26px;
  border-radius: 6px;
  background-color: transparent;
  border: solid 1px #d6e1e8;
  display: block;
  box-sizing: border-box;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: background-color, border-color; }

._control-link_1boag_1525:not(._disabled_1boag_788) div {
  cursor: pointer; }
  ._control-link_1boag_1525:not(._disabled_1boag_788) div:hover {
    background-color: #d6e6fe;
    border-color: #d6e6fe; }

._control-link_1boag_1525 path {
  fill: #868aa5;
  transition-duration: 0.2s;
  transition-property: fill; }

._control-link_1boag_1525 input {
  display: none; }
  ._control-link_1boag_1525 input:checked + div {
    background-color: #116dff;
    border-color: transparent; }
    ._control-link_1boag_1525 input:checked + div path {
      fill: #fff; }
    ._control-link_1boag_1525 input:checked + div:hover {
      background-color: #5999ff; }

._info-icon_1boag_1039 {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  fill: #868aa5; }
  ._label-info-icon-container_1boag_1564._info-icon_1boag_1039 {
    background-color: #3b4057;
    cursor: pointer;
    border-radius: 0;
    width: 30px;
    height: 16px;
    padding-top: 2px;
    box-sizing: border-box; }
  ._accordion-header_1boag_1572 ._info-icon_1boag_1039 {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none; }
    ._accordion-header_1boag_1572:hover ._info-icon_1boag_1039 {
      display: inline-block; }
  ._dropdown_1boag_349 ._info-icon_1boag_1039 {
    vertical-align: middle;
    margin-bottom: 3px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
    ._dropdown_1boag_349 ._info-icon_1boag_1039,
    ._dropdown_1boag_349 ._info-icon_1boag_1039 > ._symbol_1boag_544 {
      margin: 0;
      padding: 0; }
  ._dropdown_1boag_349:hover ._info-icon_1boag_1039 {
    opacity: 1; }
  ._control-checkbox_1boag_1592 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    ._control-checkbox_1boag_1592:hover ._info-icon_1boag_1039 {
      display: inline-block; }
  ._control-text-input_1boag_1599 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    ._control-text-input_1boag_1599:hover ._info-icon_1boag_1039 {
      display: inline-block; }
  ._control-text-input_1boag_1599._always-show-info-icon_1boag_1606 ._info-icon_1boag_1039 {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0; }
  ._preset-item_1boag_1611 ._info-icon_1boag_1039 {
    position: absolute;
    bottom: 21px;
    right: 16px; }
    ._preset-item_1boag_1611:hover ._info-icon_1boag_1039 {
      opacity: 1; }
      ._preset-item_1boag_1611:hover ._info-icon_1boag_1039:hover {
        color: #fff; }
    ._preset-item_1boag_1611 ._info-icon_1boag_1039 svg circle {
      fill: #fff; }
  ._preset-item_1boag_1611 ._info-wrapper_1boag_1621._has-label_1boag_1621 ._info-icon_1boag_1039 {
    bottom: 26px; }
  ._section-header_1boag_1623 > ._title-line_1boag_1623 ._info-icon_1boag_1039 {
    position: absolute;
    bottom: 6px;
    right: 1px;
    display: none; }
  ._site-members-settings_1boag_1628 ._info-icon_1boag_1039 {
    display: none; }
    ._site-members-settings_1boag_1628:hover ._info-icon_1boag_1039 {
      display: inline-block; }
  ._control-section-divider_1boag_1632._labeled_1boag_1632 ._info-icon_1boag_1039 {
    position: absolute;
    right: 12px;
    top: 12px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  ._control-section-divider_1boag_1632._labeled_1boag_1632:hover ._info-icon_1boag_1039 {
    opacity: 1; }

._control-alignment_1boag_1643 {
  text-align: center; }
  ._control-alignment_1boag_1643 ._cls-4_1boag_1645 {
    fill: #116dff; }
  ._control-alignment_1boag_1643 ._cls-5_1boag_899 {
    fill: #fff; }
  ._control-alignment_1boag_1643 ._label-info-wrapper_1boag_1649 {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  ._control-alignment_1boag_1643 div._label_1boag_617 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    text-align: left; }
  ._control-alignment_1boag_1643 div._items_1boag_1663 {
    display: inline-block;
    width: 90px;
    height: 90px; }
    ._control-alignment_1boag_1643 div._items_1boag_1663 input[type='radio'] {
      display: none; }
    ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 3px;
      float: left; }
      ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 span {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: #d6e6fe no-repeat center; }
        ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 span:hover {
          background-color: #80b1ff; }
          ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 span:hover svg {
            visibility: visible; }
        ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 span svg {
          visibility: hidden;
          shape-rendering: crispEdges; }
      ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 input[type='radio']:checked + span {
        background-color: #116dff; }
        ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 input[type='radio']:checked + span svg {
          visibility: visible; }
      ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 input[type='radio']:disabled + span {
        background-color: #f0f0f0; }
      ._control-alignment_1boag_1643 div._items_1boag_1663 label._item_1boag_1663 input[type='radio']:disabled:not(:checked) + span:hover svg {
        visibility: hidden; }

._playIcon_1boag_1697 {
  width: 0;
  height: 0;
  margin-top: 50%;
  margin-left: 50%;
  top: -4px;
  left: -2px;
  position: relative;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #116dff; }

._playButton_1boag_1709 {
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #116dff; }
  ._playButton_1boag_1709:hover {
    background-color: #edf7ff; }
  ._playButton_1boag_1709._isPlaying_1boag_1719 {
    background-color: #116dff; }
    ._playButton_1boag_1709._isPlaying_1boag_1719 ._playIcon_1boag_1697 {
      border-left-color: #ffffff; }

._thumbnail-preview-play_1boag_1724 {
  position: relative; }
  ._thumbnail-preview-play_1boag_1724 svg {
    position: absolute;
    left: 18px;
    width: 49px;
    height: 24px;
    top: 50%;
    margin-top: -11px;
    text-align: center; }
  ._thumbnail-preview-play_1boag_1724 ._label_1boag_617 {
    margin-left: 78px;
    margin-right: 48px;
    padding: 0;
    position: relative;
    color: #395671;
    font-size: 14px;
    font-weight: 300; }
  ._thumbnail-preview-play_1boag_1724 ._playButton_1boag_1709 {
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -11px; }

._pick-text-alignment_1boag_1748 ._svg-radio_1boag_867 {
  border-radius: 6px;
  background-color: #eaf7fe;
  width: 38px;
  height: 38px;
  fill: #7fccf7;
  padding: 8px; }

._pick-text-alignment_1boag_1748 input:checked + ._svg-radio_1boag_867 {
  background-color: #4e99ec;
  fill: #d3edfe; }

._pick-text-alignment_1boag_1748 ._control-thumbnails_1boag_854:not(._with-title_1boag_929) {
  padding-top: 25px; }

hr._divider-long_1boag_1763, hr._divider-short_1boag_1763 {
  background-color: #dfe5eb;
  border: 0;
  height: 1px;
  margin: 0; }

hr._divider-short_1boag_1763 {
  margin: 0 24px; }

._rich-text-paragraph_1boag_1772 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-title_1boag_1774 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    text-align: left;
    margin: 10px 0 0; }
  ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-content_1boag_1784 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    margin: 0; }
    ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-content_1boag_1784 a {
      color: #116dff;
      text-decoration: none; }
      ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-content_1boag_1784 a:hover {
        cursor: pointer;
        text-decoration: underline; }
    ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-content_1boag_1784 b,
    ._rich-text-paragraph_1boag_1772 ._rich-text-paragraph-content_1boag_1784 strong {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      font-weight: normal; }

._listItemWithImage_1boag_1811 {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  ._listItemWithImage_1boag_1811:hover {
    background-color: #e7f0ff; }
    ._disabled_1boag_788._listItemWithImage_1boag_1811:hover {
      background-color: inherit;
      cursor: default; }
  ._listItemWithImage_1boag_1811 ._image_1boag_878 {
    display: inline-block;
    width: 90px;
    height: 90px; }
  ._listItemWithImage_1boag_1811 ._content_1boag_1826 {
    vertical-align: top;
    display: flex;
    min-width: 0;
    margin: 0 10px 0 16px;
    flex: 1;
    flex-direction: column;
    justify-content: center; }
    ._listItemWithImage_1boag_1811 ._content_1boag_1826 ._ellipsis_1boag_1834 {
      overflow: hidden;
      text-overflow: ellipsis; }
    ._listItemWithImage_1boag_1811 ._content_1boag_1826 ._title_1boag_1623 {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin-bottom: 6px; }
      ._disabled_1boag_788._listItemWithImage_1boag_1811 ._content_1boag_1826 ._title_1boag_1623 {
        color: #7a7a7a; }
    ._listItemWithImage_1boag_1811 ._content_1boag_1826 ._desc_1boag_1847 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      line-height: 17px;
      max-height: 51px; }
      ._disabled_1boag_788._listItemWithImage_1boag_1811 ._content_1boag_1826 ._desc_1boag_1847 {
        color: #939393; }
  ._listItemWithImage_1boag_1811 ._list-item-indicator_1boag_1858 {
    display: flex;
    align-items: center; }
  ._listItemWithImage_1boag_1811 ._control-button_1boag_544._inline-link-button_1boag_1861 {
    display: inline;
    height: auto;
    font-size: inherit; }

._list-item-with-image-tooltip-content_1boag_1866 {
  margin: -18px; }
  ._list-item-with-image-tooltip-content_1boag_1866 p span {
    display: inline-block;
    padding-top: 6px; }

._listItem_1boag_1811 {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  ._listItem_1boag_1811:hover {
    background-color: #e7f0ff; }
  ._listItem_1boag_1811 ._image_1boag_878 {
    display: inline-block;
    width: 90px;
    height: 90px; }
  ._listItem_1boag_1811 ._content_1boag_1826 {
    width: 148px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 9px 10px 0 14px; }
    ._listItem_1boag_1811 ._content_1boag_1826._disabled_1boag_788 {
      cursor: default; }
    ._listItem_1boag_1811 ._content_1boag_1826 ._ellipsis_1boag_1834 {
      overflow: hidden;
      text-overflow: ellipsis; }
    ._listItem_1boag_1811 ._content_1boag_1826 ._title_1boag_1623 {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-medium);
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      margin: 0;
      height: 34px;
      line-height: 34px;
      flex-shrink: 0; }
    ._listItem_1boag_1811 ._content_1boag_1826 ._desc_1boag_1847 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      line-height: initial; }
  ._listItem_1boag_1811 ._expandSymbol_1boag_1914 {
    width: 28px;
    height: 28px; }

i._arrow-button_1boag_1918 {
  border: 2px solid #116dff;
  border-radius: 50%;
  display: inline-block;
  height: 21px;
  width: 21px;
  opacity: 0.67;
  cursor: pointer; }
  i._arrow-button_1boag_1918 svg {
    fill: #116dff;
    padding: 5px;
    vertical-align: middle; }
  i._arrow-button_1boag_1918:hover {
    background-color: #116dff; }
    i._arrow-button_1boag_1918:hover svg {
      fill: #fff; }
  i._arrow-button_1boag_1918._without-border_1boag_1934 {
    border: none;
    width: 9px;
    height: 9px;
    opacity: 1; }
    i._arrow-button_1boag_1918._without-border_1boag_1934 svg {
      width: 9px;
      padding: 0; }
    i._arrow-button_1boag_1918._without-border_1boag_1934:hover {
      background-color: transparent; }
      i._arrow-button_1boag_1918._without-border_1boag_1934:hover svg {
        fill: #116dff; }

:not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 {
  overflow: hidden; }
  :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 input {
    display: none; }
    :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 input ~ svg,
    :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 input ~ ._label_1boag_617 {
      cursor: pointer; }
    :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 input:disabled ~ svg,
    :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 input:disabled ~ ._label_1boag_617 {
      cursor: default; }
  :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947 span._label_1boag_617 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: inherit;
    display: inline-block;
    vertical-align: middle; }
  :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947._medium-scale_1boag_1964 svg {
    transform: scale(0.8);
    margin-bottom: -3px;
    vertical-align: middle;
    padding: 1px; }
  :not(._composite-toggle-switch_1boag_1947) > ._control-boolean_1boag_1947._no-scale_1boag_1969 svg {
    transform: scale(1);
    vertical-align: middle;
    padding: 1px; }

._control-boolean_1boag_1947._toggle-button_1boag_1974 {
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  ._control-boolean_1boag_1947._toggle-button_1boag_1974:hover {
    background-color: #e7f0ff; }
  ._control-boolean_1boag_1947._toggle-button_1boag_1974:active:not(._secondary_1boag_1984), ._control-boolean_1boag_1947._toggle-button_1boag_1974._checked_1boag_1984:not(._secondary_1boag_1984) {
    background-color: #116dff; }
    ._control-boolean_1boag_1947._toggle-button_1boag_1974:active:not(._secondary_1boag_1984):hover, ._control-boolean_1boag_1947._toggle-button_1boag_1974._checked_1boag_1984:not(._secondary_1boag_1984):hover {
      background-color: #5999ff; }
  ._control-boolean_1boag_1947._toggle-button_1boag_1974[disabled] {
    background-color: #fff; }
    ._control-boolean_1boag_1947._toggle-button_1boag_1974[disabled]:hover {
      background-color: #fff; }
  ._control-boolean_1boag_1947._toggle-button_1boag_1974:active._secondary_1boag_1984, ._control-boolean_1boag_1947._toggle-button_1boag_1974._checked_1boag_1984._secondary_1boag_1984 {
    background-color: transparent; }
    ._control-boolean_1boag_1947._toggle-button_1boag_1974:active._secondary_1boag_1984 svg, ._control-boolean_1boag_1947._toggle-button_1boag_1974._checked_1boag_1984._secondary_1boag_1984 svg {
      fill: #116dff; }
    ._control-boolean_1boag_1947._toggle-button_1boag_1974:active._secondary_1boag_1984:hover, ._control-boolean_1boag_1947._toggle-button_1boag_1974._checked_1boag_1984._secondary_1boag_1984:hover {
      background-color: #e7f0ff; }
  ._control-boolean_1boag_1947._toggle-button_1boag_1974._small_1boag_1998 {
    width: 24px;
    height: 24px; }

._radio-control_1boag_862 {
  cursor: pointer; }
  ._radio-control_1boag_862 input {
    display: none; }
  ._radio-control_1boag_862 span._label_1boag_617 {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

._circle-preloader_1boag_2012 {
  width: 15px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  overflow: hidden;
  transform-origin: 100% 50%;
  animation: _semi-rotate_1boag_1 1s linear infinite; }
  ._wix-code-file-tree_1boag_2023 ._circle-preloader_1boag_2012 {
    width: 10px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px; }
  ._circle-preloader_1boag_2012::before {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: _inner-rotate_1boag_1 0.5s linear infinite alternate;
    color: #80b1ff; }
  ._circle-preloader_1boag_2012::after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: _inner-rotate_1boag_1 0.5s linear infinite alternate;
    color: #116dff;
    opacity: 0; }
  ._circle-preloader_1boag_2012._white_1boag_2055::before {
    color: #f0f0f0; }
  ._circle-preloader_1boag_2012._white_1boag_2055::after {
    color: #f0f0f0; }
  ._install-preloader_1boag_2059 ._circle-preloader_1boag_2012 {
    position: relative; }

@keyframes _inner-rotate_1boag_1 {
  to {
    transform: rotate(115deg);
    opacity: 1; } }

@keyframes _semi-rotate_1boag_1 {
  from {
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  to {
    transform: rotate(540deg); } }

._video-container_1boag_2078 {
  border-radius: 6px;
  height: 174px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 310px;
  z-index: 0; }
  ._video-container_1boag_2078 * {
    border: none;
    margin: 0;
    outline: none;
    padding: 0; }
  ._video-container_1boag_2078 > video {
    object-fit: cover;
    width: 100%; }
  ._video-container_1boag_2078 > ._controls_1boag_2094 {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='stopped'] > footer > ._actions_1boag_2103 > ._play-pause_1boag_2103, ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='paused'] > footer > ._actions_1boag_2103 > ._play-pause_1boag_2103 {
      border-left: 8px solid #ffffff;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      height: 0;
      width: 0; }
    ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='playing'] > ._main-action_1boag_2109,
    ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='playing'] > footer {
      box-shadow: 0 0 10px 5px rgba(22, 45, 61, 0.2);
      opacity: 0; }
    ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='playing']:hover > footer {
      opacity: 1; }
    ._video-container_1boag_2078 > ._controls_1boag_2094[data-state='playing'] > footer > ._actions_1boag_2103 > ._play-pause_1boag_2103 {
      height: 10px;
      border-left: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      width: 9px; }
    ._video-container_1boag_2078 > ._controls_1boag_2094 > ._main-action_1boag_2109,
    ._video-container_1boag_2078 > ._controls_1boag_2094 > footer {
      transition-duration: 0.3s;
      transition-property: opacity, box-shadow;
      will-change: opacity, box-shadow; }
    ._video-container_1boag_2078 > ._controls_1boag_2094 > ._main-action_1boag_2109 {
      background: rgba(22, 45, 61, 0.2);
      flex-grow: 1;
      position: relative; }
      ._video-container_1boag_2078 > ._controls_1boag_2094 > ._main-action_1boag_2109 > ._play-big_1boag_2129 {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        border: 2px solid #ffffff;
        border-radius: 50%;
        background: rgba(22, 45, 61, 0.2);
        height: 80px;
        width: 80px; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > ._main-action_1boag_2109 > ._play-big_1boag_2129:hover {
          opacity: 1; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > ._main-action_1boag_2109 > ._play-big_1boag_2129:after {
          border-left: 30px solid #ffffff;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          height: 0;
          width: 0;
          content: '';
          display: inline-block;
          left: 4px;
          position: relative; }
    ._video-container_1boag_2078 > ._controls_1boag_2094 > footer {
      background: rgba(22, 45, 61, 0.2);
      box-sizing: border-box;
      height: 30px;
      padding: 0 18px;
      width: 100%; }
      ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161 {
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        cursor: pointer;
        height: 10px;
        overflow: hidden;
        position: relative; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161:hover {
          opacity: 1; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161:before {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          background: #ffffff;
          content: '';
          display: block;
          height: 2px;
          width: 100%; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161:hover > ._progress_1boag_2161,
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161:hover > ._progress_1boag_2161:after {
          box-shadow: 0 0 2px 0 #116dff; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161 > ._progress_1boag_2161 {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          transition-duration: 0.3s;
          transition-property: box-shadow;
          will-change: box-shadow;
          background: #116dff;
          height: 2px;
          width: 0;
          display: block; }
          ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._progress-bar_1boag_2161 > ._progress_1boag_2161:after {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            transition-duration: 0.3s;
            transition-property: box-shadow;
            will-change: box-shadow;
            background: inherit;
            border-radius: 50%;
            content: '';
            display: block;
            height: 8px;
            right: 0;
            width: 8px; }
      ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 {
        align-items: center;
        display: flex;
        height: calc( 100% - 14px); }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 button {
          transition-duration: 0.3s;
          transition-property: opacity;
          will-change: opacity;
          opacity: 0.7;
          background: none; }
          ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 button:hover {
            opacity: 1; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 > ._play-pause_1boag_2103 {
          margin-right: 10px; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 > ._stop_1boag_2223 {
          background: #ffffff;
          height: 10px;
          width: 10px; }
        ._video-container_1boag_2078 > ._controls_1boag_2094 > footer > ._actions_1boag_2103 > ._fullscreen_1boag_2227 {
          border: 2px solid #ffffff;
          height: 10px;
          margin-left: auto;
          width: 16px; }
  ._wix-store-switch-panel_1boag_2232 ._video-container_1boag_2078 {
    margin-bottom: 60px; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-0-icon_1boag_2235,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-0-icon_1boag_2236 ._class-v-overlay-pat-0-icon_1boag_2235 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-0-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-0-icon_1boag_2236:hover ._class-v-overlay-pat-0-icon_1boag_2235 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-0-icon_1boag_2235,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-0-icon_1boag_2236 ._input-v-overlay-pat-0-icon_1boag_2249:checked ~ ._class-v-overlay-pat-0-icon_1boag_2235 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-1-icon_1boag_2253,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-1-icon_1boag_2254 ._class-v-overlay-pat-1-icon_1boag_2253 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-1-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-1-icon_1boag_2254:hover ._class-v-overlay-pat-1-icon_1boag_2253 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-1-icon_1boag_2253,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-1-icon_1boag_2254 ._input-v-overlay-pat-1-icon_1boag_2267:checked ~ ._class-v-overlay-pat-1-icon_1boag_2253 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-2-icon_1boag_2271,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-2-icon_1boag_2272 ._class-v-overlay-pat-2-icon_1boag_2271 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-2-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-2-icon_1boag_2272:hover ._class-v-overlay-pat-2-icon_1boag_2271 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-2-icon_1boag_2271,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-2-icon_1boag_2272 ._input-v-overlay-pat-2-icon_1boag_2285:checked ~ ._class-v-overlay-pat-2-icon_1boag_2271 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-3-icon_1boag_2289,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-3-icon_1boag_2290 ._class-v-overlay-pat-3-icon_1boag_2289 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-3-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-3-icon_1boag_2290:hover ._class-v-overlay-pat-3-icon_1boag_2289 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-3-icon_1boag_2289,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-3-icon_1boag_2290 ._input-v-overlay-pat-3-icon_1boag_2303:checked ~ ._class-v-overlay-pat-3-icon_1boag_2289 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-4-icon_1boag_2307,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-4-icon_1boag_2308 ._class-v-overlay-pat-4-icon_1boag_2307 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-4-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-4-icon_1boag_2308:hover ._class-v-overlay-pat-4-icon_1boag_2307 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-4-icon_1boag_2307,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-4-icon_1boag_2308 ._input-v-overlay-pat-4-icon_1boag_2321:checked ~ ._class-v-overlay-pat-4-icon_1boag_2307 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-5-icon_1boag_2325,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-5-icon_1boag_2326 ._class-v-overlay-pat-5-icon_1boag_2325 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-5-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-5-icon_1boag_2326:hover ._class-v-overlay-pat-5-icon_1boag_2325 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-5-icon_1boag_2325,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-5-icon_1boag_2326 ._input-v-overlay-pat-5-icon_1boag_2339:checked ~ ._class-v-overlay-pat-5-icon_1boag_2325 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-6-icon_1boag_2343,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-6-icon_1boag_2344 ._class-v-overlay-pat-6-icon_1boag_2343 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-6-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-6-icon_1boag_2344:hover ._class-v-overlay-pat-6-icon_1boag_2343 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-6-icon_1boag_2343,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-6-icon_1boag_2344 ._input-v-overlay-pat-6-icon_1boag_2357:checked ~ ._class-v-overlay-pat-6-icon_1boag_2343 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._class-v-overlay-pat-7-icon_1boag_2361,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-7-icon_1boag_2362 ._class-v-overlay-pat-7-icon_1boag_2361 {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-7-icon.png");
  background-color: #e7f0ff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #ffffff; }

._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-7-icon_1boag_2362:hover ._class-v-overlay-pat-7-icon_1boag_2361 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #80b1ff; }

._background-video-patterns-control_1boag_2235 ._selected_1boag_2248 ._class-v-overlay-pat-7-icon_1boag_2361,
._background-video-patterns-control_1boag_2235 ._control-v-overlay-pat-7-icon_1boag_2362 ._input-v-overlay-pat-7-icon_1boag_2375:checked ~ ._class-v-overlay-pat-7-icon_1boag_2361 {
  border-radius: 4px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #116dff; }

._background-video-patterns-control_1boag_2235 ._control-buttons-group_1boag_1216 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 24px;
  text-align: center; }
  ._background-video-patterns-control_1boag_2235 ._control-buttons-group_1boag_1216 input + span {
    max-width: 70px;
    max-height: 34px;
    line-height: 34px; }

._control-dock_1boag_2390 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0;
  position: relative; }
  ._control-dock_1boag_2390:hover ._info-icon_1boag_1039 {
    opacity: 1; }
  ._control-dock_1boag_2390 ._info-icon_1boag_1039 {
    float: right;
    opacity: 0; }
  ._control-dock_1boag_2390 ._dock-label_1boag_2401 {
    font-size: 14px;
    left: 25px;
    margin-bottom: 20px;
    display: block;
    color: #3b4057;
    margin-top: 20px; }
  ._control-dock_1boag_2390 ._dock-corners-wrapper_1boag_2408 {
    position: relative;
    margin: 0 auto;
    width: 185px; }
  ._control-dock_1boag_2390 label {
    font-size: 0;
    margin: 0;
    display: inline-block; }
    ._control-dock_1boag_2390 label input {
      display: none; }
      ._control-dock_1boag_2390 label input:checked + ._top_1boag_2418,
      ._control-dock_1boag_2390 label input:checked + ._right_1boag_1142,
      ._control-dock_1boag_2390 label input:checked + ._bottom_1boag_2420,
      ._control-dock_1boag_2390 label input:checked + ._left_1boag_1144 {
        border-color: #116dff;
        background: #d6e6fe; }
      ._control-dock_1boag_2390 label input:checked + ._middle_1boag_2424 {
        background: #116dff; }
    ._control-dock_1boag_2390 label ._square_1boag_927 {
      cursor: pointer;
      box-sizing: border-box;
      background: #e7f0ff;
      margin: 0;
      padding: 0;
      width: 61px;
      height: 43px;
      display: block; }
      ._control-dock_1boag_2390 label ._square_1boag_927._top_1boag_2418 {
        margin-bottom: 1px;
        margin-right: 1px;
        border-top: 12px solid #a8caff; }
        ._control-dock_1boag_2390 label ._square_1boag_927._top_1boag_2418:hover {
          border-top-color: #5999ff; }
        ._control-dock_1boag_2390 label ._square_1boag_927._top_1boag_2418._left_1boag_1144 {
          border-top-left-radius: 5px; }
        ._control-dock_1boag_2390 label ._square_1boag_927._top_1boag_2418._right_1boag_1142 {
          border-top-right-radius: 5px; }
      ._control-dock_1boag_2390 label ._square_1boag_927._left_1boag_1144 {
        border-left: 12px solid #a8caff;
        margin-left: 0; }
        ._control-dock_1boag_2390 label ._square_1boag_927._left_1boag_1144:hover {
          border-left-color: #5999ff; }
      ._control-dock_1boag_2390 label ._square_1boag_927._middle_1boag_2424 {
        margin-left: 1px;
        margin-right: 1px; }
        ._control-dock_1boag_2390 label ._square_1boag_927._middle_1boag_2424:hover {
          background: #5999ff; }
      ._control-dock_1boag_2390 label ._square_1boag_927._right_1boag_1142 {
        border-right: 12px solid #a8caff;
        margin-right: 0; }
        ._control-dock_1boag_2390 label ._square_1boag_927._right_1boag_1142:hover {
          border-right-color: #5999ff; }
      ._control-dock_1boag_2390 label ._square_1boag_927._bottom_1boag_2420 {
        margin-top: 1px;
        margin-right: 1px;
        border-bottom: 12px solid #a8caff; }
        ._control-dock_1boag_2390 label ._square_1boag_927._bottom_1boag_2420:hover {
          border-bottom-color: #5999ff; }
        ._control-dock_1boag_2390 label ._square_1boag_927._bottom_1boag_2420._left_1boag_1144 {
          border-bottom-left-radius: 5px; }
        ._control-dock_1boag_2390 label ._square_1boag_927._bottom_1boag_2420._right_1boag_1142 {
          border-bottom-right-radius: 5px;
          margin-right: 0; }
    ._control-dock_1boag_2390 label._disabled_1boag_788 ._square_1boag_927 {
      background: #f0f0f0;
      border-color: #d5d5d5; }
      ._control-dock_1boag_2390 label._disabled_1boag_788 ._square_1boag_927:hover {
        background: #f0f0f0;
        border-color: #d5d5d5; }
      ._control-dock_1boag_2390 label._disabled_1boag_788 ._square_1boag_927._middle_1boag_2424 {
        background: #ffffff; }
        ._control-dock_1boag_2390 label._disabled_1boag_788 ._square_1boag_927._middle_1boag_2424:hover {
          background: #ffffff; }
  ._control-dock_1boag_2390 ._dashed_1boag_2481 {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none; }
    ._control-dock_1boag_2390 ._dashed_1boag_2481._horizontal_1boag_1308 {
      border-top: 1px dotted #80b1ff;
      border-bottom: 1px dotted #80b1ff;
      height: 45px;
      top: 43px;
      left: 12px;
      right: 12px; }
    ._control-dock_1boag_2390 ._dashed_1boag_2481._vertical_1boag_1327 {
      border-left: 1px dotted #80b1ff;
      border-right: 1px dotted #80b1ff;
      width: 63px;
      top: 12px;
      bottom: 12px;
      left: 61px; }

._drag-area-control_1boag_2500 {
  min-height: 100px; }
  ._drag-area-control_1boag_2500._with-border_1boag_2502 {
    border-radius: 14px;
    border: 2px dashed #d6e6fe;
    margin: 12px; }
    ._fonts-upload-panel_1boag_2506 ._drag-area-control_1boag_2500._with-border_1boag_2502 {
      min-height: 299px; }
    ._drag-area-control_1boag_2500._with-border_1boag_2502._dragging_1boag_2508 {
      border: 2px dashed #80b1ff; }
  ._fonts-upload-panel_1boag_2506 ._drag-area-control_1boag_2500 {
    min-height: 327px; }

._splitter_1boag_2513 {
  display: flex; }

._splitter-divider_1boag_2516 {
  z-index: 1500; }
  ._splitter-divider-horizontal_1boag_2518 {
    width: 100%;
    height: 10px;
    margin: -5px 0;
    cursor: row-resize; }
  ._splitter-divider-vertical_1boag_2523 {
    width: 10px;
    height: 100%;
    margin: 0 -5px;
    cursor: col-resize;
    color: #b6c1cd; }

._ide-splitter_1boag_2530 ._splitter-divider-horizontal_1boag_2518 {
  height: 18px;
  margin: -6px 0 -12px 0; }

._splitter-divider-handle_1boag_2534 {
  background-color: #e7f0ff;
  border: 1px solid #dfe5eb;
  cursor: pointer;
  height: 52px;
  position: relative;
  text-align: center;
  top: calc(50% - (52px / 2));
  width: 11px; }
  ._splitter-divider-handle_1boag_2534._open_1boag_2543 {
    border-bottom-left-radius: 7px;
    border-right: none;
    border-top-left-radius: 7px;
    left: calc(-50% - 3px); }
  ._splitter-divider-handle_1boag_2534._closed_1boag_1482 {
    border-bottom-right-radius: 7px;
    border-left: none;
    border-top-right-radius: 7px;
    left: 50%; }
  ._splitter-divider-handle_1boag_2534 ._symbol_1boag_544 {
    height: 12px;
    position: relative;
    top: 19px;
    width: 5px; }
    ._splitter-divider-handle_1boag_2534 ._symbol_1boag_544 path {
      fill: #000624; }
  ._splitter-divider-handle_1boag_2534:hover ._symbol_1boag_544 path {
    fill: #116dff; }

._paginated-thumbnails_1boag_2563 {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  padding-bottom: 36px; }
  ._paginated-thumbnails_1boag_2563 ._paginated-thumbnails-content_1boag_2567 {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory; }
    ._paginated-thumbnails_1boag_2563 ._paginated-thumbnails-content_1boag_2567 ._composite-thumbnail-wrapper_1boag_2575 {
      box-sizing: border-box;
      flex-shrink: 0;
      scroll-snap-align: start;
      width: 100%; }
  ._paginated-thumbnails_1boag_2563 ._paginated-tumbnails-pager_1boag_2580 {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 18px;
    text-align: center; }
    ._paginated-thumbnails_1boag_2563 ._paginated-tumbnails-pager_1boag_2580 li {
      display: inline-block;
      margin: 0 6px; }
    ._paginated-thumbnails_1boag_2563 ._paginated-tumbnails-pager_1boag_2580 button {
      border-radius: 50%;
      overflow: hidden;
      width: 12px;
      height: 12px;
      background: #dfe5eb; }
      ._paginated-thumbnails_1boag_2563 ._paginated-tumbnails-pager_1boag_2580 button._selected_1boag_2248 {
        background: #116dff; }
      ._paginated-thumbnails_1boag_2563 ._paginated-tumbnails-pager_1boag_2580 button span {
        visibility: hidden; }
  ._paginated-thumbnails_1boag_2563 ._hide-scrollbar_1boag_2599 {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    ._paginated-thumbnails_1boag_2563 ._hide-scrollbar_1boag_2599::-webkit-scrollbar {
      display: none; }

._control-accordion_1boag_2605 {
  position: relative; }
  ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 {
    background-color: #fff; }
    ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572:hover {
      background-color: #e7f0ff; }
    ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 > ._selected-indicator_1boag_2611 {
      margin-left: 12px;
      vertical-align: middle; }
    ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 > ._control-label_1boag_402 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #000624;
      font-weight: 500;
      text-align: left;
      padding: 19px 0 18px 0;
      display: inline-block;
      line-height: 13px; }
    ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 > ._accordion-icon_1boag_2626 {
      margin: 19px 15px 18px 24px; }
      ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 > ._accordion-icon_1boag_2626._open_1boag_2543 {
        margin: 22px 12px 20px 24px; }

._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572:hover {
  background-color: #fff; }
  ._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572:hover > ._control-label_1boag_402 {
    color: #116dff; }
  ._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572:hover ._symbol-accordionClose_1boag_2635,
  ._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572:hover ._symbol-accordionOpen_1boag_2636 {
    fill: #116dff; }

._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 > ._control-label_1boag_402 {
  color: #3b4057; }

._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 ._symbol-accordionClose_1boag_2635,
._light-accordion_1boag_2631 ._control-accordion_1boag_2605 > ._accordion-header_1boag_1572 ._symbol-accordionOpen_1boag_2636 {
  fill: #3b4057; }

._control-text-input_1boag_1599 {
  position: relative;
  text-align: left;
  display: inline-block;
  width: 100%; }
  ._control-text-input_1boag_1599 > ._text-input-label_1boag_2651 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: #3b4057;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px;
    padding: 7px 30px 6px 12px; }
    ._is-disabled_1boag_2665._control-text-input_1boag_1599 > ._text-input-label_1boag_2651 {
      color: #939393; }
    ._developer-toolbar-content_1boag_2667 ._control-text-input_1boag_1599 > ._text-input-label_1boag_2651 {
      padding: 10px 5px 10px 1px; }
    ._wix-code-find-replace_1boag_2669 ._control-text-input_1boag_1599 > ._text-input-label_1boag_2651 {
      padding-top: 6px; }
  ._success_1boag_2671._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 :not(:focus), ._error_1boag_2671._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 :not(:focus), ._warning_1boag_2671._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 :not(:focus) {
    width: calc(100% - 43px);
    padding-right: 0; }
  ._instant-error_1boag_2674._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309:focus {
    border: 1px solid #e62214; }
  ._color-format_1boag_2676 ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding-left: 62px;
    height: 24px;
    line-height: 24px;
    width: 100%; }
  ._developer-toolbar-content_1boag_2667 ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 {
    width: 100%;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #174165;
    font-weight: 700;
    padding-left: 8px; }
  ._wix-code-find-replace_1boag_2669 ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 {
    height: 22px;
    font-size: 14px;
    color: #3b4057;
    border: 1px solid #5999ff;
    box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    ._wix-code-find-replace_1boag_2669:hover ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309 {
      background-color: transparent;
      cursor: text; }
    ._wix-code-find-replace_1boag_2669 ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._input_1boag_309:hover {
      background-color: transparent;
      cursor: text; }
  ._control-text-input_1boag_1599 > ._input-container_1boag_309 ._developer-toolbar-content_1boag_2667 {
    flex: 1; }
  ._color-format_1boag_2676 ._control-text-input_1boag_1599 > ._input-container_1boag_309 {
    display: block; }
  ._wix-code-find-replace_1boag_2669 ._control-text-input_1boag_1599 > ._input-container_1boag_309 {
    width: 190px; }
  ._control-text-input_1boag_1599 ._validation-icon_1boag_2713 {
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
    visibility: hidden;
    font-size: 0; }
    ._error_1boag_2671._control-text-input_1boag_1599:not(._focused_1boag_2720) ._validation-icon_1boag_2713._validation-icon-error_1boag_2720 {
      visibility: visible; }
    ._success_1boag_2671._control-text-input_1boag_1599:not(._focused_1boag_2720) ._validation-icon_1boag_2713._validation-icon-success_1boag_2722 {
      visibility: visible; }
    ._warning_1boag_2671._control-text-input_1boag_1599:not(._focused_1boag_2720) ._validation-icon_1boag_2713._validation-icon-warning_1boag_2724 {
      visibility: visible; }
    ._color-format_1boag_2676 ._control-text-input_1boag_1599 ._validation-icon_1boag_2713 {
      top: 0; }
    ._wix-code-find-replace_1boag_2669 ._control-text-input_1boag_1599 ._validation-icon_1boag_2713 {
      display: none; }
  ._control-text-input_1boag_1599 ._validation-icon-wrapper_1boag_2730 {
    position: absolute;
    top: -3px;
    right: 2px; }
    ._developer-toolbar-content_1boag_2667 ._control-text-input_1boag_1599 ._validation-icon-wrapper_1boag_2730 {
      position: relative;
      top: -20px;
      left: 36px;
      right: unset; }
    ._has-label_1boag_1621._control-text-input_1boag_1599 ._validation-icon-wrapper_1boag_2730 {
      top: 37px;
      right: 25px; }
      ._has-label_1boag_1621._control-text-input_1boag_1599 ._validation-icon-wrapper_1boag_2730 ._validation-icon_1boag_2713 {
        top: 0;
        right: -11px; }
  ._color-format_1boag_2676 ._control-text-input_1boag_1599:before {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    content: '#';
    position: absolute;
    left: 55px;
    top: 4px; }
  ._developer-toolbar-content_1boag_2667 ._control-text-input_1boag_1599 {
    display: flex;
    align-items: center; }
  ._wix-code-file-tree_1boag_2023 ._control-text-input_1boag_1599 > ._input-container_1boag_309 > ._input_1boag_309 {
    height: 22px;
    font-size: 14px; }
  ._wix-code-pages-tree_1boag_2761 ._control-text-input_1boag_1599 > ._input-container_1boag_309 > ._input_1boag_309 {
    height: 22px;
    font-size: 14px; }

._developer-toolbar-content_1boag_2667 ._control-text-input_1boag_1599._focused_1boag_2720 > ._input-container_1boag_309 ._input_1boag_309 {
  font-weight: 400; }

._selective-text-input_1boag_2768 {
  position: relative;
  display: flex;
  align-items: center; }
  ._selective-text-input_1boag_2768 ._textInput_1boag_2772 {
    margin-left: 16px;
    margin-right: 80px;
    padding: 0;
    width: auto; }
    ._selective-text-input_1boag_2768 ._textInput_1boag_2772 > input {
      font-size: 16px;
      line-height: 16px; }
  ._selective-text-input_1boag_2768 ._control-mandatory_1boag_1164 {
    display: flex;
    align-items: center;
    margin-left: 11px; }

._input-slider_1boag_2785 {
  position: relative; }
  ._input-slider_1boag_2785 > label {
    display: none; }
  ._input-slider_1boag_2785._has-label_1boag_1621 > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-bottom: 5px;
    color: #3b4057;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  ._input-slider_1boag_2785 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  ._input-slider_1boag_2785 ._sliderArea_1boag_2808 {
    position: relative; }
  ._input-slider_1boag_2785 ._slider_1boag_2808 {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    ._input-slider_1boag_2785 ._slider_1boag_2808 ._line_1boag_2815 {
      position: absolute;
      top: 17px;
      left: 0;
      height: 6px;
      width: 100%;
      background-color: #d6e6fe;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    ._input-slider_1boag_2785 ._slider_1boag_2808 ._knobContainer_1boag_2825 {
      margin-right: 16px;
      position: relative; }
    ._input-slider_1boag_2785 ._slider_1boag_2808 ._coloredLine_1boag_2828 {
      position: absolute;
      top: 14px;
      left: 0;
      height: 6px;
      background-color: #116dff;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #116dff;
      will-change: width;
      cursor: pointer; }
    ._input-slider_1boag_2785 ._slider_1boag_2808 ._sliderKnob_1boag_2838 {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      ._input-slider_1boag_2785 ._slider_1boag_2808 ._sliderKnob_1boag_2838:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #116dff;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      ._input-slider_1boag_2785 ._slider_1boag_2808 ._sliderKnob_1boag_2838:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  ._input-slider_1boag_2785 ._input-stepper_1boag_844 {
    position: absolute;
    right: 0; }
    ._input-slider_1boag_2785 ._input-stepper_1boag_844 ._input_1boag_309 {
      text-align: right; }
  ._input-slider_1boag_2785:hover ._input-stepper_1boag_844,
  ._input-slider_1boag_2785:hover ._input-stepper_1boag_844 > :not(._units-container_1boag_2870) {
    background-color: #e7f0ff; }
  ._input-slider_1boag_2785:hover ._info-icon_1boag_1039 {
    display: inline-block; }
  ._input-slider_1boag_2785._disabled_1boag_788 {
    opacity: 0.5; }
  ._color-picker-input-with-opacity_1boag_2876 ._input-slider_1boag_2785 {
    margin-right: 40px; }

._input-slider-opacity_1boag_2879 {
  position: relative; }
  ._input-slider-opacity_1boag_2879 > label {
    display: none; }
  ._input-slider-opacity_1boag_2879._has-label_1boag_1621 > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-bottom: 5px;
    color: #3b4057;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  ._input-slider-opacity_1boag_2879 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  ._input-slider-opacity_1boag_2879 ._sliderArea_1boag_2808 {
    position: relative; }
  ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._empty-line_1boag_2909 {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
      background-position: 1px 1px, 5px 5px;
      background-size: 8px 8px;
      cursor: pointer; }
    ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._line_1boag_2815 {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._knobContainer_1boag_2825 {
      margin-right: 16px;
      position: relative; }
    ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._sliderKnob_1boag_2838 {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._sliderKnob_1boag_2838:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #116dff;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      ._input-slider-opacity_1boag_2879 ._slider_1boag_2808 ._sliderKnob_1boag_2838:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  ._input-slider-opacity_1boag_2879 ._input-stepper_1boag_844 {
    position: absolute;
    right: 0; }
    ._input-slider-opacity_1boag_2879 ._input-stepper_1boag_844 ._input_1boag_309 {
      text-align: right; }
  ._input-slider-opacity_1boag_2879:hover ._input-stepper_1boag_844,
  ._input-slider-opacity_1boag_2879:hover ._input-stepper_1boag_844 > :not(._units-container_1boag_2870) {
    background-color: #e7f0ff; }
  ._input-slider-opacity_1boag_2879:hover ._info-icon_1boag_1039 {
    display: inline-block; }
  ._input-slider-opacity_1boag_2879._disabled_1boag_788 {
    opacity: 0.5; }
  ._color-picker-input-with-opacity_1boag_2876 ._input-slider-opacity_1boag_2879 {
    margin-right: 40px; }

._input-stepper_1boag_844 {
  border-radius: 4px;
  border: 1px solid transparent;
  width: 56px;
  padding: 0 6px;
  position: relative;
  text-align: left;
  display: inline-block; }
  ._input-stepper_1boag_844._no-padding_1boag_2981 {
    padding: 0; }
  ._input-stepper_1boag_844:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    border: 1px solid transparent;
    border-radius: 4px;
    pointer-events: none; }
    ._edited_1boag_2993._input-stepper_1boag_844:after {
      border-color: #9cccf6;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    ._bad-value_1boag_2996._input-stepper_1boag_844:after {
      border-color: #f1726b; }
  ._input-stepper_1boag_844._disabled_1boag_788 {
    opacity: 0.5; }
  ._units-dd-expanded_1boag_3000._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993),
  ._units-dd-expanded_1boag_3000._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993) > ._input_1boag_309,
  ._units-dd-expanded_1boag_3000._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993) > ._units-container_1boag_2870 > ._units_1boag_2870,
  ._units-dd-expanded_1boag_3000._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993) > ._units-container_1boag_2870, ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):hover,
  ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):hover > ._input_1boag_309,
  ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):hover > ._units-container_1boag_2870 > ._units_1boag_2870,
  ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):hover > ._units-container_1boag_2870 {
    background-color: #d6e6fe;
    color: #3b4057;
    cursor: pointer; }
  ._units-dd-expanded_1boag_3000._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):after, ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993):hover:after {
    border-color: #d6e6fe; }
  ._input-stepper_1boag_844:not(._disabled_1boag_788):not(._bad-value_1boag_2996):not(._edited_1boag_2993) > ._input_1boag_309:focus {
    background-color: #d6e6fe;
    color: #3b4057;
    cursor: pointer; }
  ._input-stepper_1boag_844._edited_1boag_2993 {
    background-color: transparent !important; }
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844._edited_1boag_2993 > ._input_1boag_309 {
      width: 100%; }
  ._input-stepper_1boag_844._small_1boag_1998 {
    width: 45px;
    padding: 2px 2px 2px 0; }
  ._input-stepper_1boag_844._has-units_1boag_3023._with-units-dd_1boag_3023 {
    width: 64px;
    padding-right: 0; }
    ._input-stepper_1boag_844._has-units_1boag_3023._with-units-dd_1boag_3023 > ._input_1boag_309 {
      width: 28px; }
  ._input-stepper_1boag_844 > ._input_1boag_309 {
    border: none;
    outline: none;
    text-align: center;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    padding: 0 7px;
    width: calc(100% - 14px);
    display: inline-block;
    color: #000624; }
    ._disabled_1boag_788._input-stepper_1boag_844 > ._input_1boag_309 {
      color: #7a7a7a;
      background: transparent; }
    ._has-units_1boag_3023._input-stepper_1boag_844 > ._input_1boag_309 {
      width: 70%;
      padding: 0;
      text-align: right;
      display: inline-block; }
    ._small_1boag_1998._input-stepper_1boag_844 > ._input_1boag_309 {
      font-size: 12px;
      height: 18px;
      line-height: 18px; }
    ._edited_1boag_2993._input-stepper_1boag_844 > ._input_1boag_309 {
      background-color: transparent !important;
      cursor: text !important; }
    ._input-stepper_1boag_844:not(._edited_1boag_2993) > ._input_1boag_309 {
      cursor: default; }
    ._input-stepper_1boag_844 > ._input_1boag_309::selection {
      background-color: #e7f0ff; }
      ._input-stepper_1boag_844:not(._edited_1boag_2993) > ._input_1boag_309::selection {
        background-color: transparent; }
    ._color-format_1boag_2676 ._input-stepper_1boag_844 > ._input_1boag_309 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 24px;
      line-height: 24px; }
  ._input-stepper_1boag_844:edited > ._units-container_1boag_2870 {
    background-color: transparent !important; }
  ._edited_1boag_2993._input-stepper_1boag_844:not(._with-units-dd_1boag_3023) > ._units-container_1boag_2870 {
    visibility: hidden; }
  ._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870 {
    line-height: 36px;
    height: 36px;
    width: 1%;
    display: inline-block;
    white-space: nowrap;
    padding: 0 7px 0 1px; }
    ._disabled_1boag_788._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870 {
      color: #7a7a7a; }
    ._small_1boag_1998._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870 {
      height: 18px;
      line-height: 18px;
      font-size: 12px; }
    ._color-format_1boag_2676 ._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870 {
      height: 24px;
      line-height: 24px; }
  ._control-angle_1boag_785 ._input-stepper_1boag_844 {
    position: absolute;
    top: 52px;
    left: 138px;
    width: 40px; }
    ._control-angle_1boag_785 ._input-stepper_1boag_844 > ._units_1boag_2870,
    ._control-angle_1boag_785 ._input-stepper_1boag_844 > ._input_1boag_309 {
      height: 28px;
      line-height: 28px; }
    ._control-angle_1boag_785 ._input-stepper_1boag_844._has-units_1boag_3023 > ._input_1boag_309 {
      width: 78%; }
  ._control-angle_1boag_785 > ._circle_1boag_790:active + ._input-stepper_1boag_844,
  ._control-angle_1boag_785 > ._circle_1boag_790:active + ._input-stepper_1boag_844 > ._units_1boag_2870,
  ._control-angle_1boag_785 > ._circle_1boag_790:active + ._input-stepper_1boag_844 > ._stepper_1boag_3018,
  ._control-angle_1boag_785:hover ._input-stepper_1boag_844,
  ._control-angle_1boag_785:hover ._input-stepper_1boag_844 > ._units_1boag_2870,
  ._control-angle_1boag_785:hover ._input-stepper_1boag_844 > ._stepper_1boag_3018 {
    background-color: #edf7ff; }
  ._color-format_1boag_2676 ._input-stepper_1boag_844 {
    width: 100%;
    box-sizing: border-box; }
  ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844 {
    text-align: left;
    padding-left: 0;
    border-radius: 4px;
    margin-top: 5px;
    overflow: hidden;
    margin-left: 12px;
    padding-right: 11px; }
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844:after {
      border-radius: 4px; }
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870,
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844 > ._input_1boag_309 {
      height: 17px;
      line-height: 17px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844 > ._units-container_1boag_2870 > ._units_1boag_2870 {
      height: 17px; }
    ._stepper-with-svg_1boag_3018 ._input-stepper_1boag_844 > ._input_1boag_309 {
      height: 18px;
      color: #384f68;
      font-size: 12px;
      padding-right: 2px;
      padding-left: 1px;
      line-height: 14px; }

._stepper-with-svg_1boag_3018 {
  margin-right: 12px;
  font-size: 12px; }
  ._stepper-with-svg_1boag_3018 ._symbol_1boag_544 {
    fill: #384f68;
    position: relative;
    bottom: 3px;
    margin-left: 2px; }
    ._stepper-with-svg_1boag_3018 ._symbol_1boag_544._safari_1boag_3143 {
      top: 3px;
      bottom: 0; }
    ._stepper-with-svg_1boag_3018 ._symbol_1boag_544:hover {
      fill: #2b81cb;
      cursor: pointer; }
  ._stepper-with-svg_1boag_3018 ._edited_1boag_2993 + ._symbol_1boag_544 {
    fill: #bcbcbc;
    pointer-events: none;
    cursor: default; }

._property-form_1boag_3154 ._errorMessage_1boag_3154 {
  color: #f00; }

._property-form_1boag_3154 span._label_1boag_617 {
  display: block;
  margin-bottom: 5px; }

._menu-panel_1boag_3161 {
  min-width: 250px;
  min-height: 300px; }
  ._menu-panel_1boag_3161 header {
    font-size: 16px;
    color: #000000;
    position: relative;
    margin-bottom: 10px; }
    ._menu-panel_1boag_3161 header a {
      position: absolute;
      top: -10px;
      right: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      font-size: 16px;
      color: #000;
      text-decoration: none; }
  ._menu-panel_1boag_3161 ul {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden; }
    ._menu-panel_1boag_3161 ul li {
      color: #333;
      border-bottom: 1px solid #fff;
      padding: 5px 5px;
      cursor: pointer; }
      ._menu-panel_1boag_3161 ul li:hover {
        background-color: #b2f3ff; }

._dropdown_1boag_349 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  position: relative; }
  ._dropdown_1boag_349._font-family-dd_1boag_3199._toolbar-mode_1boag_3199, ._dropdown_1boag_349._text-style-dd_1boag_3199._toolbar-mode_1boag_3199 {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 5px;
    padding-right: 0;
    min-width: 140px; }
  ._dropdown_1boag_349._context-menu_1boag_3205 {
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 50%;
    line-height: 24px;
    text-align: center; }
    ._dropdown_1boag_349._context-menu_1boag_3205._wix-code-pages-tree-dd_1boag_3212, ._dropdown_1boag_349._context-menu_1boag_3205._wix-code-file-tree-dd_1boag_3212 {
      border: none;
      width: 16px;
      height: 16px;
      line-height: 16px; }
  ._dropdown_1boag_349._units-dd_1boag_3000 {
    display: inline-block;
    padding-left: 5px; }
  ._dropdown_1boag_349:focus,
  ._dropdown_1boag_349 > ._dd_1boag_3221:focus {
    outline: none; }
  ._dropdown_1boag_349 > ._dd_1boag_3221,
  ._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
    margin: 0;
    padding: 0; }
  ._dropdown_1boag_349 > ._dropdown-label_1boag_3227 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 3px 0;
    padding: 0;
    color: #3b4057;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 20px); }
    ._select_1boag_2248._disabled_1boag_788._dropdown_1boag_349 > ._dropdown-label_1boag_3227 {
      color: #939393; }
  ._dropdown_1boag_349 > ._dd_1boag_3221 {
    position: relative;
    cursor: pointer; }
    ._disabled_1boag_788._dropdown_1boag_349 > ._dd_1boag_3221 {
      cursor: default; }
    ._context-menu_1boag_3205._dropdown_1boag_349 > ._dd_1boag_3221 {
      height: 100%; }
    ._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
      position: relative;
      box-sizing: border-box;
      color: #000624;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      padding: 0 30px 0 0;
      margin: 0; }
      ._input-slider_1boag_2785._dropdown_1boag_349:hover > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        color: #116dff; }
      ._text-link-dd_1boag_3262._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        height: 32px;
        line-height: 32px;
        min-height: 32px;
        width: 32px;
        padding-right: 0; }
      ._disabled_1boag_788._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        color: #7a7a7a; }
      ._context-menu_1boag_3205._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        height: 30px;
        line-height: 30px;
        min-height: 30px; }
      ._units-dd_1boag_3000._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        padding-right: 12px;
        font-size: 14px;
        line-height: 14px;
        height: 14px;
        min-height: 14px; }
      ._wix-code-pages-tree-dd_1boag_3212._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      ._wix-code-file-tree-dd_1boag_3212._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      ._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 > ._symbol-more_1boag_3288 > ._notification_1boag_3288 {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        width: 20px;
        height: 20px;
        top: -10px;
        font-size: 10px;
        border-radius: 10px;
        line-height: 18px;
        border: 1px solid #fff;
        position: absolute;
        left: 10px;
        background-color: #ffc233;
        color: #fff;
        box-sizing: border-box; }
      ._dropdown_1boag_349 > ._dd_1boag_3221 > ._selected-container_1boag_3250 > ._symbol-more_1boag_3288._shrink_1boag_3306 > ._notification_1boag_3288 {
        width: 14px;
        height: 14px;
        top: -7px;
        font-size: 7px;
        border-radius: 7px;
        line-height: 12px; }
    ._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
      border: 2px solid #116dff;
      border-radius: 50%;
      content: '';
      display: block;
      height: 22px;
      opacity: 0.6;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 22px; }
      ._select_1boag_2248._disabled_1boag_788._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        border-color: #bcbcbc; }
        ._select_1boag_2248._disabled_1boag_788._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296:hover {
          background-color: transparent; }
      ._font-family-dd_1boag_3199._toolbar-mode_1boag_3199._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296, ._units-dd_1boag_3000._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        border: none;
        width: 9px;
        opacity: 1; }
        ._font-family-dd_1boag_3199._toolbar-mode_1boag_3199._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296:hover, ._units-dd_1boag_3000._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296:hover {
          background-color: transparent; }
      ._input-stepper_1boag_844._dropdown_1boag_349:hover > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        opacity: 1; }
      ._input-slider_1boag_2785:hover ._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        opacity: 1; }
      ._units-dd_1boag_3000._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        opacity: 0; }
      ._toolbar-mode_1boag_3199._font-family-dd_1boag_3199._dropdown_1boag_349 > ._dd_1boag_3221 > ._expand_1boag_1914._arrow_1boag_1296 {
        right: 8px; }

._combo-box_1boag_3344 > ._combo-box-input_1boag_3344 {
  border: none;
  width: 100%;
  padding: 0; }
  ._font-family-dd_1boag_3199._toolbar-mode_1boag_3199 ._combo-box_1boag_3344 > ._combo-box-input_1boag_3344 {
    font-size: 16px; }
  ._combo-box_1boag_3344 > ._combo-box-input_1boag_3344:focus {
    outline: none; }

._font-family-dd_1boag_3199._toolbar-mode_1boag_3199 ._combo-box_1boag_3344._selected_1boag_2248 {
  width: 130px; }

._dropdown_1boag_349:not(._disabled_1boag_788):hover ._combo-box_1boag_3344 {
  color: #116dff; }

._dropdown_1boag_349._combobox_1boag_3359 ._combo-box_1boag_3344 {
  overflow: inherit;
  text-overflow: inherit; }

._dropdown-options_1boag_349 {
  left: 0;
  position: fixed;
  top: 0; }
  ._dropdown-options_1boag_349._expanded_1boag_3367 {
    bottom: 0;
    right: 0;
    z-index: 5998 !important; }
  ._dropdown-options_1boag_349 > ._options_1boag_3371 {
    display: none;
    position: fixed;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
    flex-direction: column;
    max-height: 100%; }
    ._expanded_1boag_3367._dropdown-options_1boag_349 > ._options_1boag_3371 {
      display: flex !important; }
      ._expanded_1boag_3367._dropdown-options_1boag_349 > ._options_1boag_3371._scroll_1boag_3381._up_1boag_3381 > ._arrow_1boag_1296._top_1boag_2418,
      ._expanded_1boag_3367._dropdown-options_1boag_349 > ._options_1boag_3371._scroll_1boag_3381._down_1boag_3382 > ._arrow_1boag_1296._bottom_1boag_2420 {
        visibility: visible; }
    ._select_1boag_2248._dropdown-options_1boag_349 > ._options_1boag_3371, ._combobox_1boag_3359._dropdown-options_1boag_349 > ._options_1boag_3371 {
      overflow: hidden; }
    ._context-menu_1boag_3205._dropdown-options_1boag_349 > ._options_1boag_3371._tag-triangle_1boag_3386._tag-left_1boag_3386._tag-side-top_1boag_3386:before, ._context-menu_1boag_3205._dropdown-options_1boag_349 > ._options_1boag_3371._tag-triangle_1boag_3386._tag-right_1boag_3386._tag-side-top_1boag_3386:before {
      top: 17px; }
    ._context-menu_1boag_3205._dropdown-options_1boag_349 > ._options_1boag_3371._tag-triangle_1boag_3386._tag-left_1boag_3386._tag-side-bottom_1boag_3388:before, ._context-menu_1boag_3205._dropdown-options_1boag_349 > ._options_1boag_3371._tag-triangle_1boag_3386._tag-right_1boag_3386._tag-side-bottom_1boag_3388:before {
      bottom: 17px; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371,
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._top_1boag_2418._arrow_1boag_1296,
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._list_1boag_1811,
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._options-footer_1boag_3393,
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._bottom_1boag_2420._arrow_1boag_1296,
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._mouse-events-blocker_1boag_3395 {
      margin: 0;
      padding: 0; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371:focus {
      outline: none; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._list_1boag_1811 {
      display: block;
      max-height: 100%;
      overflow: hidden; }
      ._context-menu_1boag_3205._dropdown-options_1boag_349 > ._options_1boag_3371 > ._list_1boag_1811 {
        box-sizing: border-box;
        padding: 10px 0; }
      ._combobox_1boag_3359._dropdown-options_1boag_349 > ._options_1boag_3371 > ._list_1boag_1811 {
        overflow-y: auto; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._arrow_1boag_1296 {
      background-color: rgba(255, 255, 255, 0.93);
      display: block;
      font-size: 8px;
      height: 36px;
      left: 0;
      opacity: 0.6;
      position: absolute;
      width: 100%;
      visibility: hidden;
      z-index: 1; }
      ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._arrow_1boag_1296._top_1boag_2418 {
        top: 0;
        transform: rotateX(180deg); }
      ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._arrow_1boag_1296._bottom_1boag_2420 {
        bottom: 0; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._options-footer_1boag_3393 {
      flex-shrink: 0; }
      ._combobox_1boag_3359._dropdown-options_1boag_349 > ._options_1boag_3371 > ._options-footer_1boag_3393 {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        background-color: #fff;
        box-shadow: 0 -1px 23px 0 #fff;
        color: #116dff;
        line-height: 35px;
        height: 35px;
        padding: 0 24px;
        z-index: 0; }
    ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._mouse-events-blocker_1boag_3395 {
      display: none; }
      ._dropdown-options_1boag_349 > ._options_1boag_3371 > ._mouse-events-blocker_1boag_3395._visible_1boag_3442 {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0; }

._dropdown-option_1boag_349 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  position: relative; }
  ._dropdown-option_1boag_349._hovered_1boag_3462:not(._separator_1boag_3462):not(._disabled_1boag_788) {
    background-color: #e7f0ff; }
  ._dropdown-option_1boag_349._disabled_1boag_788 {
    cursor: default; }
    ._dropdown-option_1boag_349._disabled_1boag_788 ._wix-code-context-menu-item_1boag_3466 {
      color: #939393; }
    ._dropdown-option_1boag_349._disabled_1boag_788 path {
      fill: #939393; }
  ._dropdown-option_1boag_349._level1_1boag_3470 {
    padding-left: 30px; }
  ._dropdown-option_1boag_349._level2_1boag_3472 {
    padding-left: 40px; }
  ._dropdown-option_1boag_349._level3_1boag_3474 {
    padding-left: 50px; }
  ._dropdown-option_1boag_349._separator_1boag_3462 {
    height: 1px; }
  ._dropdown-option_1boag_349._font-option_1boag_3478 {
    height: 24px;
    padding: 0 14px; }
  ._dropdown-option_1boag_349._units-dropdown_1boag_3481 {
    text-align: center;
    padding: 0; }
  ._dropdown-option_1boag_349._link-dd-option_1boag_3484 {
    padding: 4px 11px; }
  ._optgroup-list_1boag_3486 ._dropdown-option_1boag_349 {
    padding: 0 40px; }
    ._dropdown-options_1boag_349._combobox_1boag_3359 ._optgroup-list_1boag_3486 ._dropdown-option_1boag_349 {
      padding: 0 24px; }
  ._dropdown-options_1boag_349._select_1boag_2248 ._dropdown-option_1boag_349._selected_1boag_2248,
  ._dropdown-options_1boag_349._combobox_1boag_3359 ._dropdown-option_1boag_349._selected_1boag_2248 {
    background-color: #116dff;
    color: #fff; }
  ._units-dd_1boag_3000 ._dropdown-option_1boag_349 {
    padding: 0 10px; }

._dropdown-optgroup_1boag_3497 ._optgroup-label_1boag_3497 {
  background-color: #f5f5f5;
  color: #3b4057;
  font-size: 14px;
  font-weight: 300;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  margin: 0; }
  ._combobox_1boag_3359 ._dropdown-optgroup_1boag_3497 ._optgroup-label_1boag_3497 {
    height: 36px;
    padding: 0 24px;
    background-color: #f7f8f8;
    border-top: 1px solid #dfe5eb;
    border-bottom: 1px solid #dfe5eb;
    cursor: default; }

._dropdown-optgroup_1boag_3497 > ._optgroup-list_1boag_3486 {
  padding: 0; }

._dropdown_1boag_349._select_1boag_2248 ._dropdown-selected_1boag_3517 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

._icons-menu-button-wrapper_1boag_3522 {
  position: relative;
  height: 34px;
  width: 34px;
  display: inline-block;
  cursor: pointer; }
  ._icons-menu-button-wrapper_1boag_3522:hover, ._icons-menu-button-wrapper_1boag_3522._active_1boag_3528 {
    background: #eaf7ff;
    border-radius: 6px; }
  ._icons-menu-button-wrapper_1boag_3522 ._small-indicator_1boag_3531 {
    position: absolute;
    box-sizing: border-box;
    width: 4px;
    height: 4px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 2px solid #116dff;
    border-right: 2px solid #116dff;
    bottom: 3px;
    right: 3px; }

._dropdown_1boag_349._icons-menu_1boag_3522 > ._dd_1boag_3221 > ._selected-container_1boag_3250 {
  padding: 0; }

._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371 {
  padding: 12px 0; }
  ._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371._tag-triangle_1boag_3386._tag-top_1boag_3548::before, ._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371._tag-triangle_1boag_3386._tag-bottom_1boag_3548::before {
    left: calc(50% - 5px); }
  ._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371 ._dropdown-option_1boag_349 {
    padding: 0 18px; }
    ._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371 ._dropdown-option_1boag_349 ._symbol-wrapper_1boag_3552 {
      height: 100%;
      display: inline-block; }
      ._dropdown-options_1boag_349._icons-menu_1boag_3522 ._options_1boag_3371 ._dropdown-option_1boag_349 ._symbol-wrapper_1boag_3552 ._symbol_1boag_544 {
        padding: 0 10px 0 0;
        margin-bottom: -2px; }

._control-checkable-text-input_1boag_3559 {
  margin: 0 12px 0 30px; }
  ._control-checkable-text-input_1boag_3559 ._checkable-text-input_1boag_3561 {
    align-items: center;
    display: flex;
    height: 59px; }
    ._control-checkable-text-input_1boag_3559 ._checkable-text-input_1boag_3561 ._control-text-input_1boag_1599,
    ._settings-panel_1boag_3566 ._control-checkable-text-input_1boag_3559 ._checkable-text-input_1boag_3561 ._control-text-input_1boag_1599 {
      margin: 0 0 0 12px; }
  ._control-checkable-text-input_1boag_3559 + hr._divider-long_1boag_1763 {
    margin: 0 24px 0 30px; }

._control-numeric-input_1boag_3571 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; }
  ._control-numeric-input_1boag_3571 ._label_1boag_617 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    padding: 18px 0 16px 24px;
    vertical-align: middle;
    color: #3b4057;
    font-size: 14px;
    line-height: 1.8;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  ._control-numeric-input_1boag_3571 ._input-stepper_1boag_844 {
    margin-right: 6px; }

._domain-input-container_1boag_3593 {
  position: relative; }
  ._domain-input-container_1boag_3593 ._domain-input-loader_1boag_3595 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 18px; }
  ._domain-input-container_1boag_3593._with-edit-buttons_1boag_3603 ._domain-input-loader_1boag_3595 {
    right: 78px; }
  ._domain-input-container_1boag_3593 ._input-validation-icon_1boag_3605 {
    visibility: hidden; }
  ._domain-input-container_1boag_3593 ._input-editable-url_1boag_3607 {
    min-width: 100%; }
  ._domain-input-container_1boag_3593 ._control-button_1boag_544._btn-edit_1boag_3609 {
    width: auto;
    min-width: 66px;
    max-width: 106px;
    padding: 0 12px; }

._control-radio-list_1boag_3615 {
  padding: 10px 20px 5px 24px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  ._control-radio-list_1boag_3615 ._title_1boag_1623 {
    color: #3b4057;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 3px;
    text-align: left;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._control-radio-list_1boag_3615 label {
    display: block;
    padding: 15px 0 15px 4px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #dfe5eb; }
    ._control-radio-list_1boag_3615 label div:nth-of-type(1) {
      margin: 0 0 0 14px;
      vertical-align: middle;
      box-sizing: border-box;
      display: inline-block; }
      ._control-radio-list_1boag_3615 label div:nth-of-type(1) div {
        display: table-cell;
        width: 54px;
        height: 42px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #e5e5e5;
        border-radius: 5px; }
        ._control-radio-list_1boag_3615 label div:nth-of-type(1) div span {
          font-size: 30px; }
    ._control-radio-list_1boag_3615 label:first-child {
      border-top: none; }
    ._control-radio-list_1boag_3615 label:last-child {
      border-bottom-color: transparent; }
    ._control-radio-list_1boag_3615 label._highlight-on-hover_1boag_3656:hover span:nth-of-type(2) {
      color: #5c99e8; }
    ._control-radio-list_1boag_3615 label._highlight-on-hover_1boag_3656:hover ._editor-mode-option-symbol_1boag_3658 svg g {
      fill: #5c99e8; }
  ._control-radio-list_1boag_3615 span:nth-of-type(2) {
    display: inline-block;
    color: #2a3742;
    margin-left: 14px;
    vertical-align: middle; }
  ._control-radio-list_1boag_3615 ._info-icon_1boag_1039 {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  ._control-radio-list_1boag_3615:hover ._info-icon_1boag_1039 {
    display: inline-block; }
  ._control-radio-list_1boag_3615 input {
    display: none; }
    ._control-radio-list_1boag_3615 input + span {
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      width: 12px;
      box-shadow: 0 0 0 1px #5c99e8;
      border: solid 5px #fff;
      transition-property: background-color, border-width;
      transition-duration: 0.15s;
      box-sizing: border-box;
      vertical-align: middle; }
    ._control-radio-list_1boag_3615 input:checked + span {
      background-color: #5c99e8;
      border-width: 1px; }

._control-checkbox-with-button_1boag_3689 {
  white-space: nowrap; }
  ._control-checkbox-with-button_1boag_3689 ._controls-wrapper_1boag_3691 {
    height: 24px;
    display: flex;
    align-items: center; }
    ._control-checkbox-with-button_1boag_3689 ._controls-wrapper_1boag_3691 ._checkbox-button_1boag_3695 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      height: 24px;
      line-height: 24px;
      display: none;
      border-radius: 12px;
      margin-left: auto;
      overflow: visible; }
  ._control-checkbox-with-button_1boag_3689:hover {
    background-color: #e7f0ff; }
    ._control-checkbox-with-button_1boag_3689:hover ._checkbox-button_1boag_3695 {
      display: inline-block;
      opacity: 1;
      transition: 0.2s opacity; }
  ._control-checkbox-with-button_1boag_3689:not(:hover) ._checkbox-button_1boag_3695 {
    opacity: 0;
    width: 0;
    padding: 0; }

._color-scheme-picker_1boag_3718 ._control-switch_1boag_3718._invert-color-toggle_1boag_3718 {
  padding-top: 10px; }

._color-scheme-picker_1boag_3718 ._control-switch_1boag_3718 ._label_1boag_617 {
  align-self: baseline; }

._action-container_1boag_3724 {
  position: relative;
  height: 72px;
  cursor: pointer; }
  ._action-container_1boag_3724 > ._action-symbol_1boag_3728 {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #116dff;
    border-radius: 50%;
    margin: auto 26px;
    width: 24px;
    height: 24px; }
    ._action-container_1boag_3724:hover > ._action-symbol_1boag_3728 {
      background: #5999ff; }
    ._symbol-right_1boag_3739._action-container_1boag_3724 > ._action-symbol_1boag_3728 {
      right: 0; }
    ._action-container_1boag_3724 > ._action-symbol_1boag_3728 > ._symbol_1boag_544 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-right: 1px; }
      ._action-container_1boag_3724 > ._action-symbol_1boag_3728 > ._symbol_1boag_544 > path {
        fill: #fff; }
  ._action-container_1boag_3724 > ._action-label_1boag_3751 {
    position: absolute;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 24px;
    height: 20px;
    color: #116dff;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px; }
    ._action-container_1boag_3724:hover > ._action-label_1boag_3751 {
      text-decoration: underline;
      color: #5999ff; }
    ._symbol-left_1boag_3766._action-container_1boag_3724 > ._action-label_1boag_3751 {
      left: 34px;
      right: 0; }
    ._symbol-right_1boag_3739._action-container_1boag_3724 > ._action-label_1boag_3751 {
      right: 45px;
      left: 0; }

._media-button_1boag_3773 > ._media-button-filename-text_1boag_3773 {
  text-align: center;
  margin-top: 16px; }
  ._media-button_1boag_3773 > ._media-button-filename-text_1boag_3773 > ._wrapper_1boag_3776 > span {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000624;
    vertical-align: middle;
    display: inline-block;
    max-width: calc(100% - 48px); }

._image-preview_1boag_3790 {
  background-color: #e7f0ff;
  box-sizing: border-box;
  height: 155px;
  position: relative;
  text-align: center;
  width: 288px; }
  ._image-preview_1boag_3790 ._image-wrapper_1boag_3797 {
    font-size: 0;
    height: 100%; }
    ._image-preview_1boag_3790 ._image-wrapper_1boag_3797 > ._image_1boag_878 {
      width: 100%;
      height: 100%; }
  ._image-preview_1boag_3790 ._button-wrapper_1boag_547 {
    background-color: rgba(177, 221, 248, 0.4);
    bottom: 0;
    display: block;
    height: 54px;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%; }
    ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 {
      background-color: #fff;
      border: 0;
      border-radius: 17px;
      color: #116dff;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      margin-top: 11px !important;
      padding: 0;
      outline: none;
      text-overflow: clip;
      vertical-align: top; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103._has-icon_1boag_3827._has-label_1boag_1621 span {
        padding: 0 34px; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103._has-icon_1boag_3827:not(._has-label_1boag_1621) {
        padding: 0 9px;
        margin-left: 0;
        margin-right: 10px; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(1) {
        min-width: 167px;
        max-width: 204px; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 24px; }
        ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2),
        ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2) + button {
          width: 108px; }
        ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2) + button {
          margin-left: 0; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(3) {
        width: 152px;
        margin-left: 24px;
        margin-right: 12px;
        float: left; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(2):nth-last-of-type(2) {
        float: right;
        margin-right: 24px; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(3):nth-last-of-type(1) {
        float: right;
        margin-right: 12px; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:hover {
        background-color: #e7f0ff; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103:active {
        background-color: #fff; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 > ._button-content_1boag_3858 {
        position: relative; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 svg {
        width: 14px;
        height: 32px; }
        ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 svg._symbol-with-label_1boag_544 {
          top: 0;
          left: 12px;
          position: absolute; }
        ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 svg * {
          fill: #116dff; }
      ._image-preview_1boag_3790 ._button-wrapper_1boag_547 button._action_1boag_2103 span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #116dff;
        line-height: 17px;
        text-align: left;
        left: 3px;
        position: relative; }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880._symbol-in-the-middle_1boag_3885 svg {
    transform: translate(-50%, -50%); }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887:before, ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887:after {
    border: solid #5c99e8;
    content: '';
    height: 10px;
    margin: 25px;
    position: absolute;
    width: 10px; }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887._top_1boag_2418:before {
    border-width: 1px 0 0 1px;
    left: 0;
    top: 0; }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887._top_1boag_2418:after {
    border-width: 1px 1px 0 0;
    right: 0;
    top: 0; }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887._bottom_1boag_2420:before {
    border-width: 0 0 1px 1px;
    bottom: 0;
    left: 0; }
  ._image-preview_1boag_3790 ._placeholder-wrapper_1boag_3880 ._corner-borders_1boag_3887._bottom_1boag_2420:after {
    border-width: 0 1px 1px 0;
    bottom: 0;
    right: 0; }
  ._image-preview_1boag_3790 ._arrow-button_1boag_1918 {
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.43);
    cursor: pointer;
    height: 24px;
    position: absolute;
    top: 50%;
    width: 24px; }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918:after {
      border-left: 2px solid #0199ef;
      border-top: 2px solid #0199ef;
      content: '';
      height: 6px;
      left: calc(50% - 2px);
      position: absolute;
      top: calc(50% - 4px);
      transform: rotate(-45deg);
      width: 6px; }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918._prev_1boag_3928 {
      left: 0;
      margin: -12px 0 0 12px; }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918._next_1boag_3931 {
      right: 0;
      margin: -12px 12px 0 0; }
      ._image-preview_1boag_3790 ._arrow-button_1boag_1918._next_1boag_3931:after {
        left: calc(50% - 5px);
        top: calc(50% - 4px);
        transform: rotate(135deg); }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918:hover {
      background-color: rgba(56, 153, 236, 0.9); }
      ._image-preview_1boag_3790 ._arrow-button_1boag_1918:hover:after {
        border-color: #fff; }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918[disabled] {
      cursor: default; }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918, ._image-preview_1boag_3790 ._arrow-button_1boag_1918[disabled], ._image-preview_1boag_3790 ._arrow-button_1boag_1918[disabled]:hover {
      background-color: rgba(255, 255, 255, 0.9); }
    ._image-preview_1boag_3790 ._arrow-button_1boag_1918[disabled]:after, ._image-preview_1boag_3790 ._arrow-button_1boag_1918[disabled]:hover:after {
      border-color: #bcbcbc; }

._video-input_1boag_3949 {
  width: 288px;
  height: 155px;
  box-sizing: border-box;
  background-color: #e7f0ff;
  position: relative;
  text-align: center; }
  ._video-input_1boag_3949 ._video-wrapper_1boag_3956 {
    height: 100%;
    font-size: 0; }
    ._video-input_1boag_3949 ._video-wrapper_1boag_3956 > iframe {
      border: none; }
    ._video-input_1boag_3949 ._video-wrapper_1boag_3956,
    ._video-input_1boag_3949 ._video-wrapper_1boag_3956 > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  ._video-input_1boag_3949 ._button-wrapper_1boag_547 {
    height: 54px;
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    background-color: rgba(177, 221, 248, 0.4); }
    ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103 {
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      border: 0;
      border-radius: 17px;
      background-color: #fff;
      cursor: pointer;
      outline: none;
      color: #116dff;
      font-size: 14px;
      vertical-align: top;
      text-overflow: clip; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103._has-icon_1boag_3827 {
        padding-left: 0; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(1) {
        width: 167px; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 23px; }
        ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2),
        ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:nth-of-type(1):nth-last-of-type(2) + button {
          width: calc(50% - 2 * 23px); }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:hover {
        background-color: #e7f0ff; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103:active {
        background-color: #fff; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103 svg {
        float: left;
        margin: 0 20px 0 0 !important;
        width: 14px;
        height: 32px; }
      ._video-input_1boag_3949 ._button-wrapper_1boag_547 button._action_1boag_2103 span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
        font-weight: var(--wbu-font-weight-regular);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        color: #116dff;
        line-height: 17px;
        text-align: left; }
  ._video-input_1boag_3949 ._placeholder-wrapper_1boag_3880 svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
    ._video-input_1boag_3949 ._placeholder-wrapper_1boag_3880 svg path {
      fill: #116dff; }
  ._video-input_1boag_3949 ._video-controls_1boag_4024 {
    width: 100%;
    height: 100%; }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._blocking-layer_1boag_4027 {
      background: #000;
      width: 100%;
      height: 100%; }
      ._video-input_1boag_3949 ._video-controls_1boag_4024 ._blocking-layer_1boag_4027._play_1boag_1697 {
        opacity: 0; }
      ._video-input_1boag_3949 ._video-controls_1boag_4024 ._blocking-layer_1boag_4027._pause_1boag_4033 {
        opacity: 0.4; }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._pause-button_1boag_4035,
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._play-button_1boag_4036 {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      position: absolute;
      margin: 46px 0 65px 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      top: 6px; }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._pause-button_1boag_4035 ._pause_1boag_4033 {
      display: block;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      width: 10%;
      height: 40%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%); }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._pause-button_1boag_4035:hover {
      border-color: #116dff;
      cursor: pointer; }
      ._video-input_1boag_3949 ._video-controls_1boag_4024 ._pause-button_1boag_4035:hover ._pause_1boag_4033 {
        border-left-color: #116dff;
        border-right-color: #116dff;
        cursor: pointer; }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._play-button_1boag_4036 ._triangle_1boag_4063 {
      width: 0;
      height: 0;
      position: relative;
      display: block;
      border-color: inherit;
      border-left: 24px solid #fff;
      border-bottom: 14px transparent solid;
      border-top: 14px transparent solid;
      top: 50%;
      left: calc(50% + 4px);
      transform: translate(-50%, -50%); }
    ._video-input_1boag_3949 ._video-controls_1boag_4024 ._play-button_1boag_4036:hover {
      border-color: #116dff;
      cursor: pointer; }
      ._video-input_1boag_3949 ._video-controls_1boag_4024 ._play-button_1boag_4036:hover ._triangle_1boag_4063 {
        border-left-color: #116dff;
        border-right-color: #116dff;
        cursor: pointer; }

._video-selector_1boag_4083._bg-panel-top_1boag_4083 {
  position: relative;
  height: 162px; }

._video-selector_1boag_4083 ._bg-panel-preview_1boag_4087 {
  height: 100%; }

._video-selector_1boag_4083 ._bg-panel-preview-media_1boag_4090 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7f0ff; }

._video-selector_1boag_4083 ._bg-panel-top-actions_1boag_4096 {
  position: absolute;
  bottom: 0;
  height: 54px;
  width: 100%;
  background: rgba(177, 221, 248, 0.4);
  display: flex;
  align-items: center;
  justify-content: center; }
  ._video-selector_1boag_4083 ._bg-panel-top-actions_1boag_4096 ._bg-panel-action_1boag_4105 ._btn-confirm-secondary_1boag_579 {
    background-color: #ffffff; }
    ._video-selector_1boag_4083 ._bg-panel-top-actions_1boag_4096 ._bg-panel-action_1boag_4105 ._btn-confirm-secondary_1boag_579:hover {
      background-color: #e7f0ff; }

._video-selector_1boag_4083 ._change-media-button-text_1boag_4110 {
  padding-left: 10px; }

._video-selector_1boag_4083 ._bg-panel-preview-buttons_1boag_4113 {
  position: relative;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center; }

._video-preview_1boag_4120 {
  position: relative;
  height: 162px;
  background: #000000; }
  ._video-preview__cover_1boag_4124 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    ._video-preview__cover_1boag_4124 > ._media-panel-play-button_1boag_4133 {
      margin: 0; }
  ._video-preview__poster_1boag_4135 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  ._video-preview__player_1boag_4141 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  ._video-preview__embedded-player_1boag_4147 {
    height: 100%;
    font-size: 0;
    opacity: 0.8; }
    ._video-preview__embedded-player_1boag_4147 > iframe {
      border: none; }
    ._video-preview__embedded-player_1boag_4147,
    ._video-preview__embedded-player_1boag_4147 > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  ._video-preview__button-wrapper_1boag_4160 {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    display: flex;
    align-items: center;
    justify-content: center; }
  ._video-preview_1boag_4120 ._change-media-button-text_1boag_4110 {
    padding-left: 10px; }

._control-rich-text_1boag_4171 ._video-type-selector_1boag_4171 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 8px -6px -4px; }

._video-type-selector__item_1boag_4178 {
  flex: 0 0 51px;
  max-width: 51px;
  margin: 0 6px 12px !important; }

._video-type-selector__btn_1boag_4183 {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  width: 51px;
  height: 51px;
  text-align: center; }
  ._video-type-selector__btn_1boag_4183:focus, ._video-type-selector__btn_1boag_4183:active {
    outline: 0; }
  ._video-type-selector__btn_1boag_4183:hover ._video-type-selector__btn-icon_1boag_4193 {
    padding: 0;
    border: 2px solid #d6e6fe; }
  ._video-type-selector__btn_1boag_4183._active_1boag_3528 ._video-type-selector__btn-icon_1boag_4193 {
    padding: 0;
    border: 2px solid #116dff; }
  ._video-type-selector__btn-icon_1boag_4193 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 1px;
    margin: 0 0 6px;
    border: 1px solid #dfe5eb;
    border-radius: 4px; }
    ._video-type-selector__btn-icon_1boag_4193 > svg {
      display: block; }

._video-upload-button_1boag_4213._control-upload-button_1boag_4213 > ._control-button_1boag_544
> ._button-content_1boag_3858
._symbol_1boag_544
> ._symbol_1boag_544 {
  padding-right: 0; }

._video-upload-button_1boag_4213._control-upload-button_1boag_4213 > ._control-button_1boag_544 {
  margin: 0 !important; }

._control-text-input-with-button_1boag_4222 {
  position: relative; }
  ._control-text-input-with-button_1boag_4222:hover ._info-icon_1boag_1039 {
    display: inline-block; }
  ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > input,
  ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > textarea {
    background-color: #e7f0ff;
    color: #3b4057; }
    ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > input::placeholder,
    ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > textarea::placeholder {
      color: #3b4057; }
  ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > input:hover,
  ._control-text-input-with-button_1boag_4222:hover ._control-text-input_1boag_1599:not(._focused_1boag_2720) > textarea:hover {
    background-color: #d6e6fe;
    color: #3b4057; }
  ._control-text-input-with-button_1boag_4222 ._control-text-input_1boag_1599 {
    margin: 12px 0 !important;
    width: 100% !important; }
  ._control-text-input-with-button_1boag_4222 ._control-button_1boag_544 {
    margin: 0 auto 18px !important;
    display: block;
    padding: 7px 24px;
    width: auto;
    min-width: 0;
    line-height: 14px !important;
    font-size: 14px;
    height: 30px; }

._control-text-input-with-fixed-button_1boag_4250 {
  display: flex;
  align-items: center; }
  ._control-text-input-with-fixed-button_1boag_4250 > ._control-text_1boag_1599 {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex-grow: 1;
    color: #3b4057;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: solid 1px #88ccf8;
    background-color: #fff;
    height: 23px;
    padding-left: 15px;
    width: 0; }
  ._control-text-input-with-fixed-button_1boag_4250 > ._control-button_1boag_544 {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    flex-shrink: 0;
    margin-left: 6px;
    height: 20px;
    border-radius: 12px;
    border-width: 2px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 9px; }
    ._control-text-input-with-fixed-button_1boag_4250 > ._control-button_1boag_544._disabled_1boag_788 {
      background-color: #bdbdbd; }
  ._control-text-input-with-fixed-button_1boag_4250._invalid_1boag_4287 > ._control-text_1boag_1599 {
    border-color: #d82646; }

._control-thumbnailsSlider_1boag_4290 {
  white-space: nowrap;
  height: 68px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: #fff; }
  ._control-thumbnailsSlider_1boag_4290 ._itemsContainer_1boag_4297 {
    visibility: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    transition-property: left;
    transition-duration: 0.3s;
    top: 50%; }
  ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296 {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 24px;
    height: 100%;
    cursor: pointer; }
    ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296 span {
      width: 8px;
      height: 8px;
      position: relative;
      top: calc(50% - 10px);
      left: calc(50% - 4px);
      border-top: 1px solid #116dff;
      border-right: 1px solid #116dff;
      display: inline-block; }
    ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296._left_1boag_1144 {
      left: 0; }
      ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296._left_1boag_1144 span {
        transform: rotate(225deg);
        left: 10px; }
    ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296._right_1boag_1142 {
      right: 0; }
      ._control-thumbnailsSlider_1boag_4290 ._arrow_1boag_1296._right_1boag_1142 span {
        transform: rotate(45deg);
        left: 5px; }
  ._control-thumbnailsSlider_1boag_4290 input {
    display: none; }
    ._control-thumbnailsSlider_1boag_4290 input:hover:checked + img, ._control-thumbnailsSlider_1boag_4290 input:checked + img {
      border-radius: 6px;
      border-color: #ff7d38;
      background-color: transparent; }
  ._control-thumbnailsSlider_1boag_4290 img {
    margin-right: 6px;
    border: solid 2px transparent; }
  ._control-thumbnailsSlider_1boag_4290 label {
    height: 100%;
    display: inline-block;
    font-size: 0;
    cursor: pointer; }
    ._control-thumbnailsSlider_1boag_4290 label:hover img {
      border-radius: 6px;
      border-color: #80b1ff;
      background-color: rgba(127, 204, 247, 0.2); }

._proportions-slider_1boag_4349 {
  border-radius: 4px;
  height: 25px;
  background-color: #116dff;
  margin: 13px auto 28px;
  padding: 12px; }
  ._proportions-slider_1boag_4349 > ._column_1boag_4355 {
    background-color: #e7f0ff;
    height: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: #116dff;
    min-width: 2px; }
    ._proportions-slider_1boag_4349 > ._column_1boag_4355 > ._label_1boag_617 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: -32px;
      width: 100%; }
    ._proportions-slider_1boag_4349 > ._column_1boag_4355 > ._divider_1boag_1763 {
      position: absolute;
      left: -6.5px;
      top: 0;
      width: 13px;
      height: 100%;
      background-color: #116dff;
      cursor: col-resize; }
      ._proportions-slider_1boag_4349:hover > ._column_1boag_4355 > ._divider_1boag_1763 {
        background-color: #0057e1; }
      ._proportions-slider_1boag_4349 > ._column_1boag_4355 > ._divider_1boag_1763:hover {
        background-color: #5999ff; }

._label-with-circle-button_1boag_4386 {
  position: relative;
  text-align: left;
  padding: 12px; }
  ._label-with-circle-button_1boag_4386 label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    line-height: 100%;
    color: #3b4057;
    text-align: left;
    display: block;
    padding: 5px 30px 3px 12px; }
  ._label-with-circle-button_1boag_4386 ._link-to-icon-info_1boag_4401 {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  ._label-with-circle-button_1boag_4386:hover:not(._disabled_1boag_788) ._link-to-icon-info_1boag_4401 {
    display: inherit; }
  ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 {
    padding: 0 11px; }
    ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._link_1boag_3484 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 34px;
      height: 36px; }
      ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._link_1boag_3484::placeholder {
        color: #b6c1cd; }
      ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._link_1boag_3484:hover::placeholder {
        color: #3b4057; }
    ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._validation-icon_1boag_2713 {
      font-size: 0; }
      ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._validation-icon_1boag_2713._validation-icon-success_1boag_2722 {
        border: none;
        background-color: transparent; }
        ._label-with-circle-button_1boag_4386 ._container-action_1boag_4408 ._validation-icon_1boag_2713._validation-icon-success_1boag_2722 svg {
          fill: #25a55a; }
  ._label-with-circle-button_1boag_4386._disabled_1boag_788 label {
    color: #939393; }
  ._label-with-circle-button_1boag_4386._disabled_1boag_788 svg {
    border-color: #bcbcbc;
    background-color: #bcbcbc; }

._control-search_1boag_4433 {
  position: relative; }
  ._control-search-input_1boag_4435 {
    position: relative; }
    ._control-search-input__prefix_1boag_4437 {
      position: absolute;
      left: 14px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    ._control-search-input__suffix_1boag_4445 {
      position: absolute;
      right: 6px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    ._control-search-input__symbol_1boag_4453 {
      height: 100%;
      pointer-events: none; }
      ._control-search-input__symbol_1boag_4453 * {
        fill: #116dff; }
    ._control-search-input_1boag_4435 > ._input-container_1boag_309 > ._input_1boag_309 {
      border: 1px solid #a8caff;
      padding: 0 36px;
      border-radius: 100px;
      height: 30px;
      font-size: 14px; }
    ._control-search-input_1boag_4435 ._control-button_1boag_544._btn-close_1boag_4464 {
      position: relative;
      background-color: #e7f0ff;
      width: 18px;
      height: 18px; }
      ._control-search-input_1boag_4435 ._control-button_1boag_544._btn-close_1boag_4464 svg * {
        fill: #116dff; }
      ._control-search-input_1boag_4435 ._control-button_1boag_544._btn-close_1boag_4464:hover {
        background-color: #e7f0ff; }
        ._control-search-input_1boag_4435 ._control-button_1boag_544._btn-close_1boag_4464:hover svg * {
          fill: #5999ff; }
  ._control-search-options_1boag_4475 {
    position: absolute;
    z-index: 5999;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
    max-height: 180px;
    overflow: auto;
    padding: 0;
    margin: 0; }
    ._control-search-options__item_1boag_4488 {
      min-height: 36px;
      padding: 6px 24px;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000624;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box; }
      ._control-search-options__item_1boag_4488._active_1boag_3528 {
        background-color: #d6e6fe;
        color: #000624; }

._colorSpace_1boag_4505 {
  position: relative;
  display: inline-block;
  margin-bottom: 10px; }
  ._colorSpace_1boag_4505 > ._top-section_1boag_4509 {
    height: 123px; }
    ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 {
      display: inline-block;
      position: relative;
      height: 100%;
      width: 210px;
      cursor: crosshair; }
      ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._selector-layer_1boag_4517 {
        position: absolute;
        width: 100%;
        height: 100%; }
      ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._saturation-layer_1boag_4521 {
        background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
      ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._brightness-layer_1boag_4523 {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), black); }
      ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._color-indicator_1boag_4525 {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 4px; }
        ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._color-indicator_1boag_4525._bright-background_1boag_4530 {
          box-shadow: 0 0 0 1px #000624; }
        ._colorSpace_1boag_4505 > ._top-section_1boag_4509 > ._select-area_1boag_4511 > ._color-indicator_1boag_4525._dark-background_1boag_4532 {
          box-shadow: 0 0 0 1px #fff; }
    ._add-color-content_1boag_4534 ._colorSpace_1boag_4505 > ._top-section_1boag_4509 {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden; }
  ._colorSpace_1boag_4505 > ._hue-scale_1boag_4538 {
    width: 100%;
    height: 10px;
    position: relative;
    background-image: linear-gradient(to right, red 0%, #ff4d00 5%, #ff9900 10%, #ffe600 15%, #ccff00 20%, #80ff00 25%, #33ff00 30%, #00ff1a 35%, #00ff66 40%, #00ffb3 45%, cyan 50%, #0066ff 60%, #001aff 65%, #3300ff 70%, #8000ff 75%, #cc00ff 80%, #ff00e6 85%, #ff0099 90%, #ff004d 95%, red 100%); }
    ._colorSpace_1boag_4505 > ._hue-scale_1boag_4538 > ._hue-indicator_1boag_4543 {
      position: absolute;
      top: -3px;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: #fff;
      box-shadow: 0 2px 1px 0 rgba(43, 86, 114, 0.4);
      box-sizing: border-box; }
    ._colorSpace_1boag_4505 > ._hue-scale_1boag_4538 > ._hue-indicator-drag_1boag_4552 {
      border: 2px solid #fff;
      background-color: transparent; }

._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 {
  position: relative;
  margin: 18px 17px 12px 17px;
  max-height: 24px; }
  ._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 > ._color-value-hex_1boag_4560 {
    width: 175px;
    margin: 0 auto 0 auto; }
  ._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 > ._color-values_1boag_4556 > ._color-value-item_1boag_4563 {
    position: relative;
    display: inline-block;
    width: 33%; }
    ._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 > ._color-values_1boag_4556 > ._color-value-item_1boag_4563:nth-child(1) {
      text-align: left; }
    ._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 > ._color-values_1boag_4556 > ._color-value-item_1boag_4563:nth-child(2) {
      text-align: center; }
    ._color-format_1boag_2676 > ._color-values-wrapper_1boag_4556 > ._color-values_1boag_4556 > ._color-value-item_1boag_4563:nth-child(3) {
      text-align: right; }

._palettes-list_1boag_4574 {
  position: relative;
  width: 138px;
  height: 100%;
  text-align: center; }
  ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 {
    display: inline;
    vertical-align: top; }
    ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 {
      padding: 3px 0;
      display: inline-block;
      cursor: pointer; }
      ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582:hover > ._palette-option_1boag_4579 {
        border-color: #d6e6fe;
        transition: border-color 0.4s; }
      ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 > ._palette-option_1boag_4579 {
        position: relative;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        border: 1px solid transparent;
        font-size: 0;
        padding: 3px; }
        ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 > ._palette-option_1boag_4579._selected_1boag_2248 {
          border-color: #116dff; }
          ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 > ._palette-option_1boag_4579._selected_1boag_2248:before {
            position: absolute;
            content: '';
            font-size: initial;
            left: -11px;
            cursor: initial;
            width: 0;
            height: 0;
            border-right: 6px solid #116dff;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            top: 9px; }
        ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 > ._palette-option_1boag_4579 > ._palette-option-color_1boag_4611 {
          display: inline-block;
          width: 18px;
          height: 100%; }
          ._palettes-list_1boag_4574 > ._palette-options_1boag_4579 > ._palette-option-wrapper_1boag_4582 > ._palette-option_1boag_4579 > ._palette-option-color_1boag_4611._white-color_1boag_4615 {
            box-sizing: border-box;
            border: 1px solid #ccc; }

._palette-color-option_1boag_4619 {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 42px;
  height: 24px; }
  ._palette-color-option_1boag_4619._white-option_1boag_4625 {
    box-sizing: border-box;
    border: 1px solid #ccc; }
  ._palette-color-option_1boag_4619._selectable_1boag_4628 {
    cursor: pointer; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._option-selected_1boag_4630:after, ._palette-color-option_1boag_4619._selectable_1boag_4628:not(._option-selected_1boag_4630):hover:after, ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625._option-selected_1boag_4630:after, ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625:not(._option-selected_1boag_4630):hover:after {
      content: '';
      position: absolute;
      width: 46px;
      height: 28px;
      background-color: inherit; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._option-selected_1boag_4630:after, ._palette-color-option_1boag_4619._selectable_1boag_4628:not(._option-selected_1boag_4630):hover:after {
      top: -1px;
      left: -2px; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625._option-selected_1boag_4630:after, ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625:not(._option-selected_1boag_4630):hover:after {
      top: -2px;
      left: -3px; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._option-selected_1boag_4630:after, ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625:not(._option-selected_1boag_4630):hover:after {
      box-sizing: border-box;
      box-shadow: 0 0 7px 0 rgba(22, 45, 61, 0.44);
      border: solid 1px #fff; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._option-selected_1boag_4630:after {
      z-index: 2; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628:not(._option-selected_1boag_4630):hover:after {
      z-index: 1; }
    ._palette-color-option_1boag_4619._selectable_1boag_4628._white-option_1boag_4625._option-selected_1boag_4630:after {
      border-color: #ccc; }

._palette-displayer-content_1boag_4653 ._palette-displayer-row_1boag_4653 {
  display: flex;
  margin-bottom: 10px; }
  ._palette-displayer-content_1boag_4653 ._palette-displayer-row_1boag_4653:last-child {
    margin-bottom: 0; }

._palette-displayer-content_1boag_4653._white-option_1boag_4625 {
  box-sizing: border-box;
  border: 1px solid #dfe5eb; }

._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619 {
  height: 18px; }
  ._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  ._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  ._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619._selectable_1boag_4628._option-selected_1boag_4630:after, ._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619._selectable_1boag_4628:not(._option-selected_1boag_4630):hover:after {
    content: '';
    position: absolute;
    width: calc(100% + 2px * 2);
    height: calc(100% + 3px * 2);
    background-color: inherit;
    border-radius: 2px;
    top: calc(3px * -1);
    left: calc(2px * -1); }
  ._palette-displayer-content_1boag_4653 ._palette-color-option_1boag_4619._selectable_1boag_4628:not(._option-selected_1boag_4630):hover:after {
    height: calc(100% + 2px * 2);
    top: calc(2px * -1); }

._color-picker-input_1boag_2876 {
  position: relative; }
  ._color-picker-input_1boag_2876 > ._color-picker-wrapper_1boag_4686 {
    width: 30px;
    height: 30px;
    transform: translateZ(0);
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    border-radius: 4px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
    background-position: 5px 5px, 15px 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    ._color-picker-input_1boag_2876 > ._color-picker-wrapper_1boag_4686._disabled_1boag_788 {
      opacity: 0.4; }
    ._color-picker-input_1boag_2876 > ._color-picker-wrapper_1boag_4686 > ._color-picker-color_1boag_4701 {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute; }
    ._color-picker-input_1boag_2876 > ._color-picker-wrapper_1boag_4686:hover, ._color-picker-input_1boag_2876 > ._color-picker-wrapper_1boag_4686._colorPickerOpen_1boag_4707 {
      border: 2px solid #80b1ff;
      background-position: 4px 4px, 14px 14px; }
  ._color-picker-input_1boag_2876 > ._color-picker-label_1boag_4710 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    height: 30px;
    line-height: 30px;
    width: 155px;
    cursor: pointer; }
    ._color-picker-input_1boag_2876 > ._color-picker-label_1boag_4710:hover + ._color-picker-wrapper_1boag_4686 {
      border: 2px solid #80b1ff; }
    ._color-picker-input_1boag_2876 > ._color-picker-label_1boag_4710 ~ ._color-picker-wrapper_1boag_4686 {
      position: absolute;
      right: 0;
      top: 0; }
  ._color-picker-input_1boag_2876 > ._divider-long_1boag_1763,
  ._color-picker-input_1boag_2876 > ._rich-text-paragraph_1boag_1772 {
    margin-top: 14px; }
  ._color-picker-input-with-opacity_1boag_2876 ._color-picker-input_1boag_2876 {
    position: absolute;
    right: 0;
    top: 2px; }

._color-picker-input-with-opacity_1boag_2876 {
  position: relative; }
  ._color-picker-input-with-opacity_1boag_2876 > ._color-picker-input-with-opacity-label_1boag_4737 {
    display: block;
    font-size: 14px;
    color: #3b4057;
    overflow: hidden;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    text-overflow: ellipsis; }
    ._color-picker-input-with-opacity_1boag_2876 > ._color-picker-input-with-opacity-label_1boag_4737._disabled_1boag_788 {
      opacity: 0.5; }
  ._color-picker-input-with-opacity_1boag_2876 > ._color-picker-input-with-opacity-slider_1boag_4746 {
    position: relative; }
  ._color-picker-input-with-opacity_1boag_2876 > ._divider-long_1boag_1763 {
    margin-top: 14px; }
  ._color-picker-input-with-opacity_1boag_2876 > ._rich-text-paragraph_1boag_1772 {
    margin-top: 14px; }

section._tabs_1boag_4753 {
  overflow: hidden;
  position: relative; }
  section._tabs_1boag_4753 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756 {
    cursor: pointer;
    display: block; }
  section._tabs_1boag_4753 > section._tab-content_1boag_4759 {
    position: relative; }
  section._tabs_1boag_4753._left_1boag_1144 {
    display: flex; }
    section._tabs_1boag_4753._left_1boag_1144 > section._tab-content_1boag_4759 {
      align-self: stretch;
      flex-grow: 1; }
  section._tabs_1boag_4753._top_1boag_2418 > ul._tab-header_1boag_4756 {
    display: block;
    overflow: hidden;
    width: 100%; }
    section._tabs_1boag_4753._top_1boag_2418 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756 {
      float: left; }
  section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756 {
    line-height: 16px;
    margin-bottom: 15px;
    padding-left: 28px;
    position: relative; }
    section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756:before, section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756:after {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute; }
    section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756:before {
      top: calc(50% - 8px);
      border: 1px solid #116dff;
      box-sizing: border-box;
      height: 16px;
      left: 0;
      width: 16px; }
    section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756._selected_1boag_2248:after {
      top: calc(50% - 6px);
      background-color: #116dff;
      height: 12px;
      left: 2px;
      width: 12px; }
    section._tabs_1boag_4753._radio_1boag_862 > ul._tab-header_1boag_4756 > li._tab-label_1boag_4756:hover:before {
      background-color: #d6e6fe; }

._control-section-divider_1boag_1632._labeled_1boag_1632 {
  position: relative; }
  ._control-section-divider_1boag_1632._labeled_1boag_1632 > ._section-divider-content_1boag_4800 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    cursor: default;
    color: #3b4057; }

._control-section-divider_1boag_1632 ._tooltip-on-ellipsis-content_1boag_4809._section-divider-content_1boag_4800 {
  position: static; }

._tooltip-presenter_1boag_4812 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 5999;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  border-radius: 4px; }
  ._tooltip-presenter_1boag_4812 ._arrow_1boag_1296 {
    transform: rotate(45deg);
    background-color: #fff; }
    ._tooltip-presenter_1boag_4812 ._arrow_1boag_1296:after {
      content: '';
      z-index: -1;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      height: 1px;
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
    ._tooltip-presenter_1boag_4812 ._arrow_1boag_1296:before {
      content: '';
      z-index: -1;
      top: 0;
      left: calc(100% - 1px);
      width: 1px;
      height: 100%;
      box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-bottom_1boag_4839 ._arrow_1boag_1296:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-bottom_1boag_4839 ._arrow_1boag_1296:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-right_1boag_4847 ._arrow_1boag_1296:after {
    top: calc(100% - 1px);
    left: 0;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-right_1boag_4847 ._arrow_1boag_1296:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-left_1boag_4855 ._arrow_1boag_1296:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812._alignment-left_1boag_4855 ._arrow_1boag_1296:before {
    top: 0;
    left: calc(100% - 1px);
    box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  ._tooltip-presenter_1boag_4812 ._content-wrapper_1boag_4863 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    position: relative;
    color: #3b4057; }
  ._tooltip-presenter_1boag_4812._normal-tooltip_1boag_4871 ._content-wrapper_1boag_4863 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 18px;
    color: #3b4057; }
  ._tooltip-presenter_1boag_4812._small-tooltip_1boag_4879 ._content-wrapper_1boag_4863 {
    padding: 12px; }
  ._tooltip-presenter_1boag_4812._tooltip-transition_1boag_4881 {
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) left; }
  ._tooltip-presenter_1boag_4812._tooltip-behind-pop-ups_1boag_4883 {
    z-index: 1500; }
  ._tooltip-presenter_1boag_4812._tooltip-behind-pages-panel_1boag_4885 {
    z-index: 1253; }
  ._tooltip-presenter_1boag_4812._tooltip-shake_1boag_4887 {
    animation: _shake-animation_1boag_1 0.3s linear; }
  ._tooltip-presenter_1boag_4812 ._content_1boag_1826 {
    word-wrap: break-word; }
  ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812 {
    border-radius: 15px;
    height: 30px;
    white-space: nowrap;
    cursor: default; }
    ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812._hover-bubble_1boag_4896 {
      height: 24px; }
      ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812._hover-bubble_1boag_4896 ._input-stepper_1boag_844 {
        margin-top: 2px; }
    ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812._hover-bubble_1boag_4896:hover {
      background-color: #d6e6fe; }
      ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812._hover-bubble_1boag_4896:hover ._input-stepper_1boag_844 ._input_1boag_309 {
        background-color: #d6e6fe; }
    ._rulers_1boag_4891 ._tooltip-presenter_1boag_4812 ._content-wrapper_1boag_4863 {
      padding: 0; }

@keyframes _shake-animation_1boag_1 {
  1% {
    transform: translate(-20px); }
  20% {
    transform: translate(10px); }
  40% {
    transform: translate(-5px); }
  60% {
    transform: translate(3px); } }

._quick-tip_1boag_4917 {
  white-space: normal;
  padding: 24px;
  width: 236px; }
  ._quick-tip_1boag_4917 ._close-quick-tip_1boag_4921 {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 7px;
    width: 7px;
    cursor: pointer; }
    ._quick-tip_1boag_4917 ._close-quick-tip_1boag_4921 svg {
      position: absolute; }
  ._quick-tip_1boag_4917 ._text-container_1boag_4930 {
    display: inline-block;
    vertical-align: top;
    padding-left: 12px;
    width: 190px; }
    ._quick-tip_1boag_4917 ._text-container_1boag_4930 ._text_1boag_674 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      display: inline; }
      ._quick-tip_1boag_4917 ._text-container_1boag_4930 ._text_1boag_674._link-action_1boag_4943 {
        cursor: pointer;
        color: #116dff; }
        ._quick-tip_1boag_4917 ._text-container_1boag_4930 ._text_1boag_674._link-action_1boag_4943:hover {
          color: #5999ff; }
    ._quick-tip_1boag_4917 ._text-container_1boag_4930._text-no-image_1boag_4948 {
      padding: 18px; }
  ._quick-tip_1boag_4917 ._quick-tip-image_1boag_4950 {
    display: inline-block;
    vertical-align: top; }
    ._quick-tip_1boag_4917 ._quick-tip-image_1boag_4950 svg {
      width: 34px;
      height: 34px; }

._imageAndTextTooltip_1boag_4957 {
  white-space: normal; }
  ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930 {
    padding: 24px; }
    ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930 ._title_1boag_1623 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      margin-bottom: 12px; }
    ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930 ._text_1boag_674 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #3b4057;
      display: inline; }
    ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930 ._learn-more_1boag_4977 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      color: #116dff;
      margin-top: 10px;
      display: inline;
      padding-left: 5px; }
      ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930 ._learn-more_1boag_4977 :hover {
        text-decoration: underline; }
    ._imageAndTextTooltip_1boag_4957 ._text-container_1boag_4930._text-no-image_1boag_4948 {
      padding: 18px; }
  ._imageAndTextTooltip_1boag_4957 ._image-container_1boag_1110 {
    background-color: #116dff;
    text-align: center;
    left: 0;
    right: 0; }
    ._imageAndTextTooltip_1boag_4957 ._image-container_1boag_1110._upper-image_1boag_4997 {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    ._imageAndTextTooltip_1boag_4957 ._image-container_1boag_1110._lower-image_1boag_5000 {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
    ._imageAndTextTooltip_1boag_4957 ._image-container_1boag_1110 svg {
      margin-top: 22px;
      margin-bottom: 22px; }
  ._imageAndTextTooltip_1boag_4957 ._close_1boag_1482 {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

._icon-and-text_1boag_5011 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  padding: 18px;
  color: #3b4057;
  display: flex;
  align-items: center; }
  ._icon-and-text_1boag_5011 svg {
    margin-right: 10px; }
  ._icon-and-text_1boag_5011 span {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

._titleBodyAndLinkTooltip_1boag_5029 {
  white-space: normal;
  max-width: 300px;
  padding: 18px; }
  ._titleBodyAndLinkTooltip_1boag_5029 ._title_1boag_1623 {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057;
    margin-bottom: 6px;
    white-space: nowrap; }
  ._titleBodyAndLinkTooltip_1boag_5029 ._text_1boag_674 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #3b4057; }
  ._titleBodyAndLinkTooltip_1boag_5029 ._link_1boag_3484 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #116dff;
    cursor: pointer;
    margin-top: 11px; }
    ._titleBodyAndLinkTooltip_1boag_5029 ._link_1boag_3484:hover {
      text-decoration: underline; }
  ._titleBodyAndLinkTooltip_1boag_5029 ._close_1boag_1482 {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

._titleBodyAndLinkTooltip_1boag_5029._small_1boag_1998 {
  padding: 12px; }
  ._titleBodyAndLinkTooltip_1boag_5029._small_1boag_1998 ._link_1boag_3484 {
    margin-top: 4px; }

._keyboardShortcutTooltip_1boag_5070 ._label_1boag_617 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #3b4057; }

._keyboardShortcutTooltip_1boag_5070 ._shortcut_1boag_5078 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  color: #868aa5;
  margin-left: 2px; }

._tooltips-layer_1boag_5087 {
  top: 0;
  left: 0; }

._aiTextCreatorEntryPoint_1boag_5091 {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 26px;
  height: 26px;
  border-radius: 50% 50% 0 50%;
  background-color: #116dff;
  border: 2px solid white;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  animation: _aiTextCreatorEntryPointAppearance_1boag_1 0.8s ease;
  box-sizing: border-box;
  transition: 0.35s ease;
  transform-origin: bottom right;
  transform: scale(1); }
  ._aiTextCreatorEntryPoint_1boag_5091:has(._aiTextCreatorEntryPoint__icon_1boag_5106) {
    background: linear-gradient(135.71deg, #6f56f9 22.72%, #116dff 56.58%, #80f3c5 92.31%); }
  ._aiTextCreatorEntryPoint_small_1boag_5108 {
    transform: scale(0.62);
    border-width: 3px; }
    ._aiTextCreatorEntryPoint_small_1boag_5108 ._aiTextCreatorEntryPoint__text_1boag_5111,
    ._aiTextCreatorEntryPoint_small_1boag_5108 ._aiTextCreatorEntryPoint__icon_1boag_5106 {
      opacity: 0;
      transition: opacity 0.35s; }
  ._aiTextCreatorEntryPoint_withBigToSmallAppearance_1boag_5115 {
    animation: _aiTextCreatorEntryPointAppearance_1boag_1 0.8s ease, _aiTextCreatorEntryPointAppearanceScale_1boag_1 1.15s ease; }
    ._aiTextCreatorEntryPoint_withBigToSmallAppearance_1boag_5115 ._aiTextCreatorEntryPoint__text_1boag_5111,
    ._aiTextCreatorEntryPoint_withBigToSmallAppearance_1boag_5115 ._aiTextCreatorEntryPoint__icon_1boag_5106 {
      animation: _aiTextCreatorEntryPointAppearanceText_1boag_1 1.15s ease; }
  ._aiTextCreatorEntryPoint__text_1boag_5111 {
    position: absolute;
    left: 4px;
    top: 6px; }
  ._aiTextCreatorEntryPoint__icon_1boag_5106 {
    position: absolute;
    left: 2px;
    top: 3px; }
  ._aiTextCreatorEntryPoint__wrapper_1boag_5128 {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 37px; }
    ._aiTextCreatorEntryPoint__wrapper_cursorPointer_1boag_5135 {
      cursor: pointer; }
    ._aiTextCreatorEntryPoint__wrapper_1boag_5128:hover ._aiTextCreatorEntryPoint_withHover_1boag_5137 {
      border: 2px solid white;
      transform: scale(1); }
      ._aiTextCreatorEntryPoint__wrapper_1boag_5128:hover ._aiTextCreatorEntryPoint_withHover_1boag_5137 ._aiTextCreatorEntryPoint__text_1boag_5111,
      ._aiTextCreatorEntryPoint__wrapper_1boag_5128:hover ._aiTextCreatorEntryPoint_withHover_1boag_5137 ._aiTextCreatorEntryPoint__icon_1boag_5106 {
        opacity: 1; }
    ._aiTextCreatorEntryPoint__wrapper_1boag_5128 svg * {
      fill: #fff; }

@keyframes _aiTextCreatorEntryPointAppearance_1boag_1 {
  0% {
    opacity: 0; }
  12.5% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes _aiTextCreatorEntryPointAppearanceScale_1boag_1 {
  0% {
    transform: scale(1);
    border-width: 2px; }
  70% {
    transform: scale(1);
    border-width: 2px; }
  100% {
    transform: scale(0.62);
    border-width: 3px; } }

@keyframes _aiTextCreatorEntryPointAppearanceText_1boag_1 {
  0% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

._button_1boag_547 {
  max-width: 100%;
  height: 24px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #a8caff;
  background: white;
  box-sizing: border-box;
  cursor: pointer; }
  ._button_1boag_547:hover {
    border-color: #5999ff; }
  ._button_selected_1boag_5186 {
    border: 1px solid #116dff; }
