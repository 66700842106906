// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  title?: string;
  text?: string;
  link?: string;
  linkAction?: () => void;
  className?: string;
  shouldTranslate?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'titleBodyAndLinkTooltip';

  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    linkAction: PropTypes.func,
    shouldTranslate: PropTypes.bool,
  };

  static defaultProps = {
    shouldTranslate: true,
  };

  onLinkClick = () => {
    if (this.props.linkAction) {
      this.props.linkAction();
    }
  };

  render() {
    return (
      <div className={`titleBodyAndLinkTooltip ${this.props.className || ''}`}>
        {this.props.onClose ? (
          <symbols.symbol
            key="closeButton"
            name="bubble-close"
            onClick={this.props.onClose}
            className="close"
          />
        ) : null}
        {this.props.title ? (
          <div key="title" className="title">
            {this.props.shouldTranslate
              ? translate(this.props.title)
              : this.props.title}
          </div>
        ) : null}
        {this.props.text ? (
          <div key="text" className="text">
            {this.props.shouldTranslate
              ? translate(this.props.text)
              : this.props.text}
          </div>
        ) : null}
        {this.props.link ? (
          <div key="link" onClick={this.onLinkClick} className="link">
            {this.props.shouldTranslate
              ? translate(this.props.link)
              : this.props.link}
          </div>
        ) : null}
      </div>
    );
  }
}
