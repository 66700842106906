import React from 'react';
import { Text, InfoIcon, type InfoIconProps } from '@wix/wix-base-ui';

import { cx } from '@/util';

import styles from './sectionHeader.scss';
import { TooltipContent } from './tooltipContent';
import type { TextPropsOverrides } from '@/newDesignPanel';

export interface SectionHeaderProps {
  title: string;
  tooltipTitle?: string;
  tooltipText?: string;
  tooltipLinkText?: string;
  className?: string;
  onTooltipLinkClick?(): void;
  onTooltipIllustrationClick?(): void;
  onTooltipOpen?(): void;
  tooltipIllustration?: React.ReactElement;
  infoIconSymbol?: React.ReactElement;
  titleAutomationId?: string;
  infoIconAutomationId?: string;
  closeOnTooltipClick?: boolean;
  error?: boolean;
  tooltipOpenDelay?: number;
  infoIconCustomProps?: Partial<InfoIconProps>;
  textPropsOverrides?: TextPropsOverrides;
}

const getTooltip = (
  title: string,
  text: string,
  linkText: string,
  onLinkClick: () => void,
) => {
  const props = { title, text, linkText, onLinkClick };

  return {
    tooltipCustomContent: React.createElement(TooltipContent, props),
  };
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  tooltipTitle,
  tooltipText,
  tooltipLinkText,
  onTooltipOpen,
  onTooltipLinkClick,
  onTooltipIllustrationClick,
  className,
  tooltipIllustration,
  infoIconSymbol,
  titleAutomationId,
  infoIconAutomationId,
  closeOnTooltipClick,
  error,
  tooltipOpenDelay,
  infoIconCustomProps,
  textPropsOverrides,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <Text
        size={textPropsOverrides?.size || 'small'}
        weight={textPropsOverrides?.weight}
        skin={textPropsOverrides?.skin}
        shouldTranslate={false}
        className={styles.title}
        automationId={titleAutomationId}
      >
        {title}
      </Text>
      {tooltipText && (
        <InfoIcon
          {...getTooltip(
            tooltipTitle,
            tooltipText,
            tooltipLinkText,
            onTooltipLinkClick,
          )}
          automationId={infoIconAutomationId}
          className={cx(styles.icon, error ? 'error' : 'info')}
          onLinkClick={onTooltipLinkClick}
          onIllustrationClick={onTooltipIllustrationClick}
          onTooltipOpen={onTooltipOpen}
          shouldTranslate={false}
          alignment="RIGHT"
          illustration={tooltipIllustration}
          customSymbol={infoIconSymbol}
          closeOnContentMouseClick={closeOnTooltipClick}
          error={error}
          tooltipOpenDelay={tooltipOpenDelay}
        />
      )}
      {infoIconCustomProps && (
        <InfoIcon
          {...infoIconCustomProps}
          automationId={infoIconAutomationId}
          className={cx(
            styles.icon,
            infoIconCustomProps.error ? 'error' : 'info',
          )}
          shouldTranslate={false}
          alignment="RIGHT"
        />
      )}
    </div>
  );
};

export default SectionHeader;
