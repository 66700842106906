// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';

const getTextValidatorByProp = (propertyData) => (text) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/size
  _.size(text) <= (propertyData?.maxLength ?? Number.MAX_SAFE_INTEGER) &&
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/size
  _.size(text) >= (propertyData?.minLength ?? -1);

const getNumberValidatorByProp = (propertyData) => (number) =>
  /^-?\d+\.?\d*$/.test(number) &&
  number >= (propertyData?.minimum ?? Number.MIN_SAFE_INTEGER) &&
  number <= (propertyData?.maximum ?? Number.MAX_SAFE_INTEGER);

const getUrlValidator = () => (url) => _.isEmpty(url) || util.validate.url(url);

const getDateTimeValidator = () => (date) =>
  _.isEmpty(date) || !isNaN(new Date(date).getDate());

const getValidatorByProp = (propertyData) => {
  switch (propertyData.$id) {
    case 'text':
      return getTextValidatorByProp(propertyData);
    case 'number':
      return getNumberValidatorByProp(propertyData);
    case 'dateTime':
      return getDateTimeValidator();
    case 'url':
      return getUrlValidator();
  }
};

const getTextInvalidMessageByProp = (propertyData) => {
  const hasMin = _.has(propertyData, 'minLength');
  const hasMax = _.has(propertyData, 'maxLength');
  if (hasMin && hasMax) {
    return translate(
      'AppStudio_propertyPanel_textLength_minMaxRange_validator',
      {
        min: propertyData.minLength,
        max: propertyData.maxLength,
      },
    );
  }
  if (hasMin) {
    return translate('AppStudio_propertyPanel_textLength_minValue_validator', {
      min: propertyData.minLength,
    });
  }
  if (hasMax) {
    return translate('AppStudio_propertyPanel_textLength_maxValue_validator', {
      max: propertyData.maxLength,
    });
  }
  return translate('AppStudio_propertyPanel_textLength_validator');
};

const getNumberInvalidMessageByProp = (propertyData) => {
  const hasMin = _.has(propertyData, 'minimum');
  const hasMax = _.has(propertyData, 'maximum');
  if (hasMin && hasMax) {
    return translate('AppStudio_propertyPanel_minMax_validator', {
      min: propertyData.minimum,
      max: propertyData.maximum,
    });
  }
  if (hasMin) {
    return translate('AppStudio_propertyPanel_valueMin_validator', {
      min: propertyData.minimum,
    });
  }
  if (hasMax) {
    return translate('AppStudio_propertyPanel_valueMax_validator', {
      max: propertyData.maximum,
    });
  }
  return translate('AppStudio_propertyPanel_numberValid_validator');
};

const getUrlInvalidMessage = () =>
  translate('AppStudio_WidgetAPI_URL_DefaultURL_Error');

const getDateTimeInvalidMessage = () =>
  translate('AppStudio_WidgetAPI_URL_DefaultDate_Error');

const getInvalidMessageByProp = (propertyData) => {
  switch (propertyData.$id) {
    case 'text':
      return getTextInvalidMessageByProp(propertyData);
    case 'number':
      return getNumberInvalidMessageByProp(propertyData);
    case 'dateTime':
      return getDateTimeInvalidMessage();
    case 'url':
      return getUrlInvalidMessage();
  }
};

const validateByProperty = (propertyData, value, resolveDefinition) => {
  if (propertyData.type === 'object') {
    const properties = resolveDefinition(propertyData.properties);
    return validateObject(properties, value, resolveDefinition);
  }
  if (propertyData.type === 'array') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(value, (listValue) =>
      validateByProperty(propertyData.items, listValue, resolveDefinition),
    );
  }
  const validate = getValidatorByProp(propertyData);
  return validate ? validate(value) : true;
};

const validateObject = (properties, value, resolveDefinition) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys, you-dont-need-lodash-underscore/every
  _.every(_.keys(properties), (propName) =>
    validateByProperty(
      properties[propName],
      value[propName],
      resolveDefinition,
    ),
  );

export {
  getTextValidatorByProp,
  getNumberValidatorByProp,
  getUrlValidator,
  getDateTimeValidator,
  getValidatorByProp,
  getTextInvalidMessageByProp,
  getNumberInvalidMessageByProp,
  getUrlInvalidMessage,
  getDateTimeInvalidMessage,
  getInvalidMessageByProp,
  validateByProperty,
};
