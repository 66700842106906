import React from 'react';
import _ from 'lodash';

import * as symbols from '@wix/santa-editor-symbols';
import constants from '@/constants';
import { Button, Tooltip } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { cx } from '@/util';

import PopoversTooltip from '../popovers/tooltip';
import tooltipContent from './listItemWithImageTooltipContent';

const SHORT_DELAY = 300;

type FixMeTooltipContentProps = any;

interface ListItemWithImageProps {
  title?: string;
  desc?: string;
  tooltip?: string;
  disabled?: boolean;
  url: string;
  callback: (event: React.MouseEvent) => void;
  componentType?: string;
  hintId?: string;
  shouldTranslate?: boolean;
  largeTooltipContent: FixMeTooltipContentProps;
  symbol?: string;
  onLearnMoreClick?: () => void;
  learnMoreText?: string;
}

class ListItemWithImage extends React.Component<ListItemWithImageProps> {
  state = {
    isLargeTooltipHovered: false,
  };

  private tooltipTimeout: number;

  shouldRenderLargeTooltip = () => {
    return Boolean(this.props.largeTooltipContent);
  };

  clearTimeout = () => {
    window.clearTimeout(this.tooltipTimeout);
  };

  handleMouseEnterTooltip = () => {
    this.clearTimeout();
    this.setState({ isLargeTooltipHovered: true });
    _.invoke(this.props, 'onLargeTooltipHovered');
  };

  handleMouseLeaveTooltip = () => {
    this.clearTimeout();
    this.tooltipTimeout = window.setTimeout(() => {
      this.setState({ isLargeTooltipHovered: false });
    }, SHORT_DELAY);
  };

  getLargeTooltipContent = () => {
    return React.createElement(
      tooltipContent,
      Object.assign(
        {
          onMouseEnter: () => {
            this.handleMouseEnterTooltip();
          },
          onMouseLeave: () => {
            this.clearTimeout();
            this.setState({ isLargeTooltipHovered: false });
          },
        },
        this.props.largeTooltipContent,
      ),
    );
  };

  getSymbol = () => {
    const symbolClasses = `listItemSymbol${
      this.props.disabled ? ' disabled' : ''
    }`;
    return React.createElement(symbols.symbol, {
      key: 'list-item-with-image-symbol',
      name: this.props.symbol || 'arrowWithStates',
      className: symbolClasses,
      hintId: this.props.hintId,
    });
  };

  translateIfNeeded = (key: AnyFixMe) => {
    return this.props?.shouldTranslate ?? true ? translate(key) : key;
  };

  handleLearnMoreClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.onLearnMoreClick?.();
  };

  render() {
    return (
      <div
        data-aid={this.props.title}
        data-hook={this.props.title}
        onClick={this.props.disabled ? _.noop : this.props.callback}
        data-comp={this.props.componentType}
        className={cx('listItemWithImage', { disabled: this.props.disabled })}
      >
        <img src={this.props.url} className="image" />
        <div className="content">
          {this.props.title ? (
            <div key="title" className="title ellipsis">
              {this.translateIfNeeded(this.props.title)}
            </div>
          ) : null}
          {this.props.desc ? (
            <div key="desc" className="desc ellipsis">
              {this.translateIfNeeded(this.props.desc)}{' '}
              {this.props.onLearnMoreClick ? (
                <Button
                  onClick={this.handleLearnMoreClick}
                  className="btn-text inline-link-button"
                >
                  {this.translateIfNeeded(this.props.learnMoreText)}
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="list-item-indicator">
          {!this.shouldRenderLargeTooltip() ? (
            <PopoversTooltip
              value={this.props.tooltip}
              disabled={!this.props.tooltip}
              key="list-item-with-image-small-tooltip"
              alignment={constants.UI.TOOLTIP.ALIGNMENT.TOP}
            >
              <div>{this.getSymbol()}</div>
            </PopoversTooltip>
          ) : null}
          <div
            onMouseEnter={this.handleMouseEnterTooltip}
            onMouseLeave={this.handleMouseLeaveTooltip}
          >
            {this.shouldRenderLargeTooltip() ? (
              <Tooltip
                // @ts-expect-error
                alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT}
                isOpen={this.state.isLargeTooltipHovered}
                openOnMouseEnter={false}
                content={this.getLargeTooltipContent()}
                key="list-item-with-image-large-tooltip"
              >
                <div>{this.getSymbol()}</div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ListItemWithImage;
